<template>
  <section
    class="tabs"
    :class="{'is-light': theme}"
  >
    <div
      class="wrapper"
      :class="{'container': container}"
    >
      <h4
        class="title-small"
        v-if="tabsTitle"
        v-inview-animate:[animationRepeat]="{name: 'fade-down', duration: 500, delay: 400}"
      >{{tabsTitle}}</h4>
      <div class="navigation">
        <div
          class="toggles"
          v-inview-animate:[animationRepeat]="{name: 'fade-down', duration: 500, delay: 500}"
        >
          <Tab
            v-for="(tab, tabID) in tabs"
            :key="`${tab?.label?.name}`"
            :data-tab="`tab-${tabID}`"
            :class="[{'is-active': activeTabId === tabID}, {'is-light': theme}]"
            @click="activatedTab(tab, tabID)"
          >{{tab?.label?.default_multiple}}</Tab>
        </div>
        <slot name="navigation"></slot>
      </div>
    </div>
    <div
      class="panels"
      v-inview-animate:[animationRepeat]="{name: 'fade-down', duration: 500, delay: 600}"
    >
      <transition
        name="fade"
        mode="out-in"
      >
        <slot :activeTab="activeTab"></slot>
      </transition>
    </div>
  </section>
</template>

<script>
import Tab from './components/Tab.vue';

export default {
  components: { Tab },
  name: 'Tabs',
  props: {
    tabs: {
      type: Array,
    },
    tabsTitle: {
      type: String,
      default: undefined,
    },
    theme: {
      type: Boolean,
      default: false,
    },
    container: {
      type: Boolean,
      default: false,
    },
    initActive: {
      type: Number,
      default: 1,
    },
    animationRepeat: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      activeTab: this?.tabs?.[this.initActive],
      activeTabId: this.initActive,
    };
  },
  methods: {
    activatedTab(tab, tabId) {
      this.activeTab = tab;
      this.activeTabId = tabId;
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  .title-small {
    display: block;
    width: 100%;
    text-align: center;

    @include wRule(
      margin-bottom,
      (
        xl: 18,
        lg: 24,
        sm: 28,
      )
    );
  }

  .wrapper {
    width: 100%;
  }

  .navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    @include wRule(
      margin-bottom,
      (
        xl: 37,
        lg: 40,
        sm: 50,
      )
    );

    @include rule(
      align-items,
      (
        xl: flex-end,
        lg: center,
      )
    );
  }

  .toggles {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .panels {
    display: block;
  }

  &.space-between {
    .title-small {
      text-align: left;
    }

    .navigation {
      justify-content: space-between;

      @include screen("sm", max) {
        justify-content: center;
      }
    }

    .toggles {
      width: auto;

      @include screen("sm", max) {
        width: 100%;
      }
    }
  }
}
</style>
