<template>
  <div class="base-datepicker">
    <VTooltip
      :theme="'calendar'"
      :triggers="['click']"
      :offset="[12, 12]"
      :placement="'top'"
      :strategy="'fixed'"
      :autoHide="true"
    >
      <template #default>
        <label
          class="datepicker-input"
          :class="[{'is-error': errorMessage}, {'is-top': inputValue}, {'is-disabled': disabled}]"
          :style="`transition-delay: ${delay}ms`"
        >
          <input
            :name="name"
            :id="name"
            type="text"
            :placeholder="label"
            v-model="inputValue"
            :disabled="disabled"
            readonly="readonly"
          >
          <span class="label">{{label}}</span>
          <Icon :name="'calendar'" />
        </label>
      </template>

      <template #popper>
        <div class="calendar">
          <div class="calendar-header">
            <h3 class="calendar-title">{{mobileTitle}}</h3>
            <Close
              ref="close"
              v-close-popper
            />
          </div>
          <v-date-picker
            v-model="date"
            :mode="'date'"
            :columns="$screens({ default: 1, md: 2 })"
            :rows="$screens({ default: 2, md: 1 })"
            :step="1"
            :min-date="new Date()"
            :timezone="'UTC'"
            :masks="{ title: 'MMMM', weekdays: 'WW', data: 'YYYY-MM-DD' }"
            :locale="currentLang"
            :transition="$screens({default: 'slide-v', md: 'slide-h'})"
            :first-day-of-week="2"
            class="calendar-content"
            @update:modelValue="updateInput"
          >
            <template #header-left-button>
              <Icon :name="'back'" />
            </template>
            <template #header-right-button>
              <Icon :name="'next'" />
            </template>
          </v-date-picker>
        </div>
      </template>
    </VTooltip>
  </div>
</template>

<script>
import Close from '../../Close.vue';
import { useField } from 'vee-validate';
import Icon from '../../Icon.vue';
import formatDate from '../../../tools/formatDate';

export default {
  components: { Close, Icon },
  name: 'BaseDatepicker',
  props: {
    name: {
      type: String,
      reqired: true,
    },
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    mobileTitle: {
      type: String,
    },
    delay: {
      type: Number,
      default: 0,
    },
    initDate: {
      type: Date,
      default: new Date(),
    },
  },
  computed: {
    currentLang() {
      return this.$store.getters.getLang;
    },
    breakpoint() {
      return this.$store.getters.getBreakpoint;
    },
  },
  data() {
    return {
      date: this.initDate,
    };
  },
  methods: {
    updateInput(value) {
      this.inputValue = formatDate(value);
      this.$refs.close.$el.click();
    },
  },
  setup(props) {
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(props.name, props.rules, {
      initialValue: formatDate(props.initDate),
    });

    return {
      inputValue,
      handleChange,
      handleBlur,
      errorMessage,
      meta,
    };
  },
};
</script>

<style lang="scss">
.v-popper--theme-calendar {
  z-index: 5;

  &.v-popper__popper {
    transition: opacity 0s ease;
    z-index: 180;

    @include screen("sm", max) {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: translate3d(0, 0, 0) !important;
      z-index: 180;
      background-color: color(basic-light);
    }

    &--shown {
      opacity: 1;
      pointer-events: auto;
    }

    &--hidden {
      opacity: 0;
      pointer-events: none;
    }
  }

  .v-popper__inner,
  .v-popper__wrapper {
    @include screen("sm", max) {
      height: 100%;
    }
  }

  .v-popper__inner {
    @include screen("sm", max) {
      width: 100%;
    }

    & > div {
      @include screen("sm", max) {
        width: 100%;
        height: 100%;
      }
    }
  }

  .calendar {
    font-family: $font-main;

    @include rule(
      width,
      (
        xl: auto,
        sm: 100%,
      )
    );

    @include screen("sm", max) {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: auto;
    }
  }

  .calendar-header {
    @include rule(
      display,
      (
        xl: none,
        sm: flex,
      )
    );

    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-family: $font-main;
    box-sizing: border-box;
    border-bottom: 1px solid color(border);

    @include wRule(
      padding,
      (
        sm: 0 40,
      )
    );

    @include wRule(
      border-bottom-width,
      (
        xl: 1,
        lg: 1,
        sm: 2,
      )
    );

    @include wRule(
      height,
      (
        sm: 116,
      )
    );

    @include screen("sm", max) {
      flex: none;
    }
  }

  .calendar-title {
    font-weight: 700;
    line-height: 1.28;
    letter-spacing: -0.02em;

    @include wRule(
      font-size,
      (
        xl: 40,
        lg: 32,
        sm: 32,
      )
    );
  }

  .calendar-content {
    border: none;
    border-radius: 0;

    @include rule(
      width,
      (
        xl: auto,
        sm: 100%,
      )
    );

    @include screen("sm", max) {
      margin: auto 0;
    }
  }

  .close {
    display: none;
    flex: none;
    box-sizing: border-box;
    color: color(close-gray);
    transition: color $ease-main;

    @include wRule(
      width,
      (
        xl: 55,
        lg: 48,
        sm: 55,
      )
    );

    @include wRule(
      height,
      (
        xl: 55,
        lg: 48,
        sm: 55,
      )
    );

    @include wRule(
      padding,
      (
        xl: 10,
        lg: 10,
        sm: 10,
      )
    );

    @media (hover: hover) {
      &:hover {
        color: color(main);
      }
    }

    svg {
      width: 100%;
      height: 100%;
      fill: currentColor;
    }

    @include wRule(
      margin,
      (
        sm: 0 -10 0 40,
      )
    );

    @include screen("sm", max) {
      display: block;
      flex: none;
    }
  }

  .vc {
    &-header {
      @include wRule(
        padding,
        (
          xl: 30 30 0,
          lg: 20 24 0,
          sm: 10 20 0,
        )
      );
    }

    &-arrows-container {
      @include wRule(
        padding,
        (
          xl: 28 30 0,
          lg: 22 23 0,
        )
      );

      @include screen("sm", max) {
        display: none;
      }
    }

    &-arrow {
      box-sizing: border-box;
      border-style: solid;
      border-color: color(basic);
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      color: color(basic);
      display: flex;

      @include wRule(
        border-width,
        (
          xl: 1,
          lg: 1,
          sm: 2,
        )
      );

      @include wRule(
        width,
        (
          xl: 29,
          lg: 23,
          sm: 30,
        )
      );
      @include wRule(
        height,
        (
          xl: 29,
          lg: 23,
          sm: 30,
        )
      );

      &.is-disabled {
        opacity: 0.32;
      }

      svg {
        fill: currentColor;

        @include wRule(
          width,
          (
            xl: 6,
            lg: 5,
            sm: 6,
          )
        );
      }

      &.is-left svg {
        @include wRule(
          margin-left,
          (
            xl: -2,
            lg: -2,
            sm: 0,
          )
        );
      }

      &.is-right svg {
        @include wRule(
          margin-right,
          (
            xl: -2,
            lg: -2,
            sm: 0,
          )
        );
      }
    }

    &-title {
      letter-spacing: -0.02em;
      color: color(basic);
      text-transform: capitalize;
      font-family: $font-main;

      @include rule(
        font-weight,
        (
          xl: 400,
          sm: 700,
        )
      );

      @include wRule(
        font-size,
        (
          xl: 20,
          lg: 16,
          sm: 28,
        )
      );
    }

    &-weeks {
      @include wRule(
        min-width,
        (
          xl: 399,
          lg: 313,
          sm: 100%,
        )
      );

      @include wRule(
        padding,
        (
          xl: 20 25 15,
          lg: 8 20 8,
          sm: 30 45 0,
        )
      );
    }

    &-weekday {
      font-family: $font-main;
      text-transform: capitalize;
      color: color(main);
      letter-spacing: -0.02em;
      font-weight: 400;

      @include wRule(
        font-size,
        (
          xl: 16,
          lg: 14,
          sm: 24,
        )
      );

      @include wRule(
        padding,
        (
          xl: 9 0 12,
          lg: 6 0 12,
          sm: 0 0 12,
        )
      );
    }

    &-day {
      font-family: $font-main;
      color: color(basic);
      letter-spacing: -0.02em;
      font-weight: 400;

      @include wRule(
        font-size,
        (
          xl: 16,
          lg: 14,
          sm: 24,
        )
      );
    }

    &-highlight {
      background-color: color(main) !important;

      @include wRule(
        width,
        (
          xl: 40,
          lg: 32,
          sm: 62,
        )
      );

      @include wRule(
        height,
        (
          xl: 40,
          lg: 32,
          sm: 62,
        )
      );
    }

    &-day-content {
      overflow: auto;
      letter-spacing: -0.02em;

      @include wRule(
        width,
        (
          xl: 40,
          lg: 32,
          sm: 62,
        )
      );

      @include wRule(
        height,
        (
          xl: 42,
          lg: 32,
          sm: 62,
        )
      );

      @include wRule(
        font-size,
        (
          xl: 16,
          lg: 14,
          sm: 24,
        )
      );

      &.vc-focusable {
        font-weight: 400 !important;
      }

      &.is-disabled {
        color: currentColor;
        opacity: 0.32;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.base-datepicker {
}

.datepicker-input {
  position: relative;
  display: block;

  &::after {
    @include pseudo;

    bottom: 0;
    left: 0;
    width: 100%;
    background-color: currentColor;

    @include wRule(
      height,
      (
        xl: 2,
        lg: 2,
        sm: 2,
      )
    );
  }

  .base-datepicker.is-inview-animation-input-transition & {
    &::after {
      transition: transform 0.5s ease;
      transition-delay: inherit;
      transform-origin: 0 50%;
      transform: scaleX(0);
    }
  }

  .base-datepicker.is-inview-animation-active & {
    &::after {
      transform: scaleX(1);
    }
  }

  input {
    display: block;
    box-sizing: border-box;
    letter-spacing: -0.02em;
    width: 100%;
    color: currentColor;

    @include wRule(
      padding,
      (
        xl: 16 40 16 0,
        lg: 16 40 16 0,
        sm: 23 40 23 0,
      )
    );

    @include wRule(
      font-size,
      (
        xl: 20,
        lg: 16,
        sm: 24,
      )
    );

    &::placeholder {
      color: transparent;
    }

    &:-webkit-autofill {
      -webkit-text-fill-color: color(basic);

      .popup-form & {
        -webkit-text-fill-color: color(basic-light);
      }
    }
  }

  .label {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    box-sizing: border-box;
    pointer-events: none;
    transform-origin: 0 100%;
    transition: transform $ease-main;
    text-rendering: geometricPrecision;

    @include wRule(
      padding,
      (
        xl: 16 0,
        lg: 16 0,
        sm: 23 0,
      )
    );

    @include wRule(
      font-size,
      (
        xl: 20,
        lg: 16,
        sm: 24,
      )
    );

    .base-datepicker.is-inview-animation-input-transition & {
      opacity: 0;
      transition: opacity 0.5s ease, transform 0.25s ease;
      transition-delay: inherit;
    }

    .base-datepicker.is-inview-animation-active & {
      opacity: 1;
    }
  }

  &.is-error {
    &::after {
      background-color: color(error);
    }
  }

  &.is-top .label,
  input:focus ~ .label {
    transform: scale(0.6) translate3d(0, -121%, 0);
  }

  .icon {
    position: absolute;
    height: 100%;
    right: 0;
    top: 0;

    @include wRule(
      width,
      (
        xl: 32,
        lg: 32,
        sm: 32,
      )
    );
  }
}
</style>
