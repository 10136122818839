<template>
  <component
    :is="tag"
    class="link"
  >
    <div class="link__wrapper">
      <slot name="start"></slot>
      <span class="link__text">
        <slot></slot>
      </span>
      <slot name="end"></slot>
    </div>
  </component>
</template>

<script>
export default {
  name: 'Link',
  props: {
    tag: {
      type: String,
      default: 'button',
    },
  },
};
</script>

<style lang="scss" scoped>
.link {
  display: inline-block;
  color: currentColor;

  &[disabled] {
    opacity: 0.4;
    pointer-events: none;
  }

  ::v-deep(.circle) {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border: 1px solid color(basic-light);
    border-radius: 50%;

    @include wRule(
      width,
      (
        xl: 34,
        lg: 28,
        sm: auto,
      )
    );
    @include wRule(
      height,
      (
        xl: 34,
        lg: 28,
        sm: auto,
      )
    );
    @include wRule(
      margin-right,
      (
        xl: 20,
        lg: 20,
        sm: 29,
      )
    );

    @include wRule(
      font-size,
      (
        xl: 20,
        lg: 16,
        sm: 28,
      )
    );
  }

  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__text {
    position: relative;
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;

    &::before {
      @include pseudo;

      font-size: inherit;
      left: 0;
      bottom: 0.04em;
      width: 100%;
      height: 0.1em;
      background-color: currentColor;
      transition: transform 0.4s ease;
      transform: scaleX(0);
      transform-origin: 0 50%;
    }

    @media (hover: hover) {
      &:hover {
        &::before {
          transform: scaleX(1);
        }
      }
    }
  }

  &.underline {
    .link__text {
      &::before {
        transform: scaleX(1);
      }
    }

    @media (hover: hover) {
      &:hover {
        .link__text {
          &::before {
            transform: scaleX(0);
          }
        }
      }
    }
  }

  ::v-deep(.plus) {
    position: relative;
    display: block;
    vertical-align: middle;
    text-align: center;
    border-style: solid;
    border-color: currentColor;
    flex: none;
    border-radius: 50%;
    font-weight: 700;
    box-sizing: border-box;
    line-height: 1;

    @include wRule(
      font-size,
      (
        xl: 16,
        lg: 16,
        sm: 24,
      )
    );

    @include wRule(
      border-width,
      (
        xl: 2,
        lg: 2,
        sm: 2,
      )
    );

    @include wRule(
      width,
      (
        xl: 24,
        lg: 24,
        sm: 36,
      )
    );

    @include wRule(
      height,
      (
        xl: 24,
        lg: 24,
        sm: 36,
      )
    );

    @include wRule(
      margin-right,
      (
        xl: 20,
        lg: 20,
        sm: 34,
      )
    );

    &::before {
      position: absolute;
      display: block;
      line-height: 0;
      letter-spacing: normal;
      content: "+";
      top: 54%;
      left: 0;
      width: 100%;
      text-align: center;
      transform: translate3d(0, -50%, 0);
    }

    &.is-minus::before {
      content: "–";
    }
  }

  ::v-deep(.icon) {
    flex: none;

    @include wRule(
      width,
      (
        xl: 16,
        lg: 14,
        sm: 26,
      )
    );
    @include wRule(
      height,
      (
        xl: 16,
        lg: 14,
        sm: 26,
      )
    );

    &_arrow {
      transition: transform 300ms ease;
      transform-origin: 0% 50%;

      @include screen("sm", max) {
        opacity: 0.5;
      }

      @include wRule(
        margin-left,
        (
          xl: 14,
          lg: 24,
          sm: 26,
        )
      );

      @include wRule(
        width,
        (
          xl: 40,
          lg: 40,
          sm: 65,
        )
      );

      @include wRule(
        height,
        (
          xl: 10,
          lg: 10,
          sm: 18,
        )
      );
    }

    &_pin-outline {
      @include wRule(
        margin-left,
        (
          xl: 18,
          lg: 12,
          sm: 16,
        )
      );

      @include wRule(
        width,
        (
          xl: 24,
          lg: 24,
          sm: 32,
        )
      );

      @include wRule(
        height,
        (
          xl: 24,
          lg: 24,
          sm: 32,
        )
      );
    }
  }

  &_whatsapp {
    ::v-deep(.circle) {
      border: none;
    }

    ::v-deep(.icon_whatsapp) {
      @include wRule(
        width,
        (
          xl: 31,
          lg: 25,
          sm: 28,
        )
      );

      @include wRule(
        height,
        (
          xl: 31,
          lg: 25,
          sm: 28,
        )
      );
    }
  }

  &.is-inview-animation-fade-down ::v-deep(.icon_arrow) {
    transform: scaleX(0);
  }

  &.is-inview-animation-active ::v-deep(.icon_arrow) {
    transform: scaleX(1);
  }
}
</style>
