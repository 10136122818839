export default function scrollObserver(instance) {
  let lastScrollTop = 0;
  let lastInstanceScrollTop = 0;
  const inst = instance;

  function handlerScroll() {
    const sT = window.pageYOffset;
    inst.pageIsBegining = (sT === 0);
    inst.pageMovedDown = sT > lastScrollTop;
    lastScrollTop = sT <= 0 ? 0 : sT;
  }

  function handlerInstanceScroll(evt) {
    console.log('scroller scroll');
    const scrollEl = evt.currentTarget;
    const sT = scrollEl.scrollTop;
    inst.pageIsBegining = (sT === 0);
    inst.pageMovedDown = sT > lastInstanceScrollTop;
    lastInstanceScrollTop = sT <= 0 ? 0 : sT;
  }

  window.addEventListener('scroll', handlerScroll, { passive: true });

  document.body.addEventListener('scroller-in', (evt) => {
    const { scroller } = evt.detail;
    lastInstanceScrollTop = 0;
    console.log('scroller-in');

    if (window.matchMedia('(min-width: 768px)').matches) {
      scroller.addEventListener('scroll', handlerInstanceScroll, { passive: true });
    } else {
      scroller.removeEventListener('scroll', handlerInstanceScroll, { passive: true });
    }

    window.addEventListener('resize', () => {
      if (window.matchMedia('(min-width: 768px)').matches) {
        scroller.addEventListener('scroll', handlerInstanceScroll, { passive: true });
      } else {
        scroller.removeEventListener('scroll', handlerInstanceScroll, { passive: true });
      }
    });
  });

  document.body.addEventListener('scroller-out', (evt) => {
    const { scroller } = evt.detail;

    inst.pageMovedDown = false;

    scroller.removeEventListener('scroll', handlerInstanceScroll, { passive: true });
  });
}
