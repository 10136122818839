<template>
  <PageSection class="management-section">
    <template #default>
      <div class="container">
        <div class="header">
          <h3
            class="title"
            v-inview-animate:repeat="{name: 'fade-down', duration: 500, delay: 300}"
          >{{content?.title}}</h3>
          <p
            v-if="false"
            class="text"
            v-inview-animate:repeat="{name: 'fade-down', duration: 500, delay: 400}"
            v-html="content?.description"
          ></p>
        </div>
        <Tabs
          :tabs="content?.tab"
          :animationRepeat="'repeat'"
          class="space-between"
          v-if="content?.tab.length > 0"
        >
          <template #navigation>
            <Link
              v-if="breakpoint !== 'sm'"
              :tag="'router-link'"
              :to="'/fleet'"
              v-inview-animate:repeat="{name: 'fade-down', duration: 500, delay: 500}"
            >
            <template #default>
              {{content?.link_text}}
            </template>
            <template #end>
              <Icon
                name="arrow"
                :style="'transition-delay: 500ms;'"
              />
            </template>
            </Link>
          </template>
          <template v-slot="{ activeTab }">
            <PlaneTabSlider
              :key="activeTab?.label?.name"
              :content="activeTab?.vehicle_type"
              :linkBase="'/fleet/'"
            />
          </template>
        </Tabs>
        <div
          class="links"
          v-if="breakpoint === 'sm'"
        >
          <Link
            :tag="'router-link'"
            :to="'/fleet'"
            v-inview-animate:repeat="{name: 'fade-down', duration: 500, delay: 700}"
          >
          <template #default>
            {{content?.link_text}}
          </template>
          <template #end>
            <Icon name="arrow" />
          </template>
          </Link>
        </div>
      </div>
    </template>
  </PageSection>
</template>

<script>
import Icon from '../../../components/Icon.vue';
import Link from '../../../components/Link.vue';
import PageSection from '../../../components/PageSection.vue';
import PlaneTabSlider from '../../../components/PlaneTabSlider.vue';
import Tabs from '../../../components/Tabs/Tabs.vue';

export default {
  components: {
    PageSection,
    Tabs,
    Icon,
    Link,
    PlaneTabSlider,
  },
  name: 'ManagementSection',
  props: {
    content: {
      type: Object,
    },
  },
  computed: {
    breakpoint() {
      return this.$store.getters.getBreakpoint;
    },
  },
};
</script>

<style lang="scss" scoped>
.management-section {
  scroll-snap-align: start;
  min-height: 100vh;

  @include screen("sm", max) {
    min-height: 0;
  }

  @include wRule(
    padding,
    (
      xl: 128 0 140,
      lg: 70 0 104,
      sm: 84 0 120,
    )
  );

  @include rule(
    background-color,
    (
      sm: color(basic-light),
    )
  );

  ::v-deep(.text) {
    @include rule(
      line-height,
      (
        xl: 1.6,
        lg: 1.5,
      )
    );

    @include wRule(
      max-width,
      (
        xl: 840,
        lg: 684,
        sm: 100%,
      )
    );

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 34,
          lg: 16,
          sm: 46,
        )
      );
    }
  }

  .tabs {
    &:not(:first-child) {
      // @include wRule(
      //   margin-top,
      //   (
      //     xl: 58,
      //     lg: 50,
      //     sm: 60,
      //   )
      // );
      @include wRule(
        margin-top,
        (
          xl: 45,
          lg: 35,
          sm: 15,
        )
      );
    }
  }

  ::v-deep(.panels) {
    @include wRule(
      margin,
      (
        sm: 0 -40,
      )
    );
  }

  ::v-deep(.title-small) {
    @include wRule(
      font-size,
      (
        xl: 40,
        lg: 32,
        sm: 32,
      )
    );
  }

  .links {
    display: flex;
    justify-content: flex-end;

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 52,
          lg: 16,
          sm: 54,
        )
      );
    }
  }

  ::v-deep(.link) {
    font-weight: 700;

    @include wRule(
      font-size,
      (
        xl: 20,
        lg: 16,
        sm: 28,
      )
    );
  }
}
</style>
