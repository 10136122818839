<template>
  <div class="popup-form">
    <div class="header">
      <slot name="header"></slot>
    </div>
    <div class="content">
      <slot name="content"></slot>
    </div>
    <div class="bottom">
      <slot name="bottom"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupForm',
};
</script>

<style lang="scss" scoped>
.popup-form {
  position: relative;
  box-sizing: border-box;
  display: block;

  @include wRule(
    padding,
    (
      xl: 54 0 0,
      lg: 36 0 0,
      sm: 116 0 0,
    )
  );

  &::before {
    @include pseudo;

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        180deg,
        rgba(28, 27, 65, 0) 0%,
        rgba(14, 14, 34, 0.67) 100%
      ),
      rgba(8, 10, 35, 0.3);
    backdrop-filter: blur(11px);
  }
}

.header {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  z-index: 1;

  @include wRule(
    padding,
    (
      xl: 0 42,
      lg: 0 36,
      sm: 0 40,
    )
  );

  @include screen("sm", max) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: auto;
    height: pxtovw(116, sm);
    border-bottom-style: solid;
    border-bottom-color: rgba(221, 221, 221, 0.28);
  }

  ::v-deep(.title-small) {
    @include wRule(
      font-size,
      (
        sm: 32,
      )
    );
  }

  &:not(:last-child) {
    @include wRule(
      margin-bottom,
      (
        xl: 50,
        lg: 28,
        sm: 0,
      )
    );
  }
}

.content {
  display: block;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  z-index: 2;

  @include wRule(
    padding,
    (
      xl: 0 42 100,
      lg: 0 36 69,
      sm: 68 40,
    )
  );

  ::v-deep(.fieldset) {
    display: block;
    box-sizing: border-box;
    width: 100%;

    &:not(:last-of-type) {
      @include wRule(
        margin-bottom,
        (
          xl: 75,
          lg: 54,
          sm: 66,
        )
      );
    }
  }

  ::v-deep(.subtitle) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    font-weight: 700;
    line-height: 1.28;
    letter-spacing: -0.02em;

    @include wRule(
      font-size,
      (
        xl: 24,
        lg: 20,
        sm: 32,
      )
    );

    .icon {
      fill: color(icon-blue);

      @include wRule(
        width,
        (
          xl: 32,
          lg: 32,
          sm: 44,
        )
      );

      @include wRule(
        height,
        (
          xl: 32,
          lg: 32,
          sm: 44,
        )
      );

      @include wRule(
        margin,
        (
          xl: 0 10 0 -2,
          lg: 0 10 0 -10,
          sm: 0 10 0 -10,
        )
      );

      &_plane {
        @include wRule(
          width,
          (
            xl: 20,
            lg: 20,
            sm: 30,
          )
        );

        @include wRule(
          height,
          (
            xl: 18,
            lg: 18,
            sm: 26,
          )
        );

        @include wRule(
          margin,
          (
            xl: 0 16 0 4,
            lg: 0 16 0 -4,
            sm: 0 17 0 -3,
          )
        );
      }
    }
  }

  ::v-deep(.fields) {
    display: block;
    box-sizing: border-box;
    width: 100%;

    @include wRule(
      padding,
      (
        xl: 0 40,
        lg: 0 32,
        sm: 0,
      )
    );

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 32,
          lg: 12,
          sm: 23,
        )
      );
    }

    &.grid-3 {
      display: grid;
      grid-template-columns: 1fr pxtovw(350, xl) 1fr;

      @include wRule(
        column-gap,
        (
          xl: 100,
          lg: 52,
          sm: 0,
        )
      );

      @include wRule(
        row-gap,
        (
          xl: 40,
          lg: 34,
          sm: 0,
        )
      );

      @include screen("lg", max) {
        grid-template-columns: 1fr pxtovw(340, lg);
      }

      @include screen("sm", max) {
        display: block;
      }
    }

    &.grid-3-equal {
      display: grid;
      grid-template-columns: 1fr pxtovw(350, xl) 1fr;

      @include wRule(
        column-gap,
        (
          xl: 100,
          lg: 90,
          sm: 0,
        )
      );

      @include wRule(
        row-gap,
        (
          xl: 40,
          lg: 34,
          sm: 0,
        )
      );

      @include screen("lg", max) {
        grid-template-columns: repeat(3, 1fr);
      }

      @include screen("sm", max) {
        display: block;
      }
    }

    &.grid-wishes {
      display: grid;
      grid-template-columns: 1fr pxtovw(350, xl) 1fr;

      @include wRule(
        column-gap,
        (
          xl: 100,
          lg: 70,
          sm: 0,
        )
      );

      @include wRule(
        row-gap,
        (
          xl: 40,
          lg: 34,
          sm: 0,
        )
      );

      @include screen("lg", max) {
        grid-template-columns: repeat(2, 1fr);
      }

      @include screen("sm", max) {
        display: block;
      }
    }

    &.grid-2 {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      @include wRule(
        column-gap,
        (
          xl: 56,
          lg: 52,
          sm: 0,
        )
      );

      @include wRule(
        row-gap,
        (
          xl: 40,
          lg: 36,
          sm: 0,
        )
      );

      @include screen("sm", max) {
        display: block;
      }
    }
  }

  ::v-deep(.wrapper) {
    display: block;
    width: 100%;
    box-sizing: border-box;

    &.lg-100 {
      @include screen("lg", max) {
        grid-column: 1 / 3;
      }
    }

    &:not(:last-child) {
      @include wRule(
        margin-bottom,
        (
          sm: 40,
        )
      );
    }
  }

  ::v-deep(.phone) {
    display: flex;
    justify-content: flex-start;
  }

  ::v-deep(.calling-code) {
    flex: none;

    @include wRule(
      width,
      (
        xl: 124,
        lg: 120,
        sm: 183,
      )
    );

    &:not(:last-child) {
      @include wRule(
        margin-right,
        (
          xl: 24,
          lg: 20,
          sm: 36,
        )
      );
    }
  }

  ::v-deep(.phone-number) {
    width: auto;
    flex: 1;
  }

  ::v-deep(.error) {
    display: block;
    color: color(error);
    font-weight: 400;
    letter-spacing: -0.02em;
    line-height: 1.28;
    box-sizing: border-box;

    @include wRule(
      padding,
      (
        xl: 12 0,
        lg: 12 0,
        sm: 16 0,
      )
    );

    @include wRule(
      font-size,
      (
        xl: 14,
        lg: 14,
        sm: 14,
      )
    );
  }

  ::v-deep(.add-button) {
    line-height: 1.28;
    letter-spacing: -0.02em;
    cursor: pointer;

    @include wRule(
      font-size,
      (
        xl: 20,
        lg: 16,
        sm: 24,
      )
    );
  }
}

.bottom {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 1;
  background: linear-gradient(180deg, rgba(28, 27, 65, 0) 0%, #0e0e22 100%),
    #080a23;

  @include wRule(
    padding,
    (
      xl: 51 82,
      lg: 46 66,
      sm: 40,
    )
  );

  @include screen("sm", max) {
    align-content: flex-start;
    flex-wrap: wrap;
  }

  ::v-deep(.submit) {
    margin-left: auto;

    @include wRule(
      min-width,
      (
        xl: 257,
        lg: 214,
        sm: 0,
      )
    );

    @include screen("sm", max) {
      margin-left: 0;
      width: 100%;
    }
  }

  ::v-deep(.add-button) {
    line-height: 1.28;
    letter-spacing: -0.02em;
    cursor: pointer;

    @include wRule(
      font-size,
      (
        xl: 20,
        lg: 16,
        sm: 24,
      )
    );

    &:not(:last-child) {
      @include wRule(
        margin-bottom,
        (
          sm: 44,
        )
      );
    }
  }
}
</style>
