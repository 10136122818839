export default function formatTime(timeMin) {
  console.log(timeMin);
  const time = parseInt(timeMin, 10);
  let hours = parseInt(time / 60, 10);
  const minutes = time % 60;
  let days = 0;
  if (hours > 23) {
    days = parseInt(hours / 24, 10);
    hours %= 24;
  }
  return {
    days,
    hours,
    minutes,
  };
}
