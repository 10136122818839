export function freeze() {
  const h = document.documentElement;
  console.log(h);

  if (getComputedStyle(h).position !== 'fixed') {
    const top = window.pageYOffset;

    if (window.innerWidth > h.clientWidth) {
      h.style.overflowY = 'scroll';
    }

    h.style.position = 'fixed';
    h.style.top = `${-top}px`;
  }
}

export function unfreeze() {
  const h = document.documentElement;
  // const body = document.querySelector('body');

  if (getComputedStyle(h).position === 'fixed') {
    h.style.position = 'static';
    const currentShift = getComputedStyle(h).top.split('px')[0];

    // h.scrollTop = -parseInt(currentShift, 10);
    // body.scrollTop = -parseInt(currentShift, 10);
    window.scrollTo(0, -parseInt(currentShift, 10));

    h.style.position = '';
    h.style.top = '';
    h.style.overflowY = '';
  }
}
