<template>
  <div class="charter-steps">
    <Feature
      class="feature-charter"
      v-for="(step, stepId) in pageContent"
      :key="`step-${stepId + 1}`"
      v-inview-animate:repeat="{name: 'fade-down', duration: 500, delay: (400 + 100 * stepId)}"
    >
      <template #symbol>{{stepId + 1}}</template>
      <template #default>
        <div class="text-small">{{step?.name}}</div>
      </template>
    </Feature>
  </div>
</template>

<script>
import Feature from './Feature.vue';
import api from '../tools/api';

export default {
  components: { Feature },
  name: 'CharterSteps',
  data() {
    return {
      pageContent: [],
      contentLoading: true,
    };
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
  },
  methods: {
    async getPageContent() {
      const results = await api('charter-steps', true);
      this.contentLoading = !results.statusText === 'ok';
      this.pageContent = results.data;
    },
  },
  watch: {
    lang() {
      this.getPageContent();
    },
  },
  created() {
    this.getPageContent();
  },
};
</script>

<style lang="scss" scoped>
.charter-steps {
  display: flex;
  justify-content: flex-start;

  @include screen("sm", max) {
    flex-wrap: wrap;
  }

  @media screen and (min-width: 1940px) {
    justify-content: space-between;
  }

  .feature {
    @include wRule(
      width,
      (
        xl: 300,
        lg: 198,
      )
    );

    @include screen("sm", max) {
      width: auto;
    }

    @media screen and (min-width: 1940px) {
      width: 400px;
    }

    &:not(:first-child) {
      @include wRule(
        margin-left,
        (
          xl: 64,
          lg: 66,
          sm: 0,
        )
      );
      @include wRule(
        margin-top,
        (
          sm: 48,
        )
      );
    }
  }
}
</style>
