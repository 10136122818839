<template>
  <router-link
    :to="link ? link : `${linkBase}${content?.vehicle_id}`"
    class="plane-preview"
  >
    <BgMedia :source="content?.preview" />
    <div class="content">
      <p v-html="content?.name"></p>
    </div>
  </router-link>
</template>

<script>
import BgMedia from './BgMedia.vue';

export default {
  components: { BgMedia },
  name: 'PlanePreview',
  props: {
    content: Object,
    linkBase: {
      type: String,
      default: '/',
    },
    link: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.plane-preview {
  position: relative;
  display: block;

  ::v-deep(.bg-media) {
    @include ratio(840, 520);
    overflow: hidden;
    width: 100%;

    @include screen("lg", max) {
      @include ratio(480, 280);
    }

    @include screen("sm", max) {
      @include ratio(560, 360);
    }

    picture {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    img {
      transition: transform $ease-main;
    }
  }

  &:hover {
    ::v-deep(.bg-media) {
      img {
        transform: scale(1.1);
      }
    }
  }

  .content {
    font-weight: 700;
    line-height: 1.28;

    @include wRule(
      margin-top,
      (
        xl: 36,
        lg: 24,
        sm: 32,
      )
    );

    @include wRule(
      font-size,
      (
        xl: 24,
        lg: 16,
        sm: 24,
      )
    );
  }

  &.inner {
    color: color(basic-light);
    overflow: hidden;

    .bg-media {
      @include ratio(690, 350);

      @include screen("lg", max) {
        @include ratio(480, 280);
      }

      @include screen("sm", max) {
        @include ratio(640, 420);
      }
    }

    .content {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      background-color: rgba(color(preview-mask), 0.37);
      transition: opacity $ease-main;
      opacity: 0;
      box-sizing: border-box;
      text-align: center;
      margin-top: 0;

      @include wRule(
        padding,
        (
          xl: 40,
          lg: 20,
          sm: 44 40,
        )
      );

      @include screen("sm", max) {
        opacity: 1;
        background-color: transparent;
        align-items: flex-end;
      }
    }

    &:hover {
      .content {
        opacity: 1;
      }
    }
  }
}
</style>
