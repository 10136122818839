<template>
  <div class="popup-order-form">
    <VeeForm
      method="post"
      :action="request?.FormUrl"
      :validation-schema="validationShema"
      class="popup-request-form"
      @submit="onSubmit"
    >
      <PopupForm v-if="formNotSend">
        <template #header>
          <button
            type="button"
            class="sublink"
            v-if="isResult"
            @click.prevent="changeContent"
          >
            <div
              class="sublink-wrapper"
              v-inview-animate="{name: 'fade', duration: 300, delay: 100}"
            >
              <div class="sublink-text">{{request?.ResultTitle}}</div>
              <Icon name="down" />
            </div>
          </button>
          <h3
            class="title-small"
            v-inview-animate="{name: 'fade-down', duration: 300, delay: 200}"
          >{{request?.FormTitle}}</h3>
        </template>
        <template #content>
          <fieldset class="fieldset">
            <div
              class="subtitle"
              v-inview-animate="{name: 'fade-down', duration: 300, delay: 300}"
            >
              <Icon :name="request?.Groups?.Contacts?.Icon" />
              <span class="subtitle-text">{{request?.Groups?.Contacts?.Name}}</span>
            </div>
            <div class="fields grid-3">
              <div class="wrapper">
                <BaseInput
                  name="name"
                  type="text"
                  :label="request?.Groups?.Contacts?.Fields?.Name?.Label"
                  v-inview-animate="{name: 'input-transition', duration: 300, delay: 400}"
                />
                <ErrorMessage
                  name="name"
                  class="error"
                />
              </div>

              <div class="wrapper">
                <div class="phone">
                  <BaseSelect
                    :options="callingCodes"
                    :name="'callingCode'"
                    :initial-selected="userCallingCode"
                    :class="'calling-code'"
                    :mobile-title="request?.Groups?.Contacts?.Fields?.CallingCode?.MobileTitle"
                    :search-placeholder="request?.Groups?.Contacts?.Fields?.CallingCode?.SearchPlaceholder"
                    :uid="'countryName'"
                    v-inview-animate="{name: 'input-transition', duration: 300, delay: 500}"
                  />

                  <BaseInput
                    name="phone"
                    type="text"
                    inputmode="number"
                    :label="request?.Groups?.Contacts?.Fields?.Phone?.Label"
                    :class="'phone-number'"
                    v-inview-animate="{name: 'input-transition', duration: 300, delay: 500}"
                  />
                </div>
                <ErrorMessage
                  name="phone"
                  class="error"
                />
              </div>

              <div class="wrapper lg-100">
                <BaseInput
                  name="email"
                  type="email"
                  :label="request?.Groups?.Contacts?.Fields?.Email?.Label"
                  v-inview-animate="{name: 'input-transition', duration: 300, delay: 600}"
                />
                <ErrorMessage
                  name="email"
                  class="error"
                />
              </div>
            </div>
          </fieldset>

          <!-- Route Fieldset -->
          <fieldset
            class="fieldset"
            v-for="(route, id) in routes"
            :key="route.id"
          >
            <div
              class="subtitle"
              v-inview-animate="{name: 'fade-down', duration: 300, delay: 700}"
            >
              <Icon :name="request?.Groups?.Route?.Icon" />
              <span
                class="subtitle-text"
                v-if="routes.length === 1"
              >{{request?.Groups?.Route?.Name}}</span>
              <span
                class="subtitle-text"
                v-if="routes.length > 1"
              >{{request?.Groups?.Route?.Numerals[id]}} {{request?.Groups?.Route?.NameNum}}</span>
              <button
                type="button"
                class="remove-route"
                v-if="routes.length > 1"
                @click="removeRoute(route)"
              >
                <Icon :name="'bold-cross'" />
              </button>
            </div>
            <div class="fields grid-2">
              <div class="wrapper">
                <AirportSelect
                  :name="`route[${id}].departure_airport`"
                  :label="request?.Groups?.Route?.Fields?.departure_airport?.label"
                  :mobileTitle="request?.Groups?.Route?.Fields?.departure_airport?.mobileTitle"
                  v-inview-animate="{name: 'input-transition', duration: 300, delay: 800}"
                  :initialSelected="presetData?.departure_airport"
                />
                <ErrorMessage
                  :name="`route[${id}].departure_airport`"
                  class="error"
                />
              </div>
              <div class="wrapper">
                <AirportSelect
                  :name="`route[${id}].arrival_airport`"
                  :label="request?.Groups?.Route?.Fields?.arrival_airport?.label"
                  :mobileTitle="request?.Groups?.Route?.Fields?.arrival_airport?.mobileTitle"
                  :initialSelected="presetData?.arrival_airport"
                  v-inview-animate="{name: 'input-transition', duration: 300, delay: 900}"
                />
                <ErrorMessage
                  :name="`route[${id}].arrival_airport`"
                  class="error"
                />
              </div>
            </div>

            <div class="add">
              <div class="add-header">
                <Link
                  class="add-button underline"
                  :tag="'button'"
                  type="button"
                  @click="route.isAdditionalInfo = !route.isAdditionalInfo"
                  v-inview-animate="{name: 'fade-down', duration: 300, delay: 900}"
                >
                <template #start>
                  <span
                    class="plus"
                    :class="{'is-minus': route.isAdditionalInfo}"
                  >
                  </span>
                </template>
                <template #default>{{route.isAdditionalInfo ? request?.FormAddButtonActive : request?.FormAddButton}}</template>
                </Link>
              </div>

              <div
                class="add-content"
                v-if="route.isAdditionalInfo"
              >
                <div class="fields grid-3-equal">
                  <div class="wrapper">
                    <BaseDatepicker
                      :name="`route[${id}].date`"
                      :label="request?.Groups?.Route?.Fields?.date?.label"
                      :mobileTitle="request?.Groups?.Route?.Fields?.date?.mobileTitle"
                      :initDate="getInitDate"
                      v-inview-animate="{name: 'input-transition', duration: 300, delay: 100}"
                    />
                    <ErrorMessage
                      :name="`route[${id}].date`"
                      class="error"
                    />
                  </div>
                  <div class="wrapper">
                    <BaseSelect
                      :options="request?.timeRange"
                      :name="`route[${id}].time`"
                      :optionType="'default'"
                      :label="request?.Groups?.Route?.Fields?.time?.label"
                      v-inview-animate="{name: 'input-transition', duration: 300, delay: 200}"
                    />
                    <ErrorMessage
                      :name="`route[${id}].time`"
                      class="error"
                    />
                  </div>
                  <div class="wrapper">
                    <BaseInput
                      :name="`route[${id}].pax`"
                      :label="request?.Groups?.Route?.Fields?.pax?.label"
                      v-inview-animate="{name: 'input-transition', duration: 300, delay: 300}"
                      :value="presetData?.pax"
                    />
                    <ErrorMessage
                      :name="`route[${id}].pax`"
                      class="error"
                    />
                  </div>
                </div>
                <div class="fields grid-wishes">
                  <div class="wishes-block">
                    <h4
                      class="wishes-title"
                      v-inview-animate="{name: 'fade-down', duration: 300, delay: 400}"
                    >{{request?.WishesTitle1}}</h4>
                    <div class="wrapper">
                      <AircraftSelect
                        :name="`route[${id}].aircraft`"
                        :label="request?.Groups?.Route?.Fields?.aircraft?.label"
                        :initData="presetData?.aircraft"
                        v-inview-animate="{name: 'input-transition', duration: 300, delay: 500}"
                      />
                      <ErrorMessage
                        :name="`route[${id}].aircraft`"
                        class="error"
                      />
                    </div>
                  </div>
                  <div class="wishes-block wishes-block_x2">
                    <h4
                      class="wishes-title"
                      v-inview-animate="{name: 'fade-down', duration: 300, delay: 600}"
                    >{{request?.WishesTitle2}}</h4>
                    <div class="check-list">
                      <BaseToggle
                        v-for="(toggle, toggleId) in request?.Groups?.Route?.Fields?.wishes"
                        :key="`${id}-${toggleId}`"
                        :data="toggle"
                        :name="`route[${id}].wishes`"
                        v-inview-animate="{name: 'input-transition', duration: 300, delay: (600 + toggleId * 100)}"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </template>

        <template #bottom>
          <Link
            class="add-button underline"
            :tag="'button'"
            type="button"
            @click="addRoute"
            :disabled="noNewFlight"
            v-inview-animate="{name: 'fade-down', duration: 300, delay: 1100}"
          >
          <template #start>
            <span class="plus">
            </span>
          </template>
          <template #default>{{request?.FormRouteButton}}</template>
          </Link>
          <VButton
            :tag="'button'"
            type="submit"
            class="submit"
            v-inview-animate="{name: 'fade-down', duration: 300, delay: 1200}"
          >{{request?.FormSubmitName}}</VButton>
        </template>
      </PopupForm>
      <SuccessMessage
        :data="sendSuccess ? successMessage : errorMessage"
        v-else
        @modal-closed="closePopup"
      ></SuccessMessage>
    </VeeForm>
  </div>
</template>

<script>
import * as yup from 'yup';
import { setLocale } from 'yup';
import { unfreeze } from '@/tools/blockScroll';
import { Form as VeeForm, ErrorMessage } from 'vee-validate';
import PopupForm from './Forms/PopupForm.vue';
import Icon from './Icon.vue';
import BaseInput from './Forms/components/BaseInput.vue';
import BaseSelect from './Forms/components/BaseSelect.vue';
import VButton from './VButton.vue';
import Link from './Link.vue';
import AirportSelect from './Forms/components/AirportSelect.vue';
import AircraftSelect from './Forms/components/AircraftSelect.vue';
import BaseDatepicker from './Forms/components/BaseDatepicker.vue';
import BaseToggle from './Forms/components/BaseToggle.vue';
import SuccessMessage from './SuccessMessage.vue';

export default {
  components: {
    PopupForm,
    VeeForm,
    ErrorMessage,
    Icon,
    BaseInput,
    BaseSelect,
    VButton,
    Link,
    AirportSelect,
    AircraftSelect,
    BaseDatepicker,
    BaseToggle,
    SuccessMessage,
  },
  name: 'PopupOrderForm',
  props: {
    isResult: {
      type: Boolean,
      default: false,
    },
    presetData: {
      type: Object,
    },
  },
  emits: ['changeContent', 'modalClosed'],
  data() {
    return {
      routes: [
        {
          id: `route${Date.now()}`,
          isAdditionalInfo: false,
        },
      ],
      formNotSend: true,
      sendSuccess: false,
      validationShema: null,
    };
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
    getErrorsList() {
      const errorList = this.$store.getters.getErrorList;
      return errorList;
    },
    callingCodes() {
      return this.$store.getters.getCallingCodes;
    },
    getInitDate() {
      return this.presetData === null
        ? new Date()
        : new Date(this.presetData?.departureDate);
    },
    userCallingCode() {
      return this.$store.getters.getUserCallingCode;
    },
    maxFlightCount() {
      return this.getName('numerals')?.default?.split(',').length;
    },
    noNewFlight() {
      return this.routes.length === this.maxFlightCount;
    },
    successMessage() {
      return {
        Icon: 'check',
        Title: this.getName('form_request_success_title')?.default,
        Text: this.getName('form_request_success_description')?.default,
        ButtonName: this.getName('close_button')?.default,
      };
    },
    errorMessage() {
      return {
        Icon: 'cross',
        Title: this.getName('form_request_error_title')?.default,
        Text: this.getName('form_request_error_description')?.default,
        ButtonName: this.getName('close_button')?.default,
      };
    },
    request() {
      const timeRange = [];
      for (let i = 0; i <= 24; i += 1) {
        timeRange.push({ value: `${`0${i}`.slice(-2)}:00` });
      }

      return {
        timeRange,
        ResultTitle: this.getName('calculator_result_title')?.default,
        FormTitle: this.getName('form_order_title')?.default,
        FormSubmitName: this.getName('FormSubmitButton')?.secondary,
        FormRouteButton: this.getName('button_add_route')?.default,
        FormAddButtonActive: this.getName('button_add_info')?.secondary,
        FormAddButton: this.getName('button_add_info')?.default,
        FormUrl: '/',
        WishesTitle1: this.getName('fieldset_subtitle_aircrafts')?.default,
        WishesTitle2: this.getName('fieldset_subtitle_crew')?.default,
        Groups: {
          Contacts: {
            Icon: 'person',
            Name: this.getName('fieldset_contacts')?.default,
            Fields: {
              Name: {
                Label: this.getName('field_name')?.default,
              },
              CallingCode: {
                MobileTitle: this.getName('field_dial_code')?.default,
                SearchPlaceholder: '',
              },
              Phone: {
                Label: this.getName('field_phone')?.default,
              },
              Email: {
                Label: this.getName('field_email')?.default,
              },
            },
          },
          Route: {
            Icon: 'plane',
            Name: this.getName('fieldset_flight_data')?.default,
            NameNum: this.getName('flight_name')?.default,
            Numerals: this.getName('numerals')?.default?.split(','),
            Fields: {
              departure_airport: {
                label: this.getName('field_from')?.secondary,
                mobileTitle: this.getName('field_from')?.default,
              },
              arrival_airport: {
                label: this.getName('field_to')?.secondary,
                mobileTitle: this.getName('field_to')?.default,
              },
              aircraft: {
                label: this.getName('field_select')?.default,
              },
              pax: {
                label: this.getName('field_seats')?.default,
              },
              time: {
                label: this.getName('field_time')?.default,
              },
              date: {
                label: this.getName('field_date')?.default,
                mobileTitle: this.getName('field_date')?.default,
              },
              wishes: [
                {
                  type: 'checkbox',
                  value: 'russia',
                  label: this.getName('field_preference_ru')?.default,
                },
                {
                  type: 'checkbox',
                  value: 'stewardess',
                  label: this.getName('field_preference_stewardess')?.default,
                },
                {
                  type: 'checkbox',
                  value: 'western europe',
                  label: this.getName('field_preference_we')?.default,
                },
                {
                  type: 'checkbox',
                  value: 'not crucial',
                  label: this.getName('field_preference_not')?.default,
                },
              ],
            },
          },
        },
      };
    },
  },
  methods: {
    changeContent() {
      this.$emit('changeContent', {
        component: 'PopupResult',
        isResult: false,
      });
    },
    closePopup() {
      this.$emit('modal-closed');
      unfreeze();
    },
    addRoute() {
      if (this.noNewFlight) return;
      this.routes.push({
        id: `route${Date.now()}`,
        isAdditionalInfo: false,
      });
    },
    removeRoute(item) {
      const index = this.routes.indexOf(item);
      if (index === -1) {
        return;
      }

      this.routes.splice(index, 1);
    },
    async onSubmit(data) {
      console.log(data);
      const messages = data?.route.map(
        (el, i) => `
        <h3>Рейс ${i + 1}</h3>
        <p>Аэропорт отправления (код ICAO): ${el?.departure_airport}</p>
        <p>Аэропорт прибытия (код ICAO): ${el?.arrival_airport}</p>
        <p>Дата вылета: ${el?.date || '-'}</p>
        <p>Время вылета: ${el?.time || '-'}</p>
        <p>Количество пассажиров: ${el?.pax || '-'}</p>
        <p>Пожелания по типу ВС: ${el?.aircraft || '-'}</p>
        <p>Пожелания по экипажу: ${
          typeof el?.wishes === 'object' ? el?.wishes.join(', ') : el?.wishes
        }</p>
        `,
      );
      const message = messages.join('<br>');
      const response = await fetch(`${process.env.VUE_APP_FORM_HOST}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: data?.email,
          phone: `${data?.callingCode}${data?.phone}`,
          name: data?.name,
          title: 'Заявка для заказа чартера с сайта SKYPRO AVIATION',
          message,
        }),
      });

      if (response.ok) {
        this.formNotSend = false;
        this.sendSuccess = true;
      } else {
        this.formNotSend = false;
        this.sendSuccess = false;
        console.log('error');
      }
    },
    setValidationShema(lang) {
      const errorList = this.getErrorsList[lang];
      setLocale(errorList);
      this.validationShema = yup.object({
        name: yup.string().required(),
        email: yup
          .string()
          .required()
          .email(),
        phone: yup.number().required(),
        route: yup.array().of(
          yup.object({
            departure_airport: yup.string().required(),
            arrival_airport: yup.string().required(),
          }),
        ),
      });
    },
  },
  watch: {
    lang(value) {
      this.setValidationShema(value);
    },
  },
  created() {
    const lang = this.$store.getters.getLang;
    this.setValidationShema(lang);
  },
};
</script>

<style lang="scss" scoped>
.popup-order-form {
  width: 100%;

  @include wRule(
    max-width,
    (
      xl: 1438,
      lg: 994,
      sm: 100%,
    )
  );

  ::v-deep(.popup-form) {
    @include wRule(
      padding,
      (
        xl: 0,
        sm: 116 0 0,
      )
    );
  }

  ::v-deep(.header) {
    display: block;
    box-sizing: border-box;

    @include wRule(
      padding-top,
      (
        xl: 2,
        lg: 2,
        sm: 0,
      )
    );

    @include screen("sm", max) {
      display: flex;
      align-items: center;
    }

    &:not(:last-child) {
      @include wRule(
        margin-bottom,
        (
          xl: 36,
          lg: 28,
          sm: 0,
        )
      );
    }

    .title-small {
      @include wRule(
        margin-top,
        (
          xl: 32,
          lg: 28,
          sm: 0,
        )
      );
    }
  }

  .sublink {
    position: relative;
    width: 100%;
    display: block;
    color: currentColor;

    @include wRule(
      padding,
      (
        xl: 32 0 28,
        lg: 23 0 20,
        sm: 0,
      )
    );

    @include screen("sm", max) {
      width: auto;
      flex: none;
    }

    &::after {
      @include pseudo;

      background: rgba(255, 255, 255, 0.2);
      bottom: 0;

      @include wRule(
        height,
        (
          xl: 1,
          lg: 1,
          sm: 2,
        )
      );

      @include wRule(
        left,
        (
          xl: -42,
          lg: -36,
          sm: 0,
        )
      );

      @include wRule(
        right,
        (
          xl: -42,
          lg: -36,
          sm: 0,
        )
      );

      @include screen("sm", max) {
        display: none;
      }
    }

    &-wrapper {
      display: flex;
      justify-content: space-between;
    }

    &-text {
      line-height: 1.28;
      font-weight: 700;
      letter-spacing: -0.02em;

      @include wRule(
        font-size,
        (
          xl: 24,
          lg: 18,
          sm: 32,
        )
      );

      @include rule(
        display,
        (
          xl: inline-block,
          sm: none,
        )
      );

      @include screen("sm", max) {
        order: 2;
      }
    }

    ::v-deep(.icon) {
      flex: none;
      transform-origin: 50% 50%;

      @include wRule(
        margin-left,
        (
          xl: 40,
          lg: 40,
          sm: 2,
        )
      );

      @include wRule(
        margin-right,
        (
          sm: 30,
        )
      );

      @include wRule(
        width,
        (
          xl: 19,
          lg: 14,
          sm: 28,
        )
      );

      @include wRule(
        height,
        (
          xl: 20,
          lg: 14,
          sm: 28,
        )
      );

      @include screen("sm", max) {
        transform: rotate(90deg);
      }
    }
  }

  ::v-deep(.content) {
    @include wRule(
      padding,
      (
        xl: 0 42 70,
        lg: 0 36 50 48,
        sm: 68 40 60,
      )
    );

    .fieldset {
      &:not(:last-of-type) {
        @include wRule(
          margin-bottom,
          (
            xl: 75,
            lg: 58,
            sm: 66,
          )
        );
      }
    }

    .fields {
      &:not(:first-child) {
        @include wRule(
          margin-top,
          (
            xl: 32,
            lg: 14,
            sm: 23,
          )
        );
      }

      &.grid-2 {
        @include wRule(
          column-gap,
          (
            xl: 112,
            lg: 70,
            sm: 0,
          )
        );
      }
    }
  }

  ::v-deep(.bottom) {
    @include wRule(
      padding,
      (
        xl: 51 82,
        lg: 46 66 46 78,
        sm: 40,
      )
    );
  }

  .add {
    display: block;
    box-sizing: border-box;

    @include wRule(
      margin-top,
      (
        xl: 60,
        lg: 60,
        sm: 66,
      )
    );
  }

  .add-header {
    display: block;
    width: 100%;
    box-sizing: border-box;

    @include wRule(
      padding,
      (
        xl: 0 40,
        lg: 0 32,
        sm: 0,
      )
    );
  }

  .add-content {
    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 30,
          lg: 34,
          sm: 22,
        )
      );
    }

    .grid-wishes {
      &:not(:first-child) {
        @include wRule(
          margin-top,
          (
            xl: 90,
            lg: 52,
            sm: 64,
          )
        );
      }
    }
  }

  .remove-route {
    position: relative;
    color: color(error);
    vertical-align: middle;
    cursor: pointer;

    &:not(:first-child) {
      @include wRule(
        margin-left,
        (
          xl: 17,
          lg: 17,
          sm: auto,
        )
      );
    }

    ::v-deep(.icon) {
      display: inline-block;
      vertical-align: middle;
      fill: currentColor;
      margin: 0;

      @include wRule(
        width,
        (
          xl: 14,
          lg: 14,
          sm: 20,
        )
      );

      @include wRule(
        height,
        (
          xl: 14,
          lg: 14,
          sm: 20,
        )
      );
    }
  }

  .wishes-block {
    display: block;
    grid-column: 1 / 2;

    &_x2 {
      grid-column: 2 / 4;

      @include screen("lg", max) {
        grid-column: 2 / 3;
      }
    }

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          sm: 68,
        )
      );
    }
  }

  .wishes-title {
    display: block;
    font-weight: 700;
    line-height: 1.28;
    letter-spacing: -0.02em;

    @include wRule(
      font-size,
      (
        xl: 20,
        lg: 16,
        sm: 28,
      )
    );

    @include wRule(
      margin-bottom,
      (
        xl: 15,
        lg: 6,
        sm: 15,
      )
    );
  }

  .check-list {
    display: grid;
    grid-template-columns: repeat(2, pxtovw(210, xl));

    @include wRule(
      column-gap,
      (
        xl: 20,
        lg: 20,
        sm: 0,
      )
    );

    @include wRule(
      row-gap,
      (
        xl: 20,
        lg: 20,
        sm: 0,
      )
    );

    @include screen("lg", max) {
      grid-template-columns: repeat(2, pxtovw(180, lg));
    }

    @include screen("sm", max) {
      display: block;
    }

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 32,
          lg: 25,
          sm: 50,
        )
      );
    }

    .base-toggle {
      &:not(:first-child) {
        @include wRule(
          margin-top,
          (
            sm: 38,
          )
        );
      }
    }
  }
}
</style>
