<template>
  <component
    :is="tag"
    class="navigation-link"
  >
    <slot></slot>
  </component>
</template>

<script>
export default {
  name: 'NavigationLink',
  props: {
    tag: {
      type: String,
      default: 'router-link',
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation-link {
  position: relative;
  display: inline-block;
  color: currentColor;
  font: inherit;
  letter-spacing: inherit;

  &::before,
  &::after {
    position: absolute;
    content: "";
    display: block;
    top: 50%;
    width: 50vw;
    background-color: currentColor;
    transform: translateY(-50%) scaleX(0);
    transition: transform $ease-main;

    @include wRule(
      height,
      (
        xl: 2,
        lg: 2,
        sm: 4,
      )
    );

    @include rule(
      width,
      (
        xl: 50vw,
        sm: 80vw,
      )
    );
  }

  &::before {
    right: 100%;
    transform-origin: 0% 50%;

    @include wRule(
      margin-right,
      (
        xl: 54,
        lg: 50,
        sm: 48,
      )
    );
  }

  &::after {
    left: 100%;
    transform-origin: 100% 50%;

    @include wRule(
      margin-left,
      (
        xl: 54,
        lg: 50,
        sm: 48,
      )
    );
  }

  &.is-active,
  &:hover {
    &::before,
    &::after {
      transform: translateY(-50%) scaleX(1);
    }
  }
}
</style>
