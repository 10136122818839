import { createStore } from 'vuex';

export default createStore({
  state: {
    aviapagesAPIToken: 'gVCzzKp2DSWdbJ27RU7ptU5SVRaKYFsaRHf3',
    // aviapages.com
    lang: 'en',
    currentBreakpoint: 'xl',
    windowWidth: 0,
    callingCodes: [],
    userCallingCode: {},
    calculationResults: null,
    orderFormData: null,
    labels: [],
    icons: [],
    alliance: {},
    navigationData: {},
    navigation: [],
    features: [
      {
        name: 'release_year',
        icon: 'calendar',
        mesure: 'mesure_year',
      },
      {
        name: 'hours',
        icon: 'time',
        mesure: 'mesure_time',
      },
      {
        name: 'cycles_count',
        icon: 'cycle',
        mesure: 'mesure_cycle',
      },
      {
        name: 'max_distance',
        icon: 'distination',
        mesure: 'mesure_distance',
      },
      {
        name: 'fly_height',
        icon: 'distination',
        mesure: 'mesure_height',
      },
      {
        name: 'max_speed',
        icon: 'speed',
        mesure: 'mesure_speed',
      },
      {
        name: 'seats',
        icon: 'person',
        mesure: 'mesure_seats',
      },
      {
        name: 'cabin_size',
        icon: 'size',
        mesure: 'mesure_volume',
      },
      {
        name: 'trunk_size',
        icon: 'volume',
        mesure: 'mesure_volume',
      },
    ],
    navigationColors: {
      Main: false,
      Charters: true,
      Sale: true,
      SaleModel: true,
      Consulting: false,
      Fleet: false,
      FleetModel: false,
      Directory: false,
      Contacts: true,
      Item: false,
      Policy: true,
      TermsAndConditions: true,
    },
    footerThemes: {
      Main: true,
      Charters: false,
      Sale: false,
      SaleModel: false,
      Consulting: false,
      Fleet: true,
      FleetModel: true,
      Directory: false,
      Contacts: false,
      Item: false,
      Policy: false,
      TermsAndConditions: false,
    },
    errorsLocale: {
      ru: {
        mixed: {
          default: 'Поле содержит ошибку',
          required: 'Обязательное поле',
          defined: 'Поле должно быть определено',
          notType(args) {
            return `Поле должно быть ${args.type}`;
          },
        },
        number: {
          min(min) {
            return `Поле должно быть больше чем ${min}`;
          },
          max(max) {
            return `Поле должно быть меньше чем ${max}`;
          },
          integer: 'Поле должно быть целым числом',
        },
        string: {
          length(length) {
            return `Поле должно содержать точно ${length} символов`;
          },
          min(min) {
            return `Поле должно содержать не менее ${min} символов`;
          },
          max(max) {
            return `Поле должно содержать не более ${max} символов`;
          },
          email: 'Укажите корректный email',
          url: 'Укажите корректный URL',
        },
        date: {
          min(min) {
            return `Дата должна идти после ${min}`;
          },
          max(max) {
            return `Дата должна идти раньше ${max}`;
          },
        },
      },
      en: {
        mixed: {
          default: 'Field is invalid',
          required: 'Field is a required',
          defined: 'Field must be defined',
          notType(args) {
            return `Field must be a ${args.type}`;
          },
        },
        number: {
          min(min) {
            return `Field must be greater than or equal to ${min}`;
          },
          max(max) {
            return `Field must be less than or equal to ${max}`;
          },
          integer: 'Field must be an integer',
        },
        string: {
          length(length) {
            return `Field must be exactly ${length} characters`;
          },
          min(min) {
            return `Field must be at least ${min} characters`;
          },
          max(max) {
            return `Field must be at most ${max} characters`;
          },
          email: 'Field must be a valid email',
          url: 'Field must be a valid URL',
        },
        date: {
          min(min) {
            return `Field must be later than ${min}`;
          },
          max(max) {
            return `Field must be at earlier than ${max}`;
          },
        },
      },
    },
  },
  getters: {
    getLang(state) {
      return state.lang;
    },
    getNavigation(state) {
      return state.navigation;
    },
    getNavigationContacts(state) {
      return state.navigationContacts;
    },
    getBreakpoint(state) {
      return state.currentBreakpoint;
    },
    getContacts(state) {
      return state.contacts;
    },
    getNavigationData(state) {
      return state.navigationData;
    },
    getNavigationColors(state) {
      return state.navigationColors;
    },
    getFooterThemes(state) {
      return state.footerThemes;
    },
    getCallingCodes(state) {
      return state.callingCodes;
    },
    getUserCallingCode(state) {
      return state.userCallingCode;
    },
    getCalculationResults(state) {
      return state.calculationResults;
    },
    getOrderFormData(state) {
      return state.orderFormData;
    },
    getLabels(state) {
      return state.labels;
    },
    getIcons(state) {
      return state.icons;
    },
    getIconById: (state) => (id) => state.icons.find((icon) => icon.id === id),
    getLabelByName: (state) => (name) => state.labels.find((label) => label.name === name),
    getAlliance(state) {
      return state.alliance;
    },
    getFeatures(state) {
      return state.features;
    },
    getErrorList(state) {
      return state.errorsLocale;
    },
  },
  mutations: {
    setLang(state, value) {
      state.lang = value;
    },
    setBreakpoint(state, value) {
      state.currentBreakpoint = value;
    },
    setWindowWidth(state, value) {
      state.windowWidth = value;
    },
    setContacts(state, value) {
      state.contacts = value;
    },
    setNavigation(state, data) {
      // state.navigationData = data;
      state.navigation = data;
    },
    setNavigationContacts(state, value) {
      state.navigationContacts = value;
    },
    setNavigationColors(state, value) {
      state.navigationColors = value;
    },
    setFooterThemes(state, value) {
      state.navigationThemes = value;
    },
    setCallingCodes(state, value) {
      state.callingCodes = value;
    },
    setUserCallingCode(state, value) {
      state.userCallingCode = value;
    },
    setCalculationResults(state, value) {
      state.calculationResults = value;
    },
    setOrderFormData(state, value) {
      state.orderFormData = value;
    },
    setLabels(state, value) {
      state.labels = value;
      state.navigationData = {
        Main: {
          title: value.find((item) => item.name === 'navigation_main')?.default,
          tree: [],
        },
        Charters: {
          title: value.find((item) => item.name === 'navigation_charters')?.default,
          tree: [
            {
              path: '/',
              name: value.find((item) => item.name === 'navigation_main').default,
            },
          ],
        },
        Sale: {
          title: value.find((item) => item.name === 'navigation_sale')?.default,
          tree: [
            {
              path: '/',
              name: value.find((item) => item.name === 'navigation_main').default,
            },
          ],
        },
        SaleModel: {
          title: '',
          tree: [
            {
              path: '/',
              name: value.find((item) => item.name === 'navigation_main')?.default,
            },
            {
              path: '/sale',
              name: value.find((item) => item.name === 'navigation_sale')?.default,
            },
          ],
        },
        Services: {
          title: value.find((item) => item.name === 'navigation_services').default,
          tree: [
            {
              path: '/',
              name: value.find((item) => item.name === 'navigation_main')?.default,
            },
          ],
        },
        Fleet: {
          title: value.find((item) => item.name === 'navigation_fleet').default,
          tree: [
            {
              path: '/',
              name: value.find((item) => item.name === 'navigation_main')?.default,
            },
          ],
        },
        FleetModel: {
          title: '',
          tree: [
            {
              path: '/',
              name: value.find((item) => item.name === 'navigation_main')?.default,
            },
            {
              path: '/fleet',
              name: value.find((item) => item.name === 'navigation_fleet')?.default,
            },
          ],
        },
        Directory: {
          title: value.find((item) => item.name === 'navigation_directory').default,
          tree: [
            {
              path: '/',
              name: value.find((item) => item.name === 'navigation_main')?.default,
            },
          ],
        },
        Contacts: {
          title: value.find((item) => item.name === 'navigation_contacts').default,
          tree: [
            {
              path: '/',
              name: value.find((item) => item.name === 'navigation_main')?.default,
            },
          ],
        },
        Policy: {
          title: value.find((item) => item.name === 'navigation_privacy_policy').default,
          tree: [
            {
              path: '/',
              name: value.find((item) => item.name === 'navigation_main')?.default,
            },
          ],
        },
        TermsAndConditions: {
          title: value.find((item) => item.name === 'navigation_terms_and_conditions').default,
          tree: [
            {
              path: '/',
              name: value.find((item) => item.name === 'navigation_main')?.default,
            },
          ],
        },
      };
    },
    setIcons(state, value) {
      state.icons = value;
    },
    setAlliance(state, value) {
      state.alliance = value;
    },
    setFeatures(state, value) {
      state.features = value;
    },
  },
  actions: {
  },
  modules: {
  },
});
