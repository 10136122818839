export default function breakpointWatcher(instance) {
  function setBreakpoint() {
    const sizes = {
      xl: 1920,
      lg: 1484,
      sm: 768,
    };

    let breakpoint = 'xl';

    const wW = window.innerWidth;

    if (window.matchMedia(`(max-width: ${sizes.lg - 1}px)`).matches) {
      breakpoint = 'lg';
    }
    if (window.matchMedia(`(max-width: ${sizes.sm - 1}px)`).matches) {
      breakpoint = 'sm';
    }

    instance.$store.commit('setWindowWidth', wW);
    instance.$store.commit('setBreakpoint', breakpoint);
  }

  setBreakpoint();

  window.addEventListener('resize', setBreakpoint);
}
