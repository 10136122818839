<template>
  <PageSection
    class="screen centered sale-section"
    :bg="content"
    :theme="true"
  >
    <template #default>
      <div class="container">
        <h3
          class="title"
          v-show="content?.title"
          v-inview-animate:repeat="{name: 'fade-down', duration: 500, delay: 300}"
        >{{content?.title}}</h3>
        <p
          class="text"
          v-show="content?.description"
          v-html="content?.description"
          v-inview-animate:repeat="{name: 'fade-down', duration: 500, delay: 400}"
        ></p>
        <VButton
          :tag="'router-link'"
          :to="'/sale'"
          v-inview-animate:repeat="{name: 'fade-down', duration: 500, delay: 500}"
        >{{content?.button_text}}</VButton>
      </div>
    </template>
  </PageSection>
</template>

<script>
import PageSection from '../../../components/PageSection.vue';
import VButton from '../../../components/VButton.vue';

export default {
  components: { PageSection, VButton },
  name: 'SaleSection',
  props: {
    content: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.bg-media) {
  &::after {
    @include pseudo;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(26, 42, 68, 0.136) 0%,
      rgba(19, 29, 45, 0.76) 48.41%
    );
    z-index: 2;

    @include rule(
      opacity,
      (
        xl: 0.9,
        sm: 1,
      )
    );
  }
}

.sale-section {
  transform-origin: 100% 100%;

  @include wRule(
    padding,
    (
      xl: 258 0 120,
      lg: 170 0 120,
      sm: 84 0 120,
    )
  );

  @media screen and (max-height: 47vw) {
    @include wRule(
      padding,
      (
        xl: 258 0 120,
        lg: 140 0 100,
        sm: 84 0 120,
      )
    );
  }

  @include screen("sm", max) {
    min-height: 100vh;
    display: flex;
  }

  @include screen("xxl", min) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 120px 0;
  }

  ::v-deep(.container) {
    @include screen("sm", max) {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      text-align: left;
    }
  }

  ::v-deep(.title, .title-big, .title-small) {
    @include rule(
      text-align,
      (
        xl: center,
        sm: left,
      )
    );
  }

  ::v-deep(.text) {
    @include rule(
      text-align,
      (
        xl: center,
        sm: left,
      )
    );

    @include wRule(
      max-width,
      (
        xl: 840,
        lg: 590,
        sm: 100%,
      )
    );

    @include rule(
      margin-bottom,
      (
        sm: auto,
      )
    );

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 25,
          lg: 24,
          sm: 42,
        )
      );
    }
  }

  ::v-deep(.v-button) {
    @include wRule(
      width,
      (
        xl: 330,
        lg: 248,
        sm: 100%,
      )
    );

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 104,
          lg: 65,
          sm: 65,
        )
      );
    }
  }
}
</style>
