<template>
  <div class="popup-result">
    <div
      class="content"
      v-if="calculationData?.state === 'ok'"
    >
      <div class="top">
        <div class="header">
          <h3
            class="title-small"
            v-inview-animate="{name: 'fade-down', duration: 300, delay: 100}"
          >{{content?.Title}}</h3>
        </div>
        <div class="info">
          <div
            class="info-grid"
            v-inview-animate="{name: 'fade-down', duration: 300, delay: 200}"
          >
            <div class="info-block info-block_departure">
              <div class="info-ico">
                <img
                  :src="require('@/assets/airports.svg')"
                  alt="@@"
                />
              </div>
              <div class="info-describe">
                <div class="info-label">{{content?.departure_airport}}</div>
                <div
                  class="info-content"
                  :class="{'is-loading': isDepartureLoading}"
                >{{departure_airport}}<span
                    class="loader"
                    v-if="isDepartureLoading"
                  >is loading ...</span></div>
                <!-- <div class="info-content">{{calculationData?.data?.airport?.departure_airport}}</div> -->
              </div>
            </div>
            <div class="info-block info-block_arrival">
              <div class="info-ico">

              </div>
              <div class="info-describe">
                <div class="info-label">{{content?.arrival_airport}}</div>
                <div
                  class="info-content"
                  :class="{'is-loading': isArrivalLoading}"
                >{{arrival_airport}}<span
                    class="loader"
                    v-if="isArrivalLoading"
                  >is loading ...</span></div>
                <!-- <div class="info-content">{{calculationData?.data?.airport?.arrival_airport}}</div> -->
              </div>
            </div>
            <div class="info-block info-block_distance">
              <div class="info-ico">
                <Icon :name="'distination'" />
              </div>
              <div class="info-describe">
                <div class="info-label">{{content?.distance}}</div>
                <div class="info-content">{{Math.round(calculationData?.data?.distance, 0)}} {{content?.mesures?.distance}}</div>
                <!-- <div class="info-content">{{calculationData?.data?.distance?.airway}}</div> -->
              </div>
            </div>
            <div class="info-block info-block_time">
              <div class="info-ico">
                <Icon :name="'time'" />
              </div>
              <div class="info-describe">
                <div class="info-label">{{content?.time}}</div>
                <div class="info-content">{{timeString}}</div>
              </div>
            </div>
          </div>

          <div
            class="order"
            v-inview-animate="{name: 'fade-down', duration: 300, delay: 300}"
          >
            <div class="image">
              <img
                :src="require('@/assets/charter-map.svg')"
                alt="@@"
              >
            </div>
            <VButton
              tag="button"
              type="button"
              @click.prevent="changeContent"
            >{{content?.OrderButtonName}}</VButton>
          </div>
        </div>
      </div>

      <div
        class="bottom"
        v-inview-animate="{name: 'fade-down', duration: 300, delay: 500}"
      >
        <DetailsBlock
          class="reverse"
          :isExpanded="breakpoint !== 'sm'"
        >
          <template #icon>
            <Icon
              :name="'down'"
              v-show="breakpoint === 'sm'"
            />
          </template>
          <template #name>{{costTable?.title}}
          </template>
          <template #content>
            <CostTable
              class="is-light"
              :content="costTable"
            />
          </template>
        </DetailsBlock>
      </div>
    </div>

    <div
      class="content error"
      v-else
    >
      <div class="header">
        <h3
          class="title-small"
          v-inview-animate="{name: 'fade-down', duration: 300, delay: 100}"
        >{{content?.ErrorTitle}}</h3>
      </div>
      <div
        class="text"
        v-inview-animate="{name: 'fade-down', duration: 300, delay: 200}"
      >{{calculationData?.data}}</div>
    </div>
  </div>
</template>

<script>
import CostTable from './CostTable.vue';
import DetailsBlock from './DetailsBlock.vue';
import Icon from './Icon.vue';
import VButton from './VButton.vue';
import formatTime from '../tools/formatTime';
import apiGetAirportByICAO from '../tools/apiGetAirportByICAO';
import api from '../tools/api';

export default {
  components: {
    VButton,
    DetailsBlock,
    Icon,
    CostTable,
  },
  name: 'PopupResult',
  emits: ['changeContent'],
  data() {
    return {
      isArrivalLoading: false,
      isDepartureLoading: false,
      arrival_airport: '',
      departure_airport: '',
      costTable: {},
      costLoading: true,
    };
  },
  computed: {
    breakpoint() {
      return this.$store.getters.getBreakpoint;
    },
    lang() {
      return this.$store.getters.getLang;
    },
    calculationData() {
      return this.$store.getters.getCalculationResults;
    },
    content() {
      return {
        Title: this.getName('calculator_result_title')?.default,
        ErrorTitle: this.getName('error')?.secondary,
        OrderButtonName: this.getName('CharterButtonName')?.default,
        departure_airport: this.getName('field_from')?.default,
        arrival_airport: this.getName('field_to')?.default,
        time: this.getName('field_time')?.default,
        distance: this.getName('max_distance')?.default,
        mesures: {
          distance: this.getName('mesure_distance')?.default,
          time: {
            day: this.getName('mesure_time')?.secondary,
            hours: this.getName('mesure_time')?.default,
            minutes: this.getName('mesure_time')?.tertiary,
          },
        },
      };
    },
    timeString() {
      const time = formatTime(this.calculationData?.data?.time);

      console.log(time);

      let dayString = `${time?.days} ${this.content?.mesures?.time?.day}`;
      let hoursString = `${time?.hours} ${this.content?.mesures?.time?.hours}`;
      let minutesString = `${time?.minutes} ${this.content?.mesures?.time?.minutes}`;

      if (time.days === 0) {
        dayString = '';
      }
      if (time.hours === 0) {
        hoursString = '';
      }
      if (time.minutes === 0) {
        minutesString = '';
      }

      if (time.days === 0 && time.hours === 0 && time.minutes === 0) {
        return '0';
      }

      const result = `${dayString} ${hoursString} ${minutesString}`;

      return result;
    },
  },
  methods: {
    changeContent() {
      this.$emit('changeContent', {
        component: 'PopupOrderForm',
        isResult: true,
        presetData: this.calculationData?.data,
      });
    },
    async getAirports() {
      const results = this.$store.getters.getCalculationResults;
      const airportA = results?.data?.arrival_airport;
      const airportD = results?.data?.departure_airport;
      this.isArrivalLoading = true;
      const arrival = await apiGetAirportByICAO(airportA, this, 'isArrivalLoading');
      this.isDepartureLoading = true;
      const departure = await apiGetAirportByICAO(airportD, this, 'isDepartureLoading');
      this.departure_airport = `${departure?.city} (${departure?.name})`;
      this.arrival_airport = `${arrival?.city} (${arrival?.name})`;
    },
    async getCostTable() {
      const results = await api('price-table', true);
      this.costLoading = !results.statusText === 'ok';
      this.costTable = results.data;
    },
  },
  watch: {
    lang() {
      this.getCostTable();
    },
  },
  created() {
    this.getAirports();
    this.getCostTable();
  },
};
</script>

<style lang="scss" scoped>
.popup-result {
  display: block;
  position: relative;
  width: 100%;

  @include wRule(
    max-width,
    (
      xl: 1438,
      lg: 994,
      sm: 100%,
    )
  );

  @include screen("sm", max) {
    min-height: 100%;
  }

  .content {
    position: relative;

    &::before {
      @include pseudo;

      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
          180deg,
          rgba(28, 27, 65, 0) 0%,
          rgba(14, 14, 34, 0.67) 100%
        ),
        rgba(8, 10, 35, 0.3);
      backdrop-filter: blur(11px);
      z-index: 0;
    }
  }

  .top,
  .bottom,
  .header,
  .text {
    position: relative;
    z-index: 1;
  }

  .top {
    position: relative;

    &::after {
      @include pseudo;

      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(255, 255, 255, 0.2);

      @include wRule(
        height,
        (
          xl: 1,
          lg: 1,
          sm: 2,
        )
      );

      @include wRule(
        left,
        (
          sm: 40,
        )
      );
      @include wRule(
        right,
        (
          sm: 40,
        )
      );
    }
  }

  .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    box-sizing: border-box;

    @include wRule(
      padding,
      (
        xl: 45 58,
        lg: 35 35,
        sm: 0 40,
      )
    );

    @include screen("sm", max) {
      height: pxtovw(118, sm);
      border-bottom: pxtovw(2, sm) solid rgba(221, 221, 221, 0.28);
    }

    ::v-deep(.title-small) {
      @include wRule(
        font-size,
        (
          xl: 40,
          lg: 32,
          sm: 32,
        )
      );
    }
  }

  .bottom {
    display: block;
    box-sizing: border-box;
    width: 100%;

    @include wRule(
      padding,
      (
        xl: 12 134 70 168,
        lg: 10 100 40,
        sm: 36 40,
      )
    );

    overflow: hidden;
  }

  ::v-deep(.cost-table) {
    @include wRule(
      margin-top,
      (
        xl: 12,
        lg: 14,
        sm: 0,
      )
    );

    tbody {
      @include wRule(
        max-height,
        (
          xl: 180,
          lg: 180,
          sm: none,
        )
      );
    }
  }

  ::v-deep(.details-block) {
    & > .trigger {
      @include rule(
        pointer-events,
        (
          xl: none,
          sm: auto,
        )
      );

      @include wRule(
        font-size,
        (
          xl: 24,
          lg: 20,
          sm: 24,
        )
      );

      @include screen("sm", max) {
        margin-top: 0;
      }

      .name {
        @include wRule(
          margin-right,
          (
            xl: 80,
            lg: 80,
            sm: 80,
          )
        );
      }
    }
  }
}

.order {
  display: block;
  flex: none;
  text-align: right;

  @include wRule(
    width,
    (
      xl: 308,
      lg: 255,
      sm: 100%,
    )
  );

  @include wRule(
    margin-top,
    (
      sm: 64,
    )
  );

  .image {
    width: 100%;
    height: auto;

    @include rule(
      display,
      (
        xl: block,
        sm: none,
      )
    );

    @include wRule(
      margin-top,
      (
        xl: -120,
        lg: -65,
        sm: 0,
      )
    );

    @include wRule(
      margin-bottom,
      (
        xl: 48,
        lg: 26,
        sm: 0,
      )
    );

    @include rule(
      transform,
      (
        lg: translate3d(4%, 0, 0),
        sm: translate3d(0, 0, 0),
      )
    );

    img {
      display: block;
      font-size: 0;
      line-height: 0;
      width: 100%;
    }
  }

  ::v-deep(.v-button) {
    @include wRule(
      min-width,
      (
        xl: 300,
        lg: 214,
        sm: 0,
      )
    );

    @include screen("sm", max) {
      width: 100%;
    }
  }
}

.info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  box-sizing: border-box;
  width: 100%;

  @include wRule(
    padding,
    (
      xl: 50 134 70 102,
      lg: 8 100 28 40,
      sm: 66 40 84,
    )
  );

  @include screen("sm", max) {
    display: block;
  }
}

.info-grid {
  display: grid;
  grid-template-columns: pxtovw(444, xl) pxtovw(240, xl);
  flex: 1;

  @include wRule(
    max-width,
    (
      xl: 800,
      lg: 580,
      sm: 100%,
    )
  );

  @include screen("lg", max) {
    grid-template-columns: pxtovw(290, lg) pxtovw(230, lg);
  }

  @include screen("sm", max) {
    display: block;
    flex: none;
    width: 100%;
    box-sizing: border-box;
    padding-left: pxtovw(18, sm);
  }

  @include wRule(
    column-gap,
    (
      xl: 40,
      lg: 30,
      sm: 0,
    )
  );

  @include wRule(
    row-gap,
    (
      xl: 50,
      lg: 66,
      sm: 0,
    )
  );

  &:not(:last-child) {
    @include wRule(
      margin-right,
      (
        xl: 40,
        lg: 30,
        sm: 0,
      )
    );
  }
}

.info-block {
  display: flex;
  width: 100%;

  &:not(:first-child) {
    @include wRule(
      margin-top,
      (
        sm: 40,
      )
    );
  }

  &_departure {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  &_arrival {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  &_distance {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }

  &_time {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }

  .info-ico {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex: none;

    @include wRule(
      width,
      (
        xl: 32,
        lg: 32,
        sm: 32,
      )
    );

    @include wRule(
      height,
      (
        xl: 32,
        lg: 32,
        sm: 32,
      )
    );

    img {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      height: auto;

      @include wRule(
        width,
        (
          xl: 18,
          lg: 18,
          sm: 18,
        )
      );

      @include wRule(
        margin-top,
        (
          sm: 5,
        )
      );
    }
  }

  .info-describe {
    line-height: 1.6;
    letter-spacing: -0.02em;
    overflow: hidden;
    flex: 1;
    display: block;

    @include wRule(
      margin-left,
      (
        xl: 34,
        lg: 32,
        sm: 48,
      )
    );
  }

  .info-label {
    display: block;

    @include wRule(
      font-size,
      (
        xl: 16,
        lg: 14,
        sm: 24,
      )
    );

    @include screen("sm", max) {
      opacity: 0.5;
    }
  }

  .info-content {
    display: block;
    letter-spacing: -0.02em;

    @include wRule(
      font-size,
      (
        xl: 28,
        lg: 20,
        sm: 28,
      )
    );

    @include ellipsis(100%);

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 6,
          lg: 6,
          sm: 4,
        )
      );
    }
  }

  .icon {
    fill: color(icon-blue);
    width: 100%;
    height: 100%;
  }
}
</style>
