<template>
  <label
    class="aircraft-select"
    :class="[{'is-top': inputValue}, {'is-disabled': disabled}]"
    @mousewheel.stop
  >
    <Treeselect
      v-model="inputValue"
      :multiple="false"
      :options="options"
      :name="name"
      :disabled="disabled"
      :disable-branch-nodes="true"
      :clear="false"
      :search-nested="true"
      @open="addMessage"
      @close="addMessage"
      ref="tree"
    >
      <template #before-list>
        <div
          class="top"
          v-if="breakpoint==='sm'"
        >
          <div class="header">
            <div class="title">{{mobileTitle || label}}</div>
            <Close @click.prevent="closeDropdown" />
          </div>
          <div class="inner-search"></div>
        </div>
      </template>
      <template v-slot:option-label="{node, labelClassName,}">
        <label
          :class="[labelClassName, {'is-expanded': node.isExpanded || node.isSearchExpanded}, {'is-branch': node.isBranch}]"
          @click="clickBranch"
        >
          <div class="vue-treeselect__label-text">{{node.label}}</div>
          <div
            class="vue-treeselect__toggle"
            :class="{'is-rotate': node.isExpanded || node.isSearchExpanded}"
            v-if="node.isBranch"
          >
            <Icon :name="'down'" />
          </div>
        </label>
      </template>
    </Treeselect>
    <div
      class="label"
      v-if="label"
    >{{label}}</div>
  </label>
</template>

<script>
import Treeselect from 'vue3-treeselect';
import { useField } from 'vee-validate';
import apiGetAircraftsTree from '../../../tools/apiGetAircraftsTree';
import Close from '../../Close.vue';
import Icon from '../../Icon.vue';

export default {
  name: 'AircraftSelect',
  components: {
    Treeselect,
    Close,
    Icon,
  },
  props: {
    name: {
      type: String,
    },
    label: {
      type: String,
    },
    mobileTitle: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isReset: {
      type: Boolean,
      default: false,
    },
    initData: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      options: [],
    };
  },
  computed: {
    breakpoint() {
      return this.$store.getters.getBreakpoint;
    },
  },
  methods: {
    async getAircrafts() {
      this.options = await apiGetAircraftsTree();
    },
    closeDropdown() {
      this.$refs.tree.closeMenu();
    },
    addMessage() {
      document.documentElement.classList.toggle('is-select-open');
    },
    readMode() {
      this.$refs.tree.$el
        .querySelector('input')
        .setAttribute('readonly', 'readonly');
    },
    unreadMode() {
      this.$refs.tree.$el.querySelector('input').removeAttribute('readonly');
    },
    clickBranch(evt) {
      const source = evt.target.closest('.is-branch');

      const parent = source.closest('.vue-treeselect__menu');
      if (source && parent) {
        const sPar = source.getBoundingClientRect();
        const pPar = parent.getBoundingClientRect();
        const top = sPar.top - pPar.top;
        const currScroll = parent.scrollTop;
        parent.scroll({ left: 0, top: currScroll + top, behavior: 'smooth' });
      }
    },
  },
  beforeMount() {
    this.getAircrafts();
  },
  setup(props) {
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = props.initData
      ? useField(props.name, props.rules, { initialValue: props.initData })
      : useField(props.name, props.rules);

    return {
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta,
    };
  },
  mounted() {
    if (this.breakpoint === 'sm') {
      this.readMode();
    }
  },
  watch: {
    breakpoint(value) {
      if (value === 'sm') {
        this.readMode();
      } else {
        this.unreadMode();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.aircraft-select {
  position: relative;
  display: block;

  &::after {
    @include pseudo;

    bottom: 0;
    left: 0;
    width: 100%;
    background-color: currentColor;

    @include wRule(
      height,
      (
        xl: 2,
        lg: 2,
        sm: 2,
      )
    );
  }

  &.is-inview-animation-input-transition {
    &::after {
      transition: transform 0.5s ease;
      transition-delay: inherit;
      transform-origin: 0 50%;
      transform: scaleX(0);
    }

    .label {
      opacity: 0;
      transition: opacity 0.5s ease, transform 0.25s ease;
      transition-delay: inherit;
    }
  }

  &.is-inview-animation-active {
    &::after {
      transform: scaleX(1);
    }

    .label {
      opacity: 1;
    }
  }

  .single-label {
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;

    @include wRule(
      font-size,
      (
        xl: 20,
        lg: 16,
        sm: 24,
      )
    );
  }

  .label {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    box-sizing: border-box;
    pointer-events: none;
    transform-origin: 0 100%;
    transition: transform $ease-main;
    text-rendering: geometricPrecision;

    @include wRule(
      padding,
      (
        xl: 16 0,
        lg: 16 0,
        sm: 23 0,
      )
    );

    @include wRule(
      font-size,
      (
        xl: 20,
        lg: 16,
        sm: 24,
      )
    );
  }

  ::v-deep(.multiselect__element) {
    @include wRule(
      padding,
      (
        xl: 0 10,
        lg: 0 16,
        sm: 0 40,
      )
    );
  }

  ::v-deep(.multiselect__option) {
    @include wRule(
      margin,
      (
        xl: 0 -10,
        lg: 0 -16,
        sm: 0 -40,
      )
    );
    @include wRule(
      padding,
      (
        xl: 0 10,
        lg: 0 16,
        sm: 0 40,
      )
    );
  }

  &.is-error {
    &::after {
      background-color: color(error);
    }
  }

  &.is-top .label,
  .vue-treeselect--open ~ .label {
    transform: scale(0.6) translate3d(0, -121%, 0);
  }
}
</style>
