<template>
  <button
    class="close"
    type="button"
  >
    <Icon name="cross"></Icon>
  </button>
</template>

<script>
import Icon from './Icon.vue';

export default {
  name: 'Close',
  components: {
    Icon,
  },
};
</script>

<style lang="scss">
.close {
  display: block;
  box-sizing: border-box;
  color: currentColor;
  transition: color $ease-main;

  @include wRule(
    width,
    (
      xl: 55,
      lg: 48,
      sm: 55,
    )
  );

  @include wRule(
    height,
    (
      xl: 55,
      lg: 48,
      sm: 55,
    )
  );

  @include wRule(
    padding,
    (
      xl: 10,
      lg: 10,
      sm: 10,
    )
  );

  @media (hover: hover) {
    &:hover {
      color: color(main);
    }
  }

  svg {
    width: 100%;
    height: 100%;
    fill: currentColor;
  }
}
</style>
