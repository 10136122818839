import axios from 'axios';
// import store from '../store/index';

export default function apiGetAircraftTree() {
  return axios.get('/data/aircrafts_tree.json').then((response) => {
    const base = response.data.aircraft_tree;
    const activeBase = base.filter((el) => el.is_active);
    const result = {};
    activeBase.forEach((el) => {
      if (result[el.manufacturer] === undefined) {
        result[el.manufacturer] = {};
        result[el.manufacturer][el.type] = [];
        result[el.manufacturer][el.type].push({ id: el.name, label: el.name, selectable: true });
      } else if (result[el.manufacturer][el.type] === undefined) {
        result[el.manufacturer][el.type] = [];
        result[el.manufacturer][el.type].push({ id: el.name, label: el.name, selectable: true });
      } else {
        result[el.manufacturer][el.type].push({ id: el.name, label: el.name, selectable: true });
      }
    });
    const tree = Object.entries(result).map(([key, value]) => {
      const childes = Object.entries(value);
      const children = childes.map(([type, aircraft]) => {
        const childesLength = aircraft.length;
        const isEmpty = !(childesLength > 1);
        const middleRes = {};
        middleRes.id = type;
        middleRes.label = type;
        middleRes.selectable = isEmpty;

        if (!isEmpty) {
          middleRes.children = aircraft;
        }

        return middleRes;
      });

      return {
        id: key,
        label: key,
        selectable: false,
        children,
      };
    });
    console.log(tree);
    return tree;
  }).catch((error) => {
    console.error(error);
  });
}
