<template>
  <table
    class="cost-table"
    :class="{'is-dark': theme}"
  >
    <thead
      v-if="breakpoint!=='sm'"
      v-inview-animate:repeat="{name: 'fade-down', duration: 500, delay: 400}"
    >
      <tr>
        <th>{{content?.columns?.from}}</th>
        <th>{{content?.columns?.to}} </th>
        <th><span class="table-title">{{content?.columns?.price}}
            <InfoPopover :text="content?.columns?.price_tooltip" />
          </span>
        </th>
      </tr>
    </thead>
    <tbody v-inview-animate:repeat="{name: 'fade-down', duration: 500, delay: 400}">
      <tr
        v-for="(row, rowId) in content?.rows"
        :key="`row${rowId}`"
        :class="[{'is-hide': (breakpoint === 'sm' && rowId > 2) }, {'is-show': isShow }]"
      >
        <td>
          <span
            class="table-title"
            v-if="breakpoint==='sm'"
          >{{content?.columns?.from}}</span>
          <span class="table-text">{{row?.from}}</span>
        </td>
        <td>
          <span
            class="table-title"
            v-if="breakpoint==='sm'"
          >{{content?.columns?.to}}</span>
          <span class="table-text">{{row?.to}}</span>
        </td>
        <td>
          <span
            class="table-title"
            v-if="breakpoint==='sm'"
          >{{content?.columns?.price}}
            <InfoPopover :text="content?.columns?.price_tooltip" />
          </span>
          <span class="table-text">{{row?.price}}</span>
        </td>
      </tr>
    </tbody>
  </table>
  <VButton
    :tag="'button'"
    type="button"
    @click="isShow = true"
    v-if="breakpoint==='sm' && !isShow"
  >{{getName('more')?.secondary}}</VButton>
</template>

<script>
import InfoPopover from './InfoPopover.vue';
import VButton from './VButton.vue';

export default {
  components: { VButton, InfoPopover },
  name: 'CostTable',
  data() {
    return {
      isShow: false,
    };
  },
  props: {
    theme: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
    },
  },
  computed: {
    breakpoint() {
      return this.$store.getters.getBreakpoint;
    },
    lang() {
      return this.$store.getters.getLang;
    },
    // content() {
    //   return {
    //     buttonName: 'Показать больше',
    //     tooltipText: 'Стоимость указана за перелёт в 1 сторону. “Итоговая цена зависит от кол-ва пассажиров, типа выбранного ВС, даты и времени вылета, а также ограничений в связи с COVID-19.”',
    //     cellTitles: {
    //       from: 'Откуда',
    //       to: 'Куда',
    //       cost: 'Средняя стоимость',
    //     },
    //     tableData: [{
    //       from: 'Москва',
    //       to: 'Дубай',
    //       cost: 'от 30’000 EUR EUR',
    //     }, {
    //       from: 'Москва',
    //       to: 'Женева',
    //       cost: 'от 20’000 EUR EUR',
    //     }, {
    //       from: 'Москва',
    //       to: 'Лондон',
    //       cost: 'от 20’000 EUR',
    //     }, {
    //       from: 'Москва',
    //       to: 'Мале',
    //       cost: 'от 20’000 –30’000 EUR',
    //     }, {
    //       from: 'Москва',
    //       to: 'Миконос',
    //       cost: 'от  20’000 – 30’000 EUR',
    //     }, {
    //       from: 'Москва',
    //       to: 'Ницца',
    //       cost: 'от  20’000 – 30’000 EUR',
    //     }, {
    //       from: 'Москва',
    //       to: 'Ницца',
    //       cost: 'от  20’000 – 30’000 EUR',
    //     }],
    //   };
    // },
  },
};
</script>

<style lang="scss" scoped>
.cost-table {
  display: block;
  width: 100%;
  line-height: 1.6;
  overflow: hidden;
  color: color(basic-light);

  @include screen("sm", max) {
    margin: 0 pxtovw(-40, sm);
    width: 100vw;
  }

  @include wRule(
    font-size,
    (
      xl: 16,
      lg: 16,
      sm: 28,
    )
  );

  thead {
    display: block;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    width: 100%;

    tr {
      height: auto;
      text-align: left;
    }
  }

  tbody {
    display: block;
    overflow: auto;
    width: 100%;

    @include wRule(
      max-height,
      (
        xl: 360,
        lg: 360,
        sm: none,
      )
    );

    @include native-scroll(color(basic-light), transparent, 6, 6, 4);
  }

  tr {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    transition: opacity $ease-main;
    vertical-align: middle;
    box-sizing: border-box;

    @include wRule(
      height,
      (
        xl: 60,
        lg: 60,
        sm: auto,
      )
    );

    @include wRule(
      padding,
      (
        sm: 14 30 22,
      )
    );

    @include screen("sm", max) {
      display: flex;
      flex-wrap: wrap;
    }

    &.is-hide {
      @include screen("sm", max) {
        display: none;
        opacity: 0;
      }
    }

    &.is-show {
      @include screen("sm", max) {
        display: flex;
        opacity: 1;
      }
    }

    &:nth-child(even) {
      background-color: rgba(196, 196, 196, 0.08);
    }
  }

  td,
  th {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    overflow: hidden;
    vertical-align: middle;

    @include wRule(
      padding,
      (
        xl: 7 20,
        lg: 7 12,
        sm: 11 10 6,
      )
    );

    @include wRule(
      width,
      (
        xl: 428,
        lg: 288,
        sm: 50%,
      )
    );

    @include screen("sm", max) {
      display: block;
    }
  }

  .table-title {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;

    @include screen("sm", max) {
      color: rgba(color(basic-light), 0.5);
    }

    @include wRule(
      font-size,
      (
        xl: 16,
        lg: 14,
        sm: 24,
      )
    );
  }

  .table-text {
    display: block;

    @include wRule(
      margin-top,
      (
        sm: 4,
      )
    );
  }

  &.is-dark {
    color: color(basic);

    thead {
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }

    tbody {
      @include native-scroll(color(basic), transparent, 6, 6, 4);
    }

    tr {
      &:nth-child(even) {
        background-color: rgba(196, 196, 196, 0.16);
      }
    }

    td,
    th {
      @include wRule(
        padding,
        (
          xl: 7 20,
          lg: 7 20,
          sm: 11 10 6,
        )
      );

      @include wRule(
        width,
        (
          xl: 428,
          lg: 288,
          sm: 50%,
        )
      );

      @media screen and (min-width: 1940px) {
        justify-content: center;
        width: 33.33333%;
      }
    }

    .table-title {
      @include wRule(
        font-size,
        (
          xl: 16,
          lg: 16,
          sm: 24,
        )
      );

      @include screen("sm", max) {
        color: rgba(color(basic), 0.5);
      }
    }
  }
}

.v-button {
  width: 100%;

  &:not(:first-child) {
    @include wRule(
      margin-top,
      (
        sm: 35,
      )
    );
  }
}
</style>
