<template>
  <div class="loader">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 52.3 52"
    >
      <!-- <path
        d="M52.12,22.87a25.52,25.52,0,0,0-5.5-12.93A26,26,0,0,0,23,.19a25.49,25.49,0,0,0-12.95,5.3A25.88,25.88,0,0,0,.19,29.1a25.53,25.53,0,0,0,5.5,13A26,26,0,0,0,29.3,51.81h0a25.41,25.41,0,0,0,13-5.36,26,26,0,0,0,9.84-23.58ZM47.85,38.11a24.73,24.73,0,1,1-19-36.63v.14a24.57,24.57,0,0,1,19,36.49Z"
        fill="#2a5388"
      /> -->
      <!-- <path
        d="M27.9,25.4a1.86,1.86,0,0,1-.54,1.27,1.84,1.84,0,0,1-1.27.54,1.8,1.8,0,1,1,1.27-3.08A1.84,1.84,0,0,1,27.9,25.4Z"
        fill="#2a5388"
      />
      <path
        d="M26.09,31.44a7.05,7.05,0,0,1-7-6.57,2.35,2.35,0,0,0,0,.53,7.06,7.06,0,0,0,9.77,6.51,7.16,7.16,0,0,0,2.29-1.52,7.08,7.08,0,0,0,2.07-5v-.53a7.07,7.07,0,0,1-2.24,4.67,7.14,7.14,0,0,1-4.83,1.9"
        fill="#2a5388"
      /> -->
      <path
        d="M26.17,18a.27.27,0,0,0,.19-.08.32.32,0,0,0,.08-.2V8.24a4.13,4.13,0,0,0-.19-1.71,4.08,4.08,0,0,0-.86-1.48A4.06,4.06,0,0,0,24,4a4.26,4.26,0,0,0-1.67-.4H18.88s5.5,1.29,7,14.17A.31.31,0,0,0,26,18a.32.32,0,0,0,.19.07"
        fill="#2a5388"
      />
      <path
        d="M26.17,34a.27.27,0,0,0-.28.28v9.61a4.3,4.3,0,0,0,1.05,3.19,4.2,4.2,0,0,0,3,1.42h3.49s-5.5-1.29-7-14.17a.29.29,0,0,0-.11-.18.31.31,0,0,0-.19-.06"
        fill="#2a5388"
      />
      <path
        d="M23,18.56a.24.24,0,0,0,.07-.18A.26.26,0,0,0,23,18.2L19.4,9.42a4.18,4.18,0,0,0-.82-1.49,4.31,4.31,0,0,0-1.34-1.06,4.44,4.44,0,0,0-1.65-.44,4.37,4.37,0,0,0-1.68.24L10.69,8s5.5-.94,11.9,10.4a.31.31,0,0,0,.18.07.29.29,0,0,0,.18-.07"
        fill="#2a5388"
      />
      <path
        d="M29.27,33.44l-.09.06a.16.16,0,0,0-.06.08.23.23,0,0,0,0,.11.43.43,0,0,0,0,.11l3.66,8.78a4.18,4.18,0,0,0,.82,1.49,4.31,4.31,0,0,0,1.34,1.06,4.28,4.28,0,0,0,3.34.2l3.27-1.4S36,44.86,29.6,33.52a.18.18,0,0,0-.07-.05l-.09,0-.09,0-.08.05"
        fill="#2a5388"
      />
      <path
        d="M20.48,20.32a.28.28,0,0,0,.08-.2.27.27,0,0,0-.08-.19l-6.74-6.72a4.24,4.24,0,0,0-3-1.53,4.44,4.44,0,0,0-1.7.24,4.49,4.49,0,0,0-1.48.9L5.11,15.27s4.81-3,15,5.07a.28.28,0,0,0,.19.07.31.31,0,0,0,.2-.07"
        fill="#2a5388"
      />
      <path
        d="M31.86,31.68a.28.28,0,0,0-.08.2.27.27,0,0,0,.08.19l6.74,6.72a4.19,4.19,0,0,0,3,1.53,4.47,4.47,0,0,0,1.7-.24,4.35,4.35,0,0,0,1.47-.9l2.45-2.45s-4.81,3-15-5.07a.29.29,0,0,0-.38,0"
        fill="#2a5388"
      />
      <path
        d="M18.72,22.93a.28.28,0,0,0-.14-.36l-8.8-3.65a4.22,4.22,0,0,0-3.36-.26,4.21,4.21,0,0,0-2.49,2.26L2.61,24.13s3.3-4.61,15.78-1l.1,0a.19.19,0,0,0,.1,0,.24.24,0,0,0,.08-.06A.41.41,0,0,0,18.72,22.93Z"
        fill="#2a5388"
      />
      <path
        d="M33.62,29.08a.26.26,0,0,0-.07.17.29.29,0,0,0,.07.18l8.8,3.65a4.15,4.15,0,0,0,1.65.49,4.32,4.32,0,0,0,1.71-.22,4.27,4.27,0,0,0,2.49-2.27l1.35-3.21s-3.33,4.61-15.81,1l-.1,0a.19.19,0,0,0-.1,0,.24.24,0,0,0-.08.06.31.31,0,0,0-.05.1"
        fill="#2a5388"
      />
      <path
        d="M18.11,26a.27.27,0,0,0-.08-.19.24.24,0,0,0-.19-.08H8.3A4.1,4.1,0,0,0,6.63,26a4.26,4.26,0,0,0-1.45.86,4.44,4.44,0,0,0-1,1.36,4.2,4.2,0,0,0-.39,1.64v3.46s1.29-5.5,14.19-7a.2.2,0,0,0,.1,0,.16.16,0,0,0,.08-.06.19.19,0,0,0,.05-.09.17.17,0,0,0,0-.1"
        fill="#2a5388"
      />
      <path
        d="M34.22,26a.27.27,0,0,0,.28.27H44a4.13,4.13,0,0,0,1.71-.19,4.34,4.34,0,0,0,1.49-.85,4.31,4.31,0,0,0,1.42-3.05V18.7s-1.29,5.49-14.19,7a.32.32,0,0,0-.18.1A.37.37,0,0,0,34.22,26Z"
        fill="#2a5388"
      />
      <path
        d="M18.74,29.08a.25.25,0,0,0-.35-.14L9.56,32.56a4.27,4.27,0,0,0-1.49.82A4.14,4.14,0,0,0,7,34.72a4.32,4.32,0,0,0-.45,1.65,4.37,4.37,0,0,0,.24,1.68l1.35,3.22s-.94-5.5,10.42-11.89a.29.29,0,0,0,.14-.15.26.26,0,0,0,0-.21"
        fill="#2a5388"
      />
      <path
        d="M33.62,22.93a.3.3,0,0,0,.07.1.21.21,0,0,0,.1.06.27.27,0,0,0,.13,0,.22.22,0,0,0,.11-.05l8.8-3.62a4.18,4.18,0,0,0,1.49-.82,4.31,4.31,0,0,0,1.06-1.34,4.49,4.49,0,0,0,.44-1.65A4.37,4.37,0,0,0,45.58,14l-1.35-3.21s.94,5.49-10.42,11.88a.3.3,0,0,0-.06.18.28.28,0,0,0,.06.18"
        fill="#2a5388"
      />
      <path
        d="M20.48,31.68a.28.28,0,0,0-.2-.08.27.27,0,0,0-.19.08l-6.73,6.76a4.23,4.23,0,0,0-1.08,1.34,4.29,4.29,0,0,0-.21,3.37,4.25,4.25,0,0,0,.9,1.46l2.45,2.45s-3-4.81,5.08-15a.31.31,0,0,0,.07-.2.26.26,0,0,0-.07-.19"
        fill="#2a5388"
      />
      <path
        d="M31.86,20.32a.27.27,0,0,0,.38,0L39,13.73a4.15,4.15,0,0,0,1.09-1.34,4.09,4.09,0,0,0,.46-1.67A4.16,4.16,0,0,0,40.28,9a4.27,4.27,0,0,0-.91-1.46L36.92,5.11s3,4.8-5.09,15a.29.29,0,0,0,0,.38"
        fill="#2a5388"
      />
      <path
        d="M23,33.44a.28.28,0,0,0-.35.14L19,42.36A4.19,4.19,0,0,0,18.54,44a4.11,4.11,0,0,0,.21,1.7,4.19,4.19,0,0,0,.88,1.48,4.25,4.25,0,0,0,1.4,1l3.21,1.35s-4.59-3.33-1-15.79a.36.36,0,0,0,0-.1.29.29,0,0,0,0-.1l-.06-.08Z"
        fill="#2a5388"
      />
      <path
        d="M29.25,18.56a.29.29,0,0,0,.18.07.31.31,0,0,0,.18-.07l3.65-8.79a4.1,4.1,0,0,0,.54-1.68,4.15,4.15,0,0,0-.19-1.75,4.09,4.09,0,0,0-.89-1.52,4.23,4.23,0,0,0-1.44-1L28,2.44s4.62,3.33,1,15.79a.17.17,0,0,0,0,.1.3.3,0,0,0,0,.1.27.27,0,0,0,.07.08A.19.19,0,0,0,29.25,18.56Z"
        fill="#2a5388"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Loader',
};
</script>

<style lang="scss" scoped>
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;

  @include wRule(
    padding,
    (
      xl: 20,
      lg: 20,
      sm: 20,
    )
  );

  svg {
    @include wRule(
      width,
      (
        xl: 50,
        lg: 50,
        sm: 50,
      )
    );
    @include wRule(
      height,
      (
        xl: 50,
        lg: 50,
        sm: 50,
      )
    );

    flex: none;
    transform-origin: 50% 50%;
    animation: loader 10s linear infinite;
  }
}
</style>
