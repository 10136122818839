import axios from 'axios';

export default function apiGetAirportByICAO(icao, instance, key) {
  const inst = instance;
  const result = axios.get(`${process.env.VUE_APP_PROXY_HOST}/airports/${icao}`, {
    // withCredentials: true,
    // headers: {
    //   Authorization: 'Token gVCzzKp2DSWdbJ27RU7ptU5SVRaKYFsaRHf3',
    // },
    params: {
      fields: 'city, name',
    },
  }).then((response) => {
    console.log(response.data);
    inst[key] = false;
    return response.data;
  }).catch((error) => {
    inst[key] = false;
    console.log(error);
  });
  return result;
}
