<template>
  <button
    class="tab"
    type="button"
    @click="$emit('click')"
  >
    <div class="wrapper">
      <slot></slot>
    </div>
  </button>
</template>

<script>
export default {
  name: 'Tab',
};
</script>

<style lang="scss" scoped>
.tab {
  position: relative;
  display: block;
  font-weight: 700;
  line-height: 1.28;
  box-sizing: border-box;
  color: color(tab-color);
  transition: border $ease-main, color $ease-main, box-shadow $ease-main;
  overflow: hidden;
  letter-spacing: -0.02em;

  @include wRule(
    font-size,
    (
      xl: 20,
      lg: 16,
      sm: 24,
    )
  );

  @include wRule(
    padding,
    (
      xl: 20,
      lg: 18 20,
      sm: 30 10,
    )
  );

  @include wRule(
    min-width,
    (
      xl: 180,
      lg: 180,
      sm: 50%,
    )
  );

  &::before {
    @include pseudo;

    bottom: 0;
    left: 0;
    width: 100%;
    background-color: color(tab-border);
    transition: transform $ease-main, background $ease-main;
    transform-origin: 50% 100%;

    @include wRule(
      height,
      (
        xl: 1,
        lg: 1,
        sm: 1,
      )
    );
  }

  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    white-space: nowrap;
  }

  &:hover {
    color: color(basic);
  }

  &.is-active {
    color: color(basic);

    &::before {
      background-color: color(border-blue);

      @include rule(
        transform,
        (
          xl: scaleY(4),
          sm: scaleY(6),
        )
      );
    }
  }

  &.is-light {
    &:hover {
      color: color(basic-light);
    }

    &.is-active {
      color: color(basic-light);
    }
  }
}
</style>
