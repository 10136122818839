<template>
  <footer
    class="page-footer"
    :class="[{'is-dark': theme}]"
    v-observe-visibility="{callback: animation, once: true}"
  >
    <div class="container-wide">
      <transition name="fade-down">
        <div
          class="wrapper"
          v-show="inView"
        >
          <!-- Top -->
          <div class="
        top">
            <transition name="fade-down">
              <router-link
                to="/"
                class="logo"
                v-show="inView"
              >
                <Icon
                  :name="logo"
                  viewBox="0 0 366 52"
                />
              </router-link>
            </transition>
            <transition name="fade-down">
              <ul
                class="menu"
                v-show="inView"
              >
                <li
                  v-for="link in navigation"
                  :key="link.name"
                >
                  <Component
                    :is="link.popup ? 'button' : 'router-link'"
                    :to="link.popup ? undefined : link?.href"
                    @click="link.popup ? popupShow(link?.popup) : null"
                  >
                    {{ link.name }}
                  </Component>
                </li>
              </ul>
            </transition>
            <!-- <transition name="
                    fade-down">
              <p
                class="copyright"
                v-show="inView"
                v-if="breakpoint!== 'xl'"
              >{{ navigationData?.copyright_short }}</p>
            </transition> -->
            <transition name="fade-down">
              <div
                class="contacts"
                v-show="inView"
              >
                <!-- <a
                  :href="`mailto:${navigationData?.main_email}`"
                  target="_blank"
                  class="email"
                  v-if="breakpoint !== 'xl'"
                >
                  {{navigationData?.main_email}}
                </a> -->
                <Socials />
              </div>
            </transition>
          </div>

          <!-- Bottom -->
          <transition name="fade-down">
            <div
              class="bottom"
              v-show="inView"
            >

              <div
                class="copyright-text"
                v-html="navigationData?.copyright_long"
              />
              <a
                :href="`mailto:${navigationData?.main_email}`"
                target="_blank"
                class="email"
              >
                {{navigationData?.main_email}}
              </a>
              <p
                class="copyright"
                v-show="inView"
                v-if="breakpoint === 'xl'"
              >{{ navigationData?.copyright_short }}</p>

              <div class="made-in">{{ navigationData?.made_in_text }} <a
                  href="https://www.bsagency.design/"
                  target="_blank"
                >BS Agency</a></div>

            </div>
          </transition>
        </div>
      </transition>
    </div>
  </footer>
</template>

<script>
import Icon from './Icon.vue';
import Socials from './Socials.vue';
import { animationMixin } from './mixins/animation';

export default {
  name: 'PageFooter',
  components: {
    Socials,
    Icon,
  },
  mixins: [animationMixin],
  emits: ['show-popup'],
  data() {
    return {
      logo: 'logo-aviation',
    };
  },
  computed: {
    breakpoint() {
      return this.$store.getters.getBreakpoint;
    },
    navigation() {
      return [
        {
          name: this.getName('navigation_main')?.default,
          href: '/',
        },
        {
          name: this.getName('navigation_sale')?.default,
          href: '/sale',
        },
        {
          name: this.getName('navigation_charters')?.default,
          href: '/charters',
        },
        {
          name: this.getName('navigation_services')?.default,
          href: '/services',
        },
        {
          name: this.getName('navigation_fleet')?.default,
          href: '/fleet',
        },
        {
          name: this.getName('navigation_directory')?.default,
          href: '/directory',
        },
        // {
        //   name: this.getName('navigation_alliance')?.default,
        //   popup: 'Alliance',
        // },
        {
          name: this.getName('navigation_contacts')?.default,
          href: '/contacts',
        },
        {
          name: this.getName('navigation_privacy_policy')?.default,
          href: '/privacy-policy',
        },
        {
          name: this.getName('navigation_terms_and_conditions')?.default,
          href: '/terms-and-conditions',
        },
      ];
    },
    navigationData() {
      return this.$store.getters.getNavigation;
    },
    footerThemes() {
      return this.$store.getters.getFooterThemes;
    },
    theme() {
      return this.footerThemes[this.$route.name];
    },
  },
  methods: {
    popupShow(component) {
      this.$emit('show-popup', { component });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-footer {
  position: relative;
  color: color(basic);
  letter-spacing: -0.02em;
  background-color: color(bg);
  transition: color $ease-main, background $ease-main;

  @include wRule(
    padding,
    (
      xl: 40 0,
      lg: 40 0,
      sm: 40 0,
    )
  );

  .wrapper {
    @include wRule(
      padding-top,
      (
        xl: 28,
        lg: 32,
        sm: 34,
      )
    );

    border-top: 2px solid currentColor;

    @include wRule(
      border-top-width,
      (
        xl: 2,
        lg: 2,
        sm: 3,
      )
    );

    &.fade-down-enter-active {
      transition-duration: 0.5s;
    }

    &.fade-down-leave-active {
      transition-duration: 0.5s;
    }
  }

  /*
  Top
   */
  .top {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include screen("lg", max) {
      flex-wrap: wrap;
      align-content: flex-start;
    }
  }

  .contacts {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include screen("sm", max) {
      justify-content: space-between;
      // margin-top: pxtovw(16, sm);
    }

    @include rule(position, (xl: relative, lg: absolute, md: relative));
    @include rule(top, (lg: 100%, md: auto));
    @include rule(right, (lg: 0%, md: auto));

    @include wRule(width, (
      lg: 100%,
      md: auto,
    ));

    @include wRule(
      margin-top,
      (
        lg: 17,
        md: 0,
      )
    );

    &.fade-down-enter-active {
      transition-duration: 0.5s;
      transition-delay: 0.9s;
    }

    &.fade-down-leave-active {
      transition-duration: 0.5s;
    }
  }

  .logo {
    display: block;
    transition: opacity $ease-main;

    @media (hover: hover) {
      &:hover {
        opacity: 0.8;
      }
    }

    svg {
      display: block;

      @include wRule(
        width,
        (
          xl: 337,
          lg: 314,
          sm: 282,
        )
      );

      @include wRule(
        height,
        (
          xl: 48,
          lg: 45,
          sm: 40,
        )
      );
    }

    &.fade-down-enter-active {
      transition-duration: 0.5s;
      transition-delay: 0.6s;
    }

    &.fade-down-leave-active {
      transition-duration: 0.5s;
    }
  }

  .menu {
    display: flex;
    align-items: center;

    @include rule(
      display,
      (
        xl: flex,
        sm: grid,
      )
    );

    @include rule(order, (md: 1, sm: 2));

    @include wRule(column-gap, (sm: 40));
    @include wRule(row-gap, (sm: 48));

    @include screen('sm', max) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include wRule(
      margin,
      (
        xl: 0 20,
        lg: 0 0 0 20,
        md: 35 0 0 0,
        sm: 80 0 0 0,
      )
    );

    &.fade-down-enter-active {
      transition-duration: 0.5s;
      transition-delay: 0.7s;
    }

    &.fade-down-leave-active {
      transition-duration: 0.5s;
    }
  }

  li {
    @include wRule(
      font-size,
      (
        xl: 16,
        lg: 13,
        md: 12,
        sm: 25
      )
    );

    font-weight: 700;
    line-height: 1;

    @include rule(line-height, (xl: 1, sm: 1.28))

    a,
    button {
      position: relative;
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
      letter-spacing: inherit;
      color: inherit;

      &::before {
        @include pseudo;

        font-size: inherit;
        left: 0;
        bottom: -0.04em;
        width: 100%;
        height: 0.1em;
        background-color: currentColor;
        transition: transform 0.4s ease;
        transform: scaleX(0);
        transform-origin: 0 50%;
      }

      @media (hover: hover) {
        &:hover {
          &::before {
            transform: scaleX(1);
          }
        }
      }
    }

    &:not(:first-child) {
      @include wRule(
        margin-left,
        (
          xl: 40,
          lg: 17,
          md: 36,
          sm: 0,
        )
      );
    }
  }

  .copyright {
    @include wRule(
      margin,
      (
        xl: 0 20,
        lg: 0 20,
        sm: 0 0 0 20,
      )
    );

    // @include wRule(
    //   font-size,
    //   (
    //     xl: 16,
    //     lg: 16,
    //     sm: 16,
    //   )
    // );

    font-weight: 700;
    line-height: 1.28;

    &.fade-down-enter-active {
      transition-duration: 0.5s;
      transition-delay: 0.8s;
    }

    &.fade-down-leave-active {
      transition-duration: 0.5s;
    }
  }

  .email {
    position: relative;
    color: color(gray);

    @include wRule(
      margin,
      (
        xl: 0 20,
        lg: 0,
      )
    );

    @include wRule(
      margin-right,
      (
        lg: auto,
        md: 0,
      )
    );

    // @include wRule(
    //   font-size,
    //   (
    //     xl: 16,
    //     lg: 16,
    //     sm: 16,
    //   )
    // );

    // @include rule(
    //   color,
    //   (
    //     xl: color(gray),
    //     lg: currentColor,
    //   )
    // );

    line-height: 1.28;

    &::before {
      @include pseudo;

      font-size: inherit;
      left: 0;
      bottom: -0.04em;
      width: 100%;
      height: 0.1em;
      background-color: currentColor;
      transition: transform 0.4s ease;
      transform: scaleX(0);
      transform-origin: 0 50%;
    }

    @media (hover: hover) {
      &:hover {
        &::before {
          transform: scaleX(1);
        }
      }
    }
  }

  .socials {
    @include wRule(
      margin-left,
      (
        lg: 52,
        sm: 52,
      )
    );

    ::v-deep(.social) {
      @include wRule(
        width,
        (
          xl: 33,
          lg: 33,
          sm: 40,
        )
      );
      @include wRule(
        height,
        (
          xl: 33,
          lg: 33,
          sm: 40,
        )
      );

      &:not(:first-child) {
        @include wRule(
          margin-left,
          (
            xl: 38,
            lg: 38,
            sm: 43,
          )
        );
      }
    }
  }

  /*
  Bottom
   */
  .bottom {
    display: flex;
    justify-content: space-between;

    @include rule(
      line-height,
      (
        xl: 1.28,
        lg: 1.5,
        sm: 1.28,
      )
    );

    // @include rule(
    //   display,
    //   (
    //     sm: flex,
    //   ),
    //   min
    // );

    @include rule(
      align-items,
      (
        xl: flex-start,
        lg: flex-end,
        sm: flex-start,
      )
    );

    @include rule(flex-direction, (sm: column));
    @include rule(flex-wrap, (lg: wrap));
    @include rule(justify-content, (xl: space-between, lg: flex-start));

    @include wRule(
      font-size,
      (
        xl: 16,
        lg: 14,
        md: 12,
        sm: 25,
      )
    );

    @include wRule(
      margin-top,
      (
        xl: 43,
        lg: 24,
        md: 38,
        sm: 77,
      )
    );

    &.fade-down-enter-active {
      transition-duration: 0.5s;
      transition-delay: 1s;
    }

    &.fade-down-leave-active {
      transition-duration: 0.5s;
    }
  }

  .copyright-text {
    // @include wRule(
    //   font-size,
    //   (
    //     xl: 16,
    //     lg: 16,
    //     sm: 16,
    //   )
    // );

    @include rule(
      color,
      (
        xl: color(gray),
      )
    );

    @include wRule(
      margin-right,
      (
        lg: 55,
        md: 30,
        sm: 0,
      )
    );

    @include wRule(
      margin-bottom,
      (
        sm: 40,
      )
    );

    ::v-deep(br) {
      display: none;

      &:nth-last-of-type(1) {
        @include screen("lg", max) {
          display: block;
        }
      }

      @include screen("sm", max) {
        display: block;
      }
    }

    // @include wRule(
    //   margin-right,
    //   (
    //     xl: 94,
    //     lg: 94,
    //   )
    // );
  }

  .made-in {
    font-weight: 700;
    white-space: nowrap;

    // @include wRule(
    //   font-size,
    //   (
    //     xl: 16,
    //     lg: 16,
    //     sm: 16,
    //   )
    // );

    @include rule(margin-left, (lg: auto, sm: 0));
    @include rule(text-align, (lg: right, sm: left));
    @include wRule(margin-top, (lg: 42, md: 38, sm: 86));
    @include rule(order, (lg: 2, sm: 1));
    @include rule(width, (lg: 50%, sm: auto));

    @include rule(
      color,
      (
        xl: color(gray),
      )
    );

    a {
      color: color(basic);
      transition: color $ease-main;

      &:hover {
        color: color(main);
      }
    }
  }

  &.is-dark {
    color: color(basic-light);
    background-color: color(dark);

    .copyright-text {
      @include rule(
        color,
        (
          xl: currentColor,
          lg: rgba(color(basic-light), 0.6),
        )
      );
    }

    .email {
      color: currentColor;
    }

    .made-in {
      @include rule(
        color,
        (
          xl: currentColor,
          sm: rgba(color(basic-light), 0.5),
        )
      );

      a {
        color: color(basic-light);

        &:hover {
          color: color(main);
        }
      }
    }
  }
}
</style>
