<template>
  <component
    :is="tag"
    class="v-button"
  >
    <div class="v-button__wrapper">
      <slot name="start"></slot>
      <span class="v-button__text">
        <slot></slot>
      </span>
      <slot name="end"></slot>
    </div>
  </component>
</template>

<script>
export default {
  name: 'VButton',
  props: {
    tag: {
      type: String,
      default: 'button',
    },
  },
};
</script>

<style lang="scss" scoped>
.v-button {
  $b: &;
  position: relative;
  display: inline-block;
  text-align: center;
  font-weight: 700;
  border-style: solid;
  border-color: currentColor;
  line-height: 1;
  box-sizing: border-box;
  color: currentColor;
  cursor: pointer;

  @include wRule(
    padding,
    (
      xl: 0 19,
      lg: 0 17,
      sm: 0 15,
    )
  );

  @include wRule(
    border-width,
    (
      xl: 3,
      lg: 3,
      md: 3,
      sm: 3,
    )
  );

  @include wRule(
    height,
    (
      xl: 64,
      lg: 52,
      sm: 88,
    )
  );

  @include wRule(
    font-size,
    (
      xl: 20,
      lg: 16,
      sm: 28,
    )
  );

  &::before {
    @include pseudo;

    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: currentColor;
    opacity: 0;
    transition: opacity $ease-main;
    z-index: -1;
  }

  @media (hover: hover) {
    &:hover {
      &::before {
        opacity: 0.05;
      }
    }
  }

  &:focus {
    &::before {
      @include screen("sm", min) {
        opacity: 0.1;
      }
    }
  }

  &__wrapper {
    display: flex;
    height: 100%;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
  }

  &__text {
    margin-top: 0;
    margin-bottom: 0;
  }

  ::v-deep(.icon) {
    &_pdf {
      @include wRule(
        width,
        (
          xl: 15,
          lg: 15,
          sm: 23,
        )
      );
    }

    &_update {
      @include wRule(
        width,
        (
          xl: 20,
          lg: 20,
          sm: 40,
        )
      );
    }

    &_bold-cross {
      @include wRule(
        width,
        (
          xl: 14,
          lg: 14,
          sm: 28,
        )
      );
    }
  }

  &.thin {
    @include rule(
      border-width,
      (
        xl: 1px,
        lg: 1px,
        sm: 2px,
      )
    );

    @include wRule(
      padding,
      (
        xl: 0 10,
        lg: 0 10 0 20,
        sm: 0 74 0 10,
      )
    );

    #{$b} {
      &__wrapper {
        justify-content: flex-start;

        @include screen("sm", max) {
          justify-content: center;
        }
      }

      &__text {
        font-weight: 400;

        @include wRule(
          font-size,
          (
            xl: 20,
            lg: 16,
            sm: 24,
          )
        );
      }
    }
  }

  &.thin-centered {
    @include wRule(
      height,
      (
        xl: 64,
        lg: 45,
        sm: 88,
      )
    );

    @include rule(
      border-width,
      (
        xl: 1px,
        lg: 1px,
        sm: 2px,
      )
    );

    @include wRule(
      padding,
      (
        xl: 0 52 0 36,
        lg: 0 52 0 36,
        sm: 0 74 0 51,
      )
    );

    #{$b} {
      &__wrapper {
        justify-content: flex-start;
      }

      &__text {
        font-weight: 400;
        margin: 0 auto;

        @include wRule(
          font-size,
          (
            xl: 20,
            lg: 16,
            sm: 24,
          )
        );
      }
    }
  }

  &.noborder {
    border: none;
    height: auto;
    transition: opacity $ease-main;

    &::before {
      display: none;
    }

    @media (hover: hover) {
      &:hover {
        opacity: 0.4;
      }
    }
  }

  &[disabled] {
    pointer-events: none;

    .v-button__wrapper {
      opacity: 0.5;
    }
  }
}
</style>
