<template>
  <div
    class="site-navigation"
    :class="{'is-active': isActive}"
  >
    <div class="header">
      <transition name="fade">
        <VButton
          tag="button"
          type="button"
          @click="charterShow"
          v-if="breakpoint !== 'sm'"
          v-show="isShown"
        >{{content?.buttonName}}</VButton>
      </transition>
      <!-- <transition name="scalein">
        <LangButton v-show="isShown" />
      </transition> -->
      <transition name="scalein">
        <Socials v-show="isShown" />
      </transition>
      <transition name="scalein-rotate">
        <Close
          @click="closeNavigation"
          v-show="isShown"
        />
      </transition>
    </div>
    <div class="content">
      <nav>
        <transition-group
          name="slide-fade"
          tag="div"
        >
          <div
            class="item"
            v-for="(link, linkId) in content?.navigation"
            :key="`nav-${linkId}`"
            v-show="isShown"
          >
            <NavigationLink
              :tag="link.popup ? 'button' : 'router-link'"
              :type="link.popup ? 'button' : undefined"
              :to="link.popup ? undefined : link?.href"
              @click="link.popup ? popupShow(link?.popup) : $emit('hideNavigation')"
              :class="{'is-active': (link?.href !== '/' && $route.path.includes(link.href))}"
            >{{link?.name}}</NavigationLink>
          </div>
        </transition-group>
      </nav>
      <transition name="slide-fade">
        <VButton
          tag="button"
          type="button"
          @click="charterShow"
          v-if="breakpoint === 'sm'"
          v-show="isShown"
        >{{content?.buttonName}}</VButton>
      </transition>
      <div class="contacts">
        <transition-group name="slide-fade">
          <Link
            v-for="(link, linkId) in content?.contacts"
            :key="`${link.type}-${linkId}`"
            :class="`link link_${link.type}`"
            :tag="'a'"
            :href="`${link.type === 'phone' ? `tel:${link.value.replace(/ /gi, '')}` : ''}${link.type === 'email' ? `mailto:${link.value}` : ''}${link.type === 'whatsapp' ? `https://wa.me/${link.value.replace(/ /gi, '').replace('+', '')}` : ''}`"
            v-show="isShown"
          >
          <template #start>
            <span class="circle">
              <Icon :name="getIcon(link?.icon)?.name" />
            </span>
          </template>
          <template #default>{{link?.value}}</template>
          </Link>
        </transition-group>
      </div>

    </div>
    <teleport to="#popups">
      <transition name="fade">
        <Popup
          v-if="popupActive"
          @modal-closed="popupActive = false"
          :isShown="popupActive"
        >
          <template #default>
            <component :is="currentPopup" />
          </template>
        </Popup>
      </transition>
    </teleport>
  </div>
</template>

<script>
// import LangButton from './LangButton.vue';
import Socials from '../../Socials.vue';
import VButton from '../../VButton.vue';
import Close from '../../Close.vue';
import NavigationLink from './NavigationLink.vue';
import Popup from '../../Popup.vue';
import Alliance from '../../Alliance/Alliance.vue';
import Link from '../../Link.vue';
import Icon from '../../Icon.vue';

export default {
  name: 'SiteNavigation',
  components: {
    Close,
    // LangButton,
    Socials,
    VButton,
    NavigationLink,
    Popup,
    Alliance,
    Link,
    Icon,
  },
  emits: ['hideNavigation', 'charterClick'],
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    isShown: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      popupActive: false,
      currentPopup: 'div',
    };
  },
  computed: {
    content() {
      return {
        buttonName: this.getName('CharterButtonName')?.default,
        navigation: [
          {
            name: this.getName('navigation_main')?.default,
            href: '/',
            popup: false,
          },
          {
            name: this.getName('navigation_sale')?.default,
            href: '/sale',
            popup: false,
          },
          {
            name: this.getName('navigation_charters')?.default,
            href: '/charters',
            popup: false,
          },
          {
            name: this.getName('navigation_services')?.default,
            href: '/services',
            popup: false,
          },
          {
            name: this.getName('navigation_fleet')?.default,
            href: '/fleet',
            popup: false,
          },
          {
            name: this.getName('navigation_directory')?.default,
            href: '/directory',
            popup: false,
          },
          // {
          //   name: this.getName('navigation_alliance')?.default,
          //   popup: 'Alliance',
          // },
          {
            name: this.getName('navigation_contacts')?.default,
            href: '/contacts',
            popup: false,
          },
        ],
        contacts: this.$store.getters.getNavigation?.navigation_contacts,
      };
    },
    breakpoint() {
      return this.$store.getters.getBreakpoint;
    },
  },
  methods: {
    closeNavigation() {
      this.$emit('hideNavigation');
    },
    charterShow() {
      this.$emit('hideNavigation');
      this.$emit('charterClick');
    },
    popupShow(component) {
      this.popupActive = true;
      this.currentPopup = component;
      this.$emit('hideNavigation');
    },
  },
};
</script>

<style lang="scss" scoped>
.site-navigation {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  top: 0;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  background-color: color(dark);
  color: color(basic-light);
  pointer-events: none;
  overflow: hidden;
  z-index: 25;

  @include screen("sm", max) {
    overflow: auto;
    max-height: 100%;
  }

  @include wRule(
    width,
    (
      xl: 830,
      lg: 560,
      sm: 100%,
    )
  );

  &.is-active {
    pointer-events: auto;
  }

  .header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    box-sizing: border-box;
    flex: none;
    width: 100%;

    @include wRule(
      padding,
      (
        xl: 42 98 0 225,
        lg: 28 65 0 102,
        md: 42 pxtovw(65, lg) 0 pxtovw(102, lg),
        sm: 24 40 0 112,
      )
    );
  }

  ::v-deep(.close) {
    transform-origin: 50% 50%;

    @include wRule(
      margin-left,
      (
        xl: auto,
        sm: 58,
      )
    );

    @include wRule(
      width,
      (
        xl: 55,
        lg: 50,
        sm: 50,
      )
    );

    @include wRule(
      height,
      (
        xl: 55,
        lg: 50,
        sm: 50,
      )
    );
  }

  ::v-deep(.v-button) {
    @include wRule(
      height,
      (
        xl: 52,
        lg: 44,
        sm: 94,
      )
    );

    @include wRule(
      padding,
      (
        xl: 0 36,
        lg: 0 22,
        sm: 0 15,
      )
    );

    @include screen("sm") {
      width: 100%;
      margin-top: pxtovw(52, sm);
    }
  }

  .lang-button {
    &:not(:first-child) {
      @include wRule(
        margin-left,
        (
          xl: 52,
          lg: 42,
          sm: 0,
        )
      );
    }
  }

  .socials {
    &:not(:first-child) {
      @include wRule(
        margin-left,
        (
          xl: 52,
          lg: 42,
          sm: 58,
        )
      );
    }
  }

  ::v-deep(.social) {
    @include wRule(
      width,
      (
        sm: 36,
      )
    );
    @include wRule(
      height,
      (
        sm: 36,
      )
    );

    &:not(:first-child) {
      @include wRule(
        margin-left,
        (
          xl: 32,
          lg: 26,
          sm: 46,
        )
      );
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1;
    box-sizing: border-box;
    overflow: hidden;

    @include screen("sm", max) {
      flex: none;
    }

    @include rule(
      padding,
      (
        xl: 8.5vh pxtovw(225, xl),
        // 92 260 120
        lg: 7vh pxtovw(102, lg),
        sm: pxtovw(64, sm) pxtovw(92, sm) pxtovw(115, sm) pxtovw(92, sm),
      )
    );

    @include screen(xl, min) {
      padding-left: 225px;
      padding-right: 225px;
    }
  }

  nav {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    @include screen("sm", max) {
      margin: 0 pxtovw(-40, sm) 0 0;
    }
  }

  .item {
    display: block;
    line-height: 1.6;
    font-weight: 400;
    letter-spacing: -0.02em;

    @include wRule(
      font-size,
      (
        xl: 35,
        lg: 30,
        sm: 45,
      )
    );

    // &:not(:first-child) {
    //   @include wRule(
    //     margin-top,
    //     (
    //       xl: 21,
    //       lg: 7,
    //       sm: 9,
    //     )
    //   );
    // }

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 10,
          lg: 0,
          sm: 9,
        )
      );
    }
  }

  .contacts {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;

    @include screen("sm", max) {
      margin-top: pxtovw(72, sm);
    }
  }

  .link {
    @include wRule(
      margin-top,
      (
        xl: 14,
        lg: 14,
        sm: 14,
      )
    );

    @include wRule(
      font-size,
      (
        xl: 20,
        lg: 18,
        sm: 30,
      )
    );
  }

  ::v-deep(.link) {
    .circle {
      @include wRule(
        width,
        (
          sm: 48,
        )
      );
      @include wRule(
        height,
        (
          sm: 48,
        )
      );
      @include wRule(
        margin-right,
        (
          sm: 43,
        )
      );
    }

    .icon {
      @include wRule(
        width,
        (
          sm: 23,
        )
      );
      @include wRule(
        height,
        (
          sm: 23,
        )
      );
    }
  }
}
</style>
