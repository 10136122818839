<template>
  <VTooltip
    :theme="'info-popover'"
    :triggers="['click']"
    :offset="[12, 12]"
    :placement="'top'"
    :strategy="'fixed'"
    :autoHide="true"
  >
    <template #default>
      <button class="trigger">i</button>
    </template>

    <template #popper>
      <div class="content">
        <span
          class="star"
          v-if="breakpoint!=='sm'"
        >*</span>
        <span class="popover-text">{{text}}</span>
        <Close v-close-popper />
      </div>
    </template>
  </VTooltip>
</template>

<script>
import Close from './Close.vue';

export default {
  components: { Close },
  name: 'InfoPopover',
  data() {
    return {
      isShown: false,
    };
  },
  props: {
    text: {
      type: String,
    },
  },
  computed: {
    breakpoint() {
      return this.$store.getters.getBreakpoint;
    },
  },
};
</script>

<style lang="scss">
.v-popper--theme-info-popover {
  z-index: 5;

  .trigger {
    display: flex;
    background-color: color(icon-blue);
    color: color(basic-light);
    text-align: center;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    @include wRule(
      margin-left,
      (
        xl: 12,
        lg: 12,
        sm: 16,
      )
    );

    @include wRule(
      width,
      (
        xl: 12,
        lg: 12,
        sm: 18,
      )
    );

    @include wRule(
      height,
      (
        xl: 12,
        lg: 12,
        sm: 18,
      )
    );

    @include wRule(
      font-size,
      (
        xl: 10,
        lg: 10,
        sm: 14,
      )
    );
  }

  .content {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: flex-start;
    box-sizing: border-box;
    color: color(basic-light);
    background-color: color(icon-blue);
    line-height: 1.28;
    font-size: 12px;

    @include wRule(
      border-radius,
      (
        xl: 5,
        lg: 5,
        sm: 0,
      )
    );

    @include wRule(
      padding,
      (
        xl: 12 10,
        lg: 12 10,
        sm: 25 40,
      )
    );

    @include wRule(
      font-size,
      (
        sm: 24,
      )
    );

    @include wRule(
      width,
      (
        xl: 276,
        lg: 276,
        sm: auto,
      )
    );

    @include screen("sm", max) {
      width: 100vw;
    }
  }

  .star {
    @include wRule(
      margin-right,
      (
        xl: 6,
        lg: 6,
        sm: 0,
      )
    );
  }

  &.v-popper__popper {
    transition: opacity $ease-main;
    z-index: 125;

    @include screen("sm", max) {
      bottom: 0;
      left: 0;
      transform: translate3d(0, 0, 0) !important;
    }

    &--shown {
      opacity: 1;
    }

    &--hidden {
      opacity: 0;
    }
  }

  .v-popper__arrow {
    transform: translate3d(50%, -50%, 0) rotate(45deg);
    background-color: color(icon-blue);

    @include wRule(
      width,
      (
        xl: 12,
        lg: 12,
        sm: 0,
      )
    );

    @include wRule(
      height,
      (
        xl: 12,
        lg: 12,
        sm: 0,
      )
    );
  }

  .v-popper__arrow-container {
    top: 100%;
  }

  &[data-popper-placement="bottom"] .v-popper__arrow-container {
    top: 0;
  }

  .close {
    display: none;

    @include wRule(
      width,
      (
        sm: 44,
      )
    );
    @include wRule(
      height,
      (
        sm: 44,
      )
    );

    @include wRule(
      margin,
      (
        sm: 0 -10 0 40,
      )
    );

    @include screen("sm", max) {
      display: block;
      flex: none;
    }
  }
}
</style>
