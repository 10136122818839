<template>
  <PageSection
    class="screen service-section"
    :bg="content"
    :theme="true"
  >
    <template #default>
      <div class="container">
        <div class="header">
          <h3
            class="title"
            v-inview-animate:repeat="{name: 'fade-down', duration: 500, delay: 500}"
          >{{content?.title}}</h3>
        </div>

        <div class="list">
          <Service
            v-for="(service, serviceId) in content?.list"
            :key="`${service?.title}`"
            :content="service"
            :href="serviceId === 0 ? '/services#consulting' : '/services#managment'"
            v-inview-animate:repeat="{name: 'fade-down', duration: 500, delay: (500 + (100 * serviceId))}"
          />
        </div>
      </div>
    </template>
  </PageSection>
</template>

<script>
import PageSection from '../../../../components/PageSection.vue';
import Service from './components/Service.vue';

export default {
  components: {
    PageSection,
    Service,
  },
  name: 'ServiceSection',
  props: {
    content: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.bg-media) {
  &::after {
    @include pseudo;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(26, 42, 68, 0.136) 0%,
      rgba(19, 29, 45, 0.76) 48.41%
    );
    opacity: 0.9;
    z-index: 2;

    @include rule(
      opacity,
      (
        xl: 0.9,
        sm: 1,
      )
    );
  }
}

.service-section {
  display: flex;
  transform-origin: 100% 100%;

  @include wRule(
    padding,
    (
      xl: 188 0 160,
      lg: 125 0 70,
      sm: 84 0 216,
    )
  );

  @media screen and (max-height: 55vw) {
    @include wRule(
      padding,
      (
        xl: 188 0 140,
        lg: 100 0 45,
        sm: 84 0 216,
      )
    );
  }

  @media screen and (max-height: 47vw) {
    @include wRule(
      padding,
      (
        xl: 188 0 100,
        lg: 80 0 25,
        sm: 84 0 216,
      )
    );
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .list {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;

    @include screen("sm", max) {
      display: block;
    }

    @include wRule(
      margin,
      (
        xl: auto 0,
        sm: 50 0 0,
      )
    );

    @include wRule(
      padding,
      (
        xl: 40 0,
        lg: 40 0,
        sm: 0,
      )
    );

    // &:not(:first-child) {
    //   @include wRule(
    //     margin-top,
    //     (
    //       xl: 80,
    //       lg: 60,
    //       sm: 50,
    //     )
    //   );

    //   @media screen and (max-height: 55vw) {
    //     @include wRule(
    //       margin-top,
    //       (
    //         xl: 80,
    //         lg: 50,
    //         sm: 50,
    //       )
    //     );
    //   }

    //   @media screen and (max-height: 47vw) {
    //     @include wRule(
    //       margin-top,
    //       (
    //         xl: 80,
    //         lg: 40,
    //         sm: 50,
    //       )
    //     );
    //   }
    // }

    .service {
      flex: 1;

      @include screen("sm", max) {
        flex: none;
        width: 100%;
      }

      &:not(:last-child) {
        @include wRule(
          margin-right,
          (
            xl: 58,
            lg: 32,
            sm: 0,
          )
        );

        @include wRule(
          margin-bottom,
          (
            sm: 35,
          )
        );
      }
    }
  }

  .links {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 78,
          lg: 65,
          sm: 60,
        )
      );

      @media screen and (max-height: 55vw) {
        @include wRule(
          margin-top,
          (
            xl: 78,
            lg: 50,
            sm: 60,
          )
        );
      }

      @media screen and (max-height: 47vw) {
        @include wRule(
          margin-top,
          (
            xl: 78,
            lg: 40,
            sm: 60,
          )
        );
      }
    }
  }
}
</style>
