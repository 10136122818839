<template>
  <label
    class="base-input"
    :class="{'is-error': errorMessage, 'is-top': inputValue, 'is-disabled': disabled}"
  >
    <input
      :name="name"
      :id="name"
      :type="type"
      :placeholder="label"
      @input="handleChange"
      @blur="handleBlur"
      v-model="inputValue"
      :disabled="disabled"
      :readonly="readonly"
    >
    <span class="label">{{label}}</span>
  </label>
</template>

<script>
import { useField } from 'vee-validate';
// import * as yup from 'yup';

export default {
  name: 'BaseInput',
  props: {
    type: {
      type: String,
      default: 'text',
    },
    name: {
      type: String,
      reqired: true,
    },
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(props.name, props.rules, {
      initialValue: props?.value,
    });

    return {
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta,
    };
  },
  watch: {
    value(value) {
      this.inputValue = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.base-input {
  position: relative;
  display: block;

  &::after {
    @include pseudo;

    bottom: 0;
    left: 0;
    width: 100%;
    background-color: currentColor;

    @include wRule(
      height,
      (
        xl: 2,
        lg: 2,
        sm: 2,
      )
    );
  }

  &.is-inview-animation-input-transition {
    &::after {
      transition: transform 0.5s ease;
      transition-delay: inherit;
      transform-origin: 0 50%;
      transform: scaleX(0);
    }

    .label {
      opacity: 0;
      transition: opacity 0.5s ease, transform 0.25s ease;
      transition-delay: inherit;
    }
  }

  &.is-inview-animation-active {
    &::after {
      transform: scaleX(1);
    }

    .label {
      opacity: 1;
    }
  }

  input {
    display: block;
    box-sizing: border-box;
    letter-spacing: -0.02em;
    width: 100%;
    color: currentColor;

    @include wRule(
      padding,
      (
        xl: 16 0,
        lg: 16 0,
        sm: 23 0,
      )
    );

    @include wRule(
      font-size,
      (
        xl: 20,
        lg: 16,
        sm: 24,
      )
    );

    &::placeholder {
      color: transparent;
    }

    &:-webkit-autofill {
      -webkit-text-fill-color: color(basic);

      .popup-form &,
      .flight-calculator &,
      .flight-form & {
        -webkit-text-fill-color: color(basic-light);
      }
    }
  }

  .label {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    box-sizing: border-box;
    pointer-events: none;
    transform-origin: 0 100%;
    transition: transform $ease-main;
    text-rendering: geometricPrecision;

    @include wRule(
      padding,
      (
        xl: 16 0,
        lg: 16 0,
        sm: 23 0,
      )
    );

    @include wRule(
      font-size,
      (
        xl: 20,
        lg: 16,
        sm: 24,
      )
    );
  }

  &.is-error {
    &::after {
      background-color: color(error);
    }
  }

  &.is-top .label,
  input:focus ~ .label {
    transform: scale(0.6) translate3d(0, -121%, 0);
  }
}
</style>
