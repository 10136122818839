<template>
  <div class="feature">
    <div class="symbol">
      <slot name="symbol"></slot>
    </div>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Feature',
};
</script>

<style lang="scss" scoped>
.feature {
  $b: &;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @include screen("sm", max) {
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
  }

  .symbol {
    flex: none;
  }

  ::v-deep(.title) {
    font-weight: 700;
    line-height: 1.28;

    @include wRule(
      font-size,
      (
        xl: 24,
        lg: 20,
        sm: 28,
      )
    );
  }

  ::v-deep(.icon) {
    &_knowledge {
      @include wRule(
        width,
        (
          xl: 79,
          lg: 46,
          sm: 79,
        )
      );
      @include wRule(
        height,
        (
          xl: 54,
          lg: 32,
          sm: 54,
        )
      );
    }

    &_world {
      @include wRule(
        width,
        (
          xl: 66,
          lg: 38,
          sm: 66,
        )
      );
      @include wRule(
        height,
        (
          xl: 66,
          lg: 38,
          sm: 66,
        )
      );
    }

    &_list {
      @include wRule(
        width,
        (
          xl: 49,
          lg: 33,
          sm: 49,
        )
      );
      @include wRule(
        height,
        (
          xl: 58,
          lg: 40,
          sm: 58,
        )
      );
    }

    &_key {
      @include wRule(
        width,
        (
          xl: 14,
          lg: 14,
          sm: 23,
        )
      );
      @include wRule(
        height,
        (
          xl: 31,
          lg: 31,
          sm: 50,
        )
      );
    }

    &_grow {
      @include wRule(
        width,
        (
          xl: 15,
          lg: 15,
          sm: 24,
        )
      );
      @include wRule(
        height,
        (
          xl: 29,
          lg: 29,
          sm: 45,
        )
      );
    }

    &_bulb {
      @include wRule(
        width,
        (
          xl: 28,
          lg: 28,
          sm: 45,
        )
      );
      @include wRule(
        height,
        (
          xl: 28,
          lg: 28,
          sm: 45,
        )
      );
    }
  }

  &-charter {
    align-items: center;

    @include screen("sm", max) {
      align-items: flex-start;
    }

    .symbol {
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      line-height: 1;
      box-sizing: border-box;
      border-style: solid;
      border-color: color(border-blue);
      border-radius: 50%;
      font-weight: 400;

      @include wRule(
        font-size,
        (
          xl: 60,
          lg: 32,
          sm: 32,
        )
      );

      @include wRule(
        width,
        (
          xl: 3,
          lg: 2,
          sm: 2,
        )
      );

      @include wRule(
        width,
        (
          xl: 143,
          lg: 101,
          sm: 90,
        )
      );

      @include wRule(
        height,
        (
          xl: 143,
          lg: 101,
          sm: 90,
        )
      );

      @include screen("sm", max) {
        flex: none;
      }
    }

    .content {
      text-align: center;

      @include screen("sm", max) {
        text-align: left;
      }

      &:not(:first-child) {
        @include wRule(
          margin-top,
          (
            xl: 47,
            lg: 44,
            sm: 22,
          )
        );
        @include wRule(
          margin-left,
          (
            sm: 44,
          )
        );
      }
    }
  }

  &-consult {
    ::v-deep(.text-small) {
      @include rule(
        line-height,
        (
          xl: 1.6,
          lg: 1.5,
        )
      );
    }

    .content {
      &:not(:first-child) {
        @include wRule(
          margin-top,
          (
            xl: 60,
            lg: 35,
            sm: -5,
          )
        );

        @include wRule(
          margin-left,
          (
            sm: 47,
          )
        );
      }
    }

    .symbol {
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url("data:image/svg+xml,%3Csvg width='68' height='98' viewBox='0 0 68 98' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 96.1V1.5H51.3787L66.5 16.6213V96.1H1.5Z' stroke='%235071ab' stroke-width='3'/%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: 100%;
      flex: none;

      @include wRule(
        width,
        (
          xl: 43,
          lg: 40,
          sm: 68,
        )
      );

      @include wRule(
        height,
        (
          xl: 61,
          lg: 58,
          sm: 98,
        )
      );
    }
  }

  &-consult-v2 {
    display: flex;
    flex-direction: column;

    @include screen("sm", max) {
      align-items: center;
      text-align: center;
    }

    .symbol {
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url("data:image/svg+xml,%3Csvg width='92' height='133' viewBox='0 0 92 133' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 130.665V2.33545H69.8493L90.5 22.9862V130.665H2Z' stroke='%235071A8' stroke-width='3'/%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: 100%;

      @include wRule(
        width,
        (
          xl: 43,
          lg: 43,
          sm: 92,
        )
      );

      @include wRule(
        height,
        (
          xl: 61,
          lg: 61,
          sm: 132,
        )
      );
    }

    ::v-deep(.icon) {
      &_key {
        @include wRule(
          width,
          (
            xl: 14,
            lg: 14,
            sm: 30,
          )
        );
        @include wRule(
          height,
          (
            xl: 31,
            lg: 31,
            sm: 68,
          )
        );
      }

      &_grow {
        @include wRule(
          width,
          (
            xl: 15,
            lg: 15,
            sm: 32,
          )
        );
        @include wRule(
          height,
          (
            xl: 29,
            lg: 29,
            sm: 61,
          )
        );
      }

      &_bulb {
        @include wRule(
          width,
          (
            xl: 28,
            lg: 28,
            sm: 60,
          )
        );
        @include wRule(
          height,
          (
            xl: 28,
            lg: 28,
            sm: 60,
          )
        );
      }
    }

    .content {
      &:not(:first-child) {
        @include wRule(
          margin-top,
          (
            xl: 60,
            lg: 42,
            sm: 63,
          )
        );
      }
    }

    ::v-deep(.text-small) {
      @include rule(
        line-height,
        (
          xl: 1.6,
          lg: 1.5,
        )
      );
    }
  }
}
</style>
