<template>
  <div
    class="page-container"
    v-observe-visibility="animation"
  >
    <BgMedia
      :media="content"
      class="bg-fixed"
    />

    <div
      class="calculator-bg"
      :class="{'is-move-down': transitionNextStart && slideActive === 1, 'is-move-up': transitionPrevStart && slideActive === 0, 'is-active': slideActive === 0, 'is-show': pageActive}"
    >
      <div class="container">
        <div class="inner"></div>
      </div>
    </div>

    <Swiper
      class="main-slider"
      :speed="500"
      :init="false"
      :slidesPerView="'auto'"
      :followFinger="false"
      :direction="'veritcal'"
      :observer="true"
      :runCallbacksOnInit="false"
      :observeSlideChildren="true"
      :resizeObserver="true"
      :simulateTouch="false"
      :resistance="false"
      :keyboard="{enabled: true}"
      :preventInteractionOnTransition="true"
      :focusableElements="'input, select, option, textarea, button, video, label, .aircraft-select, .airport-select, .base-select'"
      @swiper="swiperInstance = $event"
      @slideNextTransitionStart="slideMoveDown"
      @slidePrevTransitionStart="slideMoveUp"
      @fromEdge="removeScrollListener"
      @slideChangeTransitionStart="blockMouse"
      @slideChangeTransitionEnd="readyToScroll"
      @activeIndexChange="slideActiveChange"
      @afterInit="listenInit"
      @keyPress="keyScroll"
    >
      <!-- :mousewheel="{ sensitivity: 2, thresholdTime: 500 }" -->
      <SwiperSlide v-slot="{ isActive }">
        <LeadSection
          data-screen-section="0"
          :data-screen-slide-active="isActive"
          :bg="content"
          @order-charter="orderCharter"
          :content="pageContent?.screen_main"
        />
      </SwiperSlide>
      <SwiperSlide v-slot="{isActive, isPrev }">
        <AboutSection
          :class="{'is-leave-animation': isPrev && isDown,'is-enter-animation': (isActive && !isDown)}"
          data-screen-section="1"
          :data-screen-slide-active="isActive"
          :content="pageContent?.screen_about"
        />
      </SwiperSlide>
      <SwiperSlide v-slot="{isActive, isPrev, isNext}">
        <SaleSection
          :class="{'is-leave-animation': isPrev && isDown, 'is-active-animation': isActive && isDown, 'is-active-reverse-animation':  isNext && !isDown, 'is-enter-animation': (isActive && !isDown)}"
          data-screen-section="2"
          :data-screen-slide-active="isActive"
          :content="pageContent?.screen_sell"
        />
      </SwiperSlide>
      <SwiperSlide v-slot="{isActive, isPrev, isNext}">
        <CharterSection
          :class="{'is-leave-animation': isPrev && isDown, 'is-active-animation': isActive && isDown, 'is-active-reverse-animation':  isNext && !isDown, 'is-enter-animation': (isActive && !isDown)}"
          data-screen-section="3"
          :data-screen-slide-active="isActive"
          :content="pageContent?.screen_charter"
        />
      </SwiperSlide>

      <SwiperSlide v-slot="{isActive,  isNext}">
        <ServiceSection
          :class="{'is-active-animation': isActive && isDown, 'is-active-reverse-animation':  isNext && !isDown}"
          data-screen-section="4"
          :data-screen-slide-active="isActive"
          :content="pageContent?.screen_services"
        />
      </SwiperSlide>

      <SwiperSlide v-slot="{ isActive }">
        <div
          class="slide-wrapper"
          ref="scroller"
          id="scroller"
        >
          <ManagementSection
            data-screen-section="5"
            :data-screen-slide-active="isActive"
            :content="pageContent?.screen_management"
          />
          <!-- <AllianceSection
            data-screen-section="6"
            :data-screen-slide-active="isActive"
            :content="pageContent?.screen_alliance"
          /> -->
          <PageFooter @show-popup="orderCharter" />
        </div>
      </SwiperSlide>
    </Swiper>

    <PageAnchors
      :isShown="inView"
      :slideActive="slideActive"
      :screenNames="pageContent"
    />
  </div>
</template>

<script>
import BgMedia from '../../components/BgMedia.vue';
import AboutSection from './components/AboutSection.vue';
// import AllianceSection from './components/AllianceSection.vue';
import CharterSection from './components/CharterSection.vue';
import ServiceSection from './components/ServiceSection/ServiceSection.vue';
import LeadSection from './components/LeadSection.vue';
import ManagementSection from './components/ManagementSection.vue';
import PageAnchors from './components/PageAnchors.vue';
import SaleSection from './components/SaleSection.vue';
import { animationMixin } from '../../components/mixins/animation';
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Keyboard } from 'swiper/core';
import PageFooter from '../../components/PageFooter.vue';
import api from '../../tools/api';
import '../../../node_modules/lethargy/lethargy';

SwiperCore.use([Keyboard]);

export default {
  name: 'Home',
  components: {
    // AllianceSection,
    ManagementSection,
    LeadSection,
    SaleSection,
    AboutSection,
    CharterSection,
    ServiceSection,
    BgMedia,
    PageAnchors,
    Swiper,
    SwiperSlide,
    PageFooter,
  },
  mixins: [animationMixin],
  emits: ['orderCharter'],
  data() {
    return {
      isDown: false,
      swiperInstance: undefined,
      lastScrollerPosition: 0,
      slideActive: 0,
      pageContent: {},
      screenConsulting: {},
      pageLoading: true,
      consultingLoading: true,
      transitionNextStart: false,
      transitionPrevStart: false,
      pageActive: false,
      isTransition: false,
      isSliderScroll: true,
    };
  },
  computed: {
    breakpoint() {
      return this.$store.getters.getBreakpoint;
    },
    lang() {
      return this.$store.getters.getLang;
    },
    content() {
      return this.pageContent?.screen_main;
    },
  },
  methods: {
    orderCharter(data) {
      console.log('main hear');
      this.$emit('orderCharter', data);
    },
    slideMoveDown() {
      this.transitionNextStart = true;
      this.isDown = true;
    },
    slideMoveUp() {
      this.transitionPrevStart = true;
      this.isDown = false;
    },
    swiperManage() {
      if (this.breakpoint === 'sm') {
        this.swiperInstance.destroy(false, true);
      } else {
        this.swiperInstance.init();
        this.swiperInstance.update();
      }
    },
    stopMouseWheel(e) {
      e.preventDefault();
    },
    blockMouse(inst) {
      const sw = inst;
      this.isTransition = true;
      window.addEventListener('wheel', this.stopMouseWheel, { passive: false });
      sw.el.addEventListener('wheel', this.stopMouseWheel, { passive: false });
    },
    readyToScroll(inst) {
      const sw = inst;
      console.log(sw.isEnd);
      this.isTransition = false;
      this.transitionNextStart = false;
      this.transitionPrevStart = false;
      window.removeEventListener('wheel', this.stopMouseWheel, {
        passive: false,
      });
      sw.el.removeEventListener('wheel', this.stopMouseWheel, {
        passive: false,
      });
      if (this.swiperInstance.isEnd) {
        sw.allowSlidePrev = false;
        sw.allowTouchMove = false;
        this.isSliderScroll = false;
        this.$refs.scroller.addEventListener('scroll', this.scrollerScroll, {
          passive: true,
        });
        this.$refs.scroller.addEventListener('wheel', this.scrollerMouse, {
          passive: true,
        });
      } else {
        this.isSliderScroll = true;
        this.$refs.scroller.removeEventListener('scroll', this.scrollerScroll, {
          passive: true,
        });
        this.$refs.scroller.removeEventListener('wheel', this.scrollerMouse, {
          passive: true,
        });
      }
    },
    scrollerScroll(evt) {
      const el = evt.currentTarget;
      const sT = el.scrollTop;
      const diff = this.lastScrollerPosition - sT;
      if (sT === 0 && diff >= 0) {
        this.swiperInstance.allowSlidePrev = true;
        this.swiperInstance.allowTouchMove = true;
        this.isSliderScroll = true;
      }
      this.lastScrollerPosition = sT;
    },
    scrollerMouse(evt) {
      const el = evt.currentTarget;
      const sT = el.scrollTop;
      const diff = evt.deltaY;
      console.log('mouse scroll inside bottom slide');
      console.log(this.lastScrollerPosition);
      console.log(diff);
      if (sT === 0 && diff < 0 && this.lastScrollerPosition === 0) {
        this.swiperInstance.allowSlidePrev = true;
        this.swiperInstance.allowTouchMove = true;
        this.isSliderScroll = true;
      } else if (sT === 0 && diff > 0 && this.lastScrollerPosition === 0) {
        this.swiperInstance.allowSlidePrev = false;
        this.swiperInstance.allowTouchMove = false;
        this.isSliderScroll = false;
      }
      this.lastScrollerPosition = sT;
    },
    removeScrollListener() {
      this.$refs.scroller.removeEventListener('scroll', this.scrollerScroll, {
        passive: true,
      });
      this.$refs.scroller.removeEventListener('wheel', this.scrollerMouse, {
        passive: true,
      });
    },
    slideActiveChange(sw) {
      this.slideActive = sw.activeIndex;
    },
    async getPageContent() {
      const results = await api('main-page', true);
      this.pageLoading = !results.statusText === 'ok';
      this.pageContent = results.data;
    },
    manualsScrolling(evt) {
      const swp = this.swiperInstance;

      if (this.isSliderScroll) {
        evt.preventDefault();
        evt.stopPropagation();
        const diff = window.lethargy.check(evt);
        if (diff !== false && !this.swiperInstance.animating) {
          if (diff > 0) {
            swp.slidePrev(500);
          } else {
            swp.slideNext(500);
          }
        }
      }
    },
    keyScroll(sw, keyCode) {
      // up
      if (keyCode === 38 || keyCode === 33) {
        if (!this.isSliderScroll && this.lastScrollerPosition === 0) {
          this.isSliderScroll = true;
          this.swiperInstance.allowSlidePrev = true;
          this.swiperInstance.allowTouchMove = true;
          this.removeScrollListener();
          sw.slidePrev(500);
        }

        if (!this.isSliderScroll && this.lastScrollerPosition > 0) {
          this.$refs.scroller.scrollBy(0, -50);
          this.lastScrollerPosition = this.$refs.scroller.scrollTop;
        }
      }

      // down
      if (keyCode === 40 || keyCode === 34) {
        if (!this.isSliderScroll && this.lastScrollerPosition >= 0) {
          this.$refs.scroller.scrollBy(0, 50);
          this.lastScrollerPosition = this.$refs.scroller.scrollTop;
        }
      }
    },
    listenInit() {
      this.pageActive = true;

      window.addEventListener('wheel', this.manualsScrolling, {
        passive: false,
      });
    },
  },
  watch: {
    breakpoint() {
      this.swiperManage();
    },
    lang() {
      this.getPageContent();
      // this.getScreenConsulting();
    },
  },
  created() {
    this.getPageContent();
    // this.getScreenConsulting();
  },
  mounted() {
    this.swiperManage();

    const event = new CustomEvent('scroller-in', {
      detail: {
        scroller: this.$refs.scroller,
      },
    });

    document.body.dispatchEvent(event);
  },
  beforeUnmount() {
    const event = new CustomEvent('scroller-out', {
      detail: {
        scroller: this.$refs.scroller,
      },
    });

    document.body.dispatchEvent(event);
    window.removeEventListener('wheel', this.manualsScrolling, {
      passive: false,
    });
  },
};
</script>

<style lang="scss" scoped>
.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.page-container {
  position: relative;
  width: 100%;
}

.main-slider {
  width: 100%;
  height: 100vh;
  overflow: hidden;

  @include screen("sm", max) {
    height: auto;
  }

  ::v-deep(> .swiper-wrapper) {
    flex-direction: column;
    transition-timing-function: cubic-bezier(0.42, -0.01, 0.84, 0.64);
  }

  .slide-wrapper {
    overflow: auto;
    max-height: 100%;
  }
}

.bg-fixed {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 0;

  &::after {
    @include pseudo;

    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(
      180deg,
      rgba(26, 42, 68, 0.32) 0%,
      rgba(40, 61, 94, 0) 299.84%
    );
    // opacity: var(--fixedBgOpacity);
    transition: opacity $ease-main;
    z-index: 1;
  }
}

.calculator-bg {
  position: fixed;
  bottom: 100%;
  opacity: 0;
  width: 100%;
  left: 0;
  z-index: 0;
  will-change: bottom;

  .inner {
    display: block;
    width: 100%;
    backdrop-filter: blur(31px);
    background: linear-gradient(
        180deg,
        rgba(28, 27, 65, 0) 0%,
        rgba(14, 14, 34, 0.67) 100%
      ),
      rgba(8, 10, 35, 0.3);

    @include wRule(
      height,
      (
        xl: 390,
        lg: 320,
        sm: auto,
      )
    );

    @media screen and (max-height: 55vw) {
      @include wRule(
        height,
        (
          xl: 390,
          lg: 265,
          sm: auto,
        )
      );
    }

    @media screen and (max-height: 47vw) {
      @include wRule(
        height,
        (
          xl: 390,
          lg: 235,
          sm: auto,
        )
      );
    }
  }

  @include screen("sm") {
    display: none;
  }

  &.is-active.is-show {
    bottom: 0;
    opacity: 1;
  }

  &.is-move-down {
    bottom: 100%;
    transition-timing-function: cubic-bezier(0.42, -0.01, 0.84, 0.64);
    transition-duration: 0.49s;
    transition-delay: 0;
    transition-property: bottom;
    opacity: 1;
  }

  &.is-move-up {
    bottom: 0;
    transition-timing-function: cubic-bezier(0.42, -0.01, 0.84, 0.64);
    transition-duration: 0.49s;
    transition-property: bottom;
    opacity: 1;
  }
}

.page-section {
  position: relative;
}
</style>
