<template>
  <div
    class="container"
    v-observe-visibility="{callback: animation, once: true}"
  >
    <transition name="fade-down">
      <section
        class="alliance"
        v-show="inView"
      >
        <allianceCard
          v-for="item in content"
          :data="item"
          :linkText="getName('go')?.default"
          :key="item?.title"
          :id="item?.title.toLowerCase().replace(/ /gi, '-')"
          :inView="inView"
        ></allianceCard>
      </section>
    </transition>
  </div>
</template>

<script>
import AllianceCard from './components/AllianceCard.vue';
import getUrl from '../../tools/getUrl';
import { animationMixin } from '@/components/mixins/animation';

export default {
  name: 'Alliance',
  mixins: [animationMixin],
  components: { AllianceCard },
  computed: {
    content() {
      return this.$store.getters.getAlliance;
      // return {
      //   items: [
      //     {
      //       title: 'HELICOPTERS',
      //       link: 'http://skypro.su/',
      //       active: true,
      //       description: 'Авиакомпания SKYPRO HELICOPTERS специализируется на эксплуатации вертолетов с максимальной взлетной массой более 3 100 кг',
      //       logo: {
      //         url: 'assets/alliance/logo-helicopters.svg',
      //       },
      //       background: {
      //         background: {
      //           url: 'assets/alliance/helicopter.jpg',
      //           mime: 'image/jpeg',
      //         },
      //       },
      //     },
      //     {
      //       title: 'GROUND SERVICES',
      //       link: 'http://skypro.moscow/',
      //       active: true,
      //       description: 'Cпециалисты компании SKYPRO GROUND SERVICES предлагают транспортировку и размещение авиационного персонала, а также оперативную доставку бортового питания',
      //       logo: {
      //         url: 'assets/alliance/logo-ground-services.svg',
      //       },
      //       background: {
      //         background: {
      //           url: 'assets/alliance/ground-service.jpg',
      //           mime: 'image/jpeg',
      //         },
      //       },
      //     },
      //     {
      //       title: 'GROUP',
      //       link: 'https://skyprogroup.com/',
      //       active: true,
      //       description: 'SKYPRO GROUP основана с целью консолидации авиационных активов и интеллектуальной собственности, связанной с товарным знаком SKYPRO',
      //       logo: {
      //         url: 'assets/alliance/logo-group.svg',
      //       },
      //       background: {
      //         background: {
      //           url: 'assets/alliance/group.jpg',
      //           mime: 'image/jpeg',
      //         },
      //       },
      //     },
      //     {
      //       title: 'TECHNICS',
      //       link: 'https://skypro.tech/',
      //       active: true,
      //       description: 'SKYPRO TECHNICS комплексные решения по техническому обслуживанию воздушных судов зарубежного производства',
      //       logo: {
      //         url: 'assets/alliance/logo-technics.svg',
      //       },
      //       background: {
      //         background: {
      //           url: 'assets/alliance/group.jpg',
      //           mime: 'image/jpeg',
      //         },
      //       },
      //     },
      //     {
      //       title: 'REAL ESTATE',
      //       link: '#',
      //       active: true,
      //       description: 'SKYPRO REAL ESTATE специализируется на управлении объектами коммерческой недвижимости',
      //       logo: {
      //         url: 'assets/alliance/logo-real-estate.svg',
      //       },
      //       background: {
      //         background: {
      //           url: 'assets/alliance/real-estate.jpg',
      //           mime: 'image/jpeg',
      //         },
      //       },
      //     },
      //     {
      //       title: 'AVIATION',
      //       link: 'https://skyprogroup.com/',
      //       active: false,
      //       description: 'Основной профиль SKYPRO AVIATION — продажа воздушных судов, организация чартерных рейсов любой сложности, авиационный консалтинг и менеджмент воздушных судов',
      //       logo: {
      //         url: 'assets/alliance/logo-aviation.svg',
      //       },
      //       background: {
      //         background: {
      //           url: 'assets/alliance/aviation.jpg',
      //           mime: 'image/jpeg',
      //         },
      //       },
      //     },
      //   ],
      //   linkText: 'Перейти на сайт',
      // };
    },
  },
  methods: {
    getUrl,
  },
};
</script>

<style lang="scss" scoped>
.alliance {
  display: grid;
  grid-template-columns: 35% repeat(2, 22.2%) 20.6%;
  width: 100%;
  border-top: 3px solid color(alliance-blue);
  border-left: 3px solid color(alliance-blue);
  box-sizing: border-box;

  @include wRule(
    border-width,
    (
      xl: 3,
      lg: 3,
      sm: 3,
    )
  );

  @include rule(
    grid-template-rows,
    (
      xl: repeat(2, pxtovw(295, xl)),
      lg: repeat(2, pxtovw(205, lg)),
    )
  );

  @include screen("lg", max) {
    grid-template-columns: 35% repeat(2, 22.2%) 20.6%;
  }

  @include screen("sm", max) {
    display: block;
    border-left: none;
  }

  &.animation {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }

  &.is-animated {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: opacity 0.5s ease 0.5s, transform 0.5s ease 0.5s;
  }

  .alliance-card {
    border-right: 3px solid color(alliance-blue);
    border-bottom: 3px solid color(alliance-blue);

    @include wRule(
      border-width,
      (
        xl: 3,
        lg: 3,
        sm: 3,
      )
    );

    @include screen("sm", max) {
      width: 100%;
      height: pxtovw(113, sm);
      border-left: 3px solid color(alliance-blue);
      border-width: pxtovw(3, sm);
    }

    &_helicopters {
      grid-row: 1 / 2;
      grid-column: 1 / 2;
    }

    &_ground-services {
      grid-row: 1 / 2;
      grid-column: 2 / 4;
    }

    &_aviation {
      grid-row: 1 / 3;
      grid-column: 4 / 5;
    }

    &_group {
      grid-row: 2 / 3;
      grid-column: 1 / 2;
    }

    &_technics {
      grid-row: 2 / 3;
      grid-column: 2 / 3;
    }

    &_real-estate {
      grid-row: 2 / 3;
      grid-column: 3 / 4;
    }
  }
}

.popup .container {
  @include wRule(
    padding-top,
    (
      sm: 105,
    )
  );
}

.popup .alliance {
  @include wRule(
    padding-bottom,
    (
      sm: 105,
    )
  );
}
</style>
