<template>
  <a
    class="service"
    :href="href"
  >
    <div class="image">
      <Icon :name="content?.icon?.name" />
    </div>

    <div class="description">
      <h4 class="title-small">{{content?.title}}</h4>
      <div
        class="text-small"
        v-html="content?.text"
      ></div>
    </div>
    <div class="arrow">
      <Icon name="arrow" />
    </div>
  </a>
</template>

<script>
import Icon from '../../../../../components/Icon.vue';

export default {
  components: {
    Icon,
  },
  name: 'Service',
  props: {
    href: {
      type: String,
    },
    content: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.service {
  position: relative;
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  border: 2px solid rgba(255, 255, 255, 0.5);
  cursor: pointer;

  @include wRule(
    min-height,
    (
      xl: 350,
      lg: 286,
      sm: 310,
    )
  );

  @include wRule(
    padding,
    (
      xl: 90 118,
      lg: 88 70 80 88,
      sm: 54 50 45,
    )
  );

  &::before {
    @include pseudo;

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(30px);
    transition: opacity $ease-main;
    opacity: 0;
    z-index: 0;
  }

  @media (hover: hover) {
    &:hover {
      &::before {
        opacity: 1;
      }
    }
  }

  .image {
    position: relative;
    z-index: 1;
    flex: none;
    box-sizing: border-box;

    @include wRule(
      padding-top,
      (
        xl: 10,
        lg: 6,
        sm: 8,
      )
    );

    @include wRule(
      width,
      (
        xl: 70,
        lg: 45,
        sm: 74,
      )
    );

    &:not(:last-child) {
      @include wRule(
        margin-right,
        (
          xl: 52,
          lg: 35,
          sm: 67,
        )
      );
    }
  }

  .description {
    position: relative;
    flex: 1;
    z-index: 1;

    ::v-deep(.title-small) {
      font-weight: 400;
      letter-spacing: -0.02em;
      line-height: 1.3;

      @include wRule(
        margin-bottom,
        (
          xl: 27,
          lg: 20,
          sm: 25,
        )
      );
    }

    ::v-deep(.text-small) {
      font-weight: 400;
      line-height: 1.6;
      letter-spacing: -0.02em;
    }
  }

  .arrow {
    position: absolute;
    z-index: 1;

    @include wRule(
      width,
      (
        xl: 40,
        lg: 40,
        sm: 65,
      )
    );

    @include wRule(
      height,
      (
        xl: 10,
        lg: 10,
        sm: 17,
      )
    );

    @include wRule(
      bottom,
      (
        xl: 56,
        lg: 40,
        sm: 42,
      )
    );
    @include wRule(
      right,
      (
        xl: 45,
        lg: 36,
        sm: 28,
      )
    );

    svg {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
