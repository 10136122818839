import axios from 'axios';

export default function apiGetCallingCodes() {
  return axios.get('https://ipapi.co/json/').then((response) => {
    const info = response.data;
    const result = {
      value: info.country_calling_code,
      country: info.country_code,
      countryName: info.country_name,
    };
    return result;
  });
}
