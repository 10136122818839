<template>
  <div
    class="details-block"
    :class="{'is-expanded': expanded }"
  >
    <transition
      name="accordion-slide-down"
      @before-enter="collapse"
      @enter="expand"
      @after-enter="expandDone"
      @before-leave="expand"
      @leave="collapse"
    >
      <div
        class="content"
        v-show="expanded"
      >
        <slot
          name="content"
          :expanded="expanded"
        ></slot>
      </div>
    </transition>
    <div
      class="trigger"
      :class="{'is-expanded': expanded }"
      @click="expanded = !expanded"
    >
      <slot name="icon">
        <span class="icon"></span>
      </slot>
      <div class="name">
        <slot
          name="name"
          :expanded="expanded"
        ></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DetailsBlock',
  data() {
    return {
      expanded: this.isExpanded,
    };
  },
  props: {
    isExpanded: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    collapse(element) {
      const el = element;
      el.style.height = '0';
    },
    expand(element) {
      const el = element;
      el.style.height = `${el.scrollHeight}px`;
    },
    expandDone(element) {
      const el = element;
      el.style.height = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.details-block {
  display: flex;
  flex-direction: column;
  width: 100%;

  .trigger {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 700;
    line-height: 1.6;
    cursor: pointer;

    @include wRule(
      font-size,
      (
        xl: 20,
        lg: 16,
        sm: 24,
      )
    );

    @include wRule(
      margin-top,
      (
        xl: 18,
        lg: 24,
        sm: 24,
      )
    );
  }

  .icon {
    position: relative;
    display: block;
    flex: none;

    @include wRule(
      height,
      (
        xl: 16,
        lg: 10,
        sm: 12,
      )
    );

    @include wRule(
      width,
      (
        xl: 37,
        lg: 37,
        sm: 37,
      )
    );

    &::before,
    &::after {
      @include pseudo;

      left: 0;
      width: 100%;
      background-color: currentColor;
      transition: all $ease-main;

      @include wRule(
        height,
        (
          xl: 3,
          lg: 2,
          sm: 3,
        )
      );
    }

    &::before {
      top: 50%;
      transform: translate3d(0, -50%, 0);
    }

    &::after {
      bottom: 50%;
      transform: translate3d(0, 50%, 0);
    }

    &:not(:last-child) {
      @include wRule(
        margin-right,
        (
          xl: 24,
          lg: 18,
          sm: 24,
        )
      );
    }
  }

  ::v-deep(.icon_down) {
    order: 2;
    transform-origin: 50% 50%;
    transition: transform $ease-main;

    @include wRule(
      width,
      (
        xl: 16,
        lg: 16,
        sm: 28,
      )
    );

    @include wRule(
      height,
      (
        xl: 10,
        lg: 10,
        sm: 28,
      )
    );

    @include wRule(
      margin-left,
      (
        xl: 15,
        lg: 15,
        sm: auto,
      )
    );
  }

  .trigger:hover {
    .icon {
      &::before {
        top: 0;
        transform: translate3d(0, 0, 0);
      }

      &::after {
        bottom: 0;
        transform: translate3d(0, 0, 0);
      }
    }
  }

  .trigger.is-expanded {
    .icon {
      &::before {
        top: 0;
        transform: translate3d(0, 0, 0);
      }

      &::after {
        bottom: 0;
        transform: translate3d(0, 0, 0);
      }
    }

    ::v-deep(.icon_down) {
      transform: rotate(180deg);
    }

    &:hover {
      .icon {
        &::before {
          top: 50%;
          transform: translate3d(0, -50%, 0);
        }

        &::after {
          bottom: 50%;
          transform: translate3d(0, 50%, 0);
        }
      }
    }
  }

  &.reverse {
    flex-direction: column-reverse;
  }

  &.small {
    .trigger {
      @include wRule(
        padding,
        (
          xl: 0 30,
          lg: 0 20,
          sm: 0 40,
        )
      );

      margin-top: 0;

      @include wRule(
        height,
        (
          xl: 68,
          lg: 68,
          sm: 102,
        )
      );
    }

    .content {
      @include wRule(
        padding,
        (
          xl: 0 30,
          lg: 0 20,
          sm: 0 40,
        )
      );

      &::after {
        display: block;
        content: "";
        width: 100%;

        @include wRule(
          height,
          (
            xl: 22,
            lg: 23,
            sm: 40,
          )
        );
      }
    }

    .name {
      line-height: 1.28;
      letter-spacing: -0.02em;
      font-weight: 700;

      @include wRule(
        font-size,
        (
          xl: 20,
          lg: 18,
          sm: 28,
        )
      );
    }
  }

  &.xsmall {
    .name {
      line-height: 1.28;
      letter-spacing: -0.02em;
      font-weight: 700;

      @include wRule(
        font-size,
        (
          xl: 16,
          lg: 14,
          sm: 24,
        )
      );
    }

    .trigger {
      height: auto;

      @include wRule(
        padding,
        (
          xl: 2 0,
          lg: 2 0,
          sm: 4 0,
        )
      );
    }

    .content {
      @include wRule(
        padding,
        (
          xl: 0,
          lg: 0,
          sm: 0,
        )
      );

      &::after {
        display: block;
        content: "";
        width: 100%;

        @include wRule(
          height,
          (
            xl: 19,
            lg: 19,
            sm: 26,
          )
        );
      }
    }

    .icon {
      @include wRule(
        width,
        (
          xl: 20,
          lg: 20,
          sm: 37,
        )
      );

      @include wRule(
        height,
        (
          xl: 10,
          lg: 10,
          sm: 12,
        )
      );

      &::before,
      &::after {
        @include wRule(
          height,
          (
            xl: 2,
            lg: 2,
            sm: 3,
          )
        );
      }

      &:not(:last-child) {
        @include wRule(
          margin-right,
          (
            xl: 20,
            lg: 20,
            sm: 24,
          )
        );
      }
    }
  }
}
</style>
