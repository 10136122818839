<template>
  <section
    class="page-section"
    :class="{'is-dark': theme}"
  >
    <transition
      name="fade"
      mode="in-out"
    >
      <BgMedia
        v-if="bg"
        :media="bg"
        :key="bg"
      />
    </transition>
    <slot name="start"></slot>
    <slot></slot>
    <slot name="end"></slot>
  </section>
</template>

<script>
import BgMedia from './BgMedia.vue';

export default {
  components: { BgMedia },
  name: 'PageSection',
  props: {
    bg: {
      type: Object,
    },
    theme: {
      type: Boolean,
      default: false,
    },
  },
  computed: {

  },
};
</script>

<style lang="scss" scoped>
.page-section {
  position: relative;
  display: block;
  width: 100%;
  background-color: color(bg);
  box-sizing: border-box;

  &.is-dark {
    color: color(basic-light);
    background-color: color(dark);
  }

  .bg-media {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  ::v-deep(.container),
  ::v-deep(.container-wide) {
    position: relative;
    z-index: 2;
  }

  &.screen {
    min-height: 100vh;
  }

  &.centered {
    ::v-deep(.container) {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      @include screen("sm", max) {
        align-items: flex-start;
      }
    }
  }
}
</style>
