<template>
  <div class="plane-tab-slider">
    <Loader v-if="aircraftsLoading" />
    <Swiper
      :init="false"
      @swiper="swiperInstance = $event"
      :speed="500"
      :pagination="{type: 'bullets', el: '.plane-tab-slider .pagination'}"
    >
      <template #container-end>
        <div class="pagination"></div>
      </template>
      <SwiperSlide
        v-for="aircraft in aircrafts"
        :key="`${aircraft?.name}`"
      >
        <PlanePreview
          :content="aircraft"
          :linkBase="linkBase"
          class="inner"
        />
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<script>
import SwiperCore, { Pagination, EffectFade } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper.scss';
import 'swiper/components/effect-fade/effect-fade.scss';
import PlanePreview from './PlanePreview.vue';
import api from '../tools/api';
import getRandomFromRange from '../tools/getRandomFromRange';
import Loader from './Loader.vue';

SwiperCore.use([Pagination, EffectFade]);

export default {
  name: 'PlaneTabSlider',
  components: {
    Swiper,
    SwiperSlide,
    PlanePreview,
    Loader,
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
    linkBase: {
      type: String,
    },
  },
  computed: {
    breakpoint() {
      return this.$store.getters.getBreakpoint;
    },
    lang() {
      return this.$store.getters.getLang;
    },
  },
  data() {
    return {
      swiperInstance: null,
      aircrafts: [],
      aircraftsLoading: true,
    };
  },
  methods: {
    swiperManage() {
      if (this.breakpoint === 'sm') {
        this.swiperInstance.init();
        this.swiperInstance.update();
        this.swiperInstance.pagination.update();
      } else {
        this.swiperInstance.destroy(false, true);
      }
    },
    async getAircrafts() {
      const results = await api(
        'vehicles',
        true,
        `vehicle_type.type_id=${encodeURI(this.content.type_id)}&fleet=true`,
      );
      this.aircraftsLoading = !results.statusText === 'ok';
      const result = results.data;
      const resLength = result.length;
      const maxIteration = resLength > 4 ? 4 : resLength;
      for (let i = 0; i < maxIteration; i += 1) {
        const number = getRandomFromRange(result.length);
        this.aircrafts.push(result[number]);
        result.splice(number, 1);
      }
    },
    getRandomFromRange,
  },
  watch: {
    breakpoint() {
      this.swiperManage();
    },
    lang() {
      this.getAircrafts();
    },
  },
  created() {
    this.getAircrafts();
  },
  mounted() {
    this.swiperManage();
  },
};
</script>

<style lang="scss" scoped>
.plane-tab-slider {
  ::v-deep(.pagination) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: color(dark);
    box-sizing: border-box;

    @include screen("sm", min) {
      display: none;
    }

    @include wRule(
      height,
      (
        sm: 80,
      )
    );
  }

  ::v-deep(.swiper-wrapper) {
    @include screen("sm", min) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    @include wRule(
      column-gap,
      (
        xl: 56,
        lg: 32,
        sm: 0,
      )
    );

    @include wRule(
      row-gap,
      (
        xl: 56,
        lg: 32,
        sm: 0,
      )
    );
  }

  ::v-deep(.swiper-pagination-bullet) {
    display: block;
    flex: none;
    background-color: color(basic-light);
    opacity: 0.24;
    transition: opacity $ease-main;
    border-radius: 50%;

    @include wRule(
      width,
      (
        sm: 9,
      )
    );
    @include wRule(
      height,
      (
        sm: 9,
      )
    );

    &:not(:first-child) {
      @include wRule(
        margin-left,
        (
          sm: 28,
        )
      );
    }

    &.swiper-pagination-bullet-active {
      opacity: 1;
    }
  }
}
</style>
