import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import ClickOutside from '@/tools/clickOutside';
import Vue3TouchEvents from 'vue3-touch-events';
import { ObserveVisibility } from 'vue-observe-visibility';
import VTooltip from 'v-tooltip';
import VCalendar from 'v-calendar';
import getUrl from './tools/getUrl';
import getName from './tools/getName';
import getIcon from './tools/getIcon';

createApp(App).use(store).use(router).use(Vue3TouchEvents)
  .use(VTooltip)
  .use(VCalendar, {})
  .directive('click-outside', ClickOutside)
  .directive('observe-visibility', {
    beforeMount: (el, binding, node) => {
      const vnode = node;
      vnode.context = binding.instance;
      ObserveVisibility.bind(el, binding, vnode);
    },
    update: ObserveVisibility.update,
    unmounted: ObserveVisibility.unbind,
  })
  .directive('inview-animate', {
    mounted(el, binding) {
      const element = el;

      el.classList.add('is-inview-animate');
      el.classList.add(`is-inview-animation-${binding.value.name}`);
      element.style.transitionDuration = `${binding.value.duration || 300}ms`;
      element.style.transitionDelay = `${binding.value.delay || 0}ms`;

      const repeat = binding.arg === 'repeat';

      function toggleAnimation(entries) {
        entries.forEach((entry) => {
          const elem = entry.target;
          if (entry.isIntersecting) {
            elem.classList.add('is-inview-animation-active');
            elem.setAttribute('data-onscreen', 'visible');
          } else if (repeat) {
            elem.classList.remove('is-inview-animation-active');
            elem.setAttribute('data-onscreen', 'hidden');
          } else {
            elem.setAttribute('data-onscreen', 'hidden');
          }
        });
      }

      const observer = new IntersectionObserver(toggleAnimation, {
        threshold: [0, 0.5, 1],
      });

      element.observer = observer;

      observer.observe(el);
    },
    updated(el, binding) {
      const element = el;
      const isVisible = el.getAttribute('data-onscreen');

      el.classList.remove(`is-inview-animation-${binding.oldValue.name}`);
      el.classList.add(`is-inview-animation-${binding.value.name}`);

      if (isVisible === 'visible') {
        el.classList.add('is-inview-animation-active');
      } else {
        el.classList.remove('is-inview-animation-active');
      }

      element.style.transitionDuration = `${binding.value.duration || 300}ms`;
      element.style.transitionDelay = `${binding.value.delay || 0}ms`;
    },
    unmounted(el) {
      el.observer.unobserve(el);
    },
  })
  .mixin({
    methods: {
      getIcon,
      getName,
      getUrl,
    },
  })
  .mount('#app');
