<template>
  <div
    class="airport-select"
    :class="[{'is-top': value, 'is-disabled': disabled, 'is-error': errorMessage}]"
    @mousewheel.stop
  >
    <VueMultiselect
      :id="name"
      v-model="value"
      :options="options"
      :placeholder="searchPlaceholder"
      :disabled="disabled"
      :close-on-select="true"
      :clear-on-select="true"
      :show-labels="false"
      :label="'city'"
      :track-by="'icao'"
      :loading="isLoading"
      :internal-search="false"
      ref="select"
      @search-change="syncFind"
      @select="selectedChange"
      @open="addMessage"
      @close="addMessage"
    >
      <template #singleLabel="{ option }">
        <div class="single-label">{{option?.city}}, {{option?.name}}</div>
      </template>
      <template #option="{ option }">
        <div class="option-item">
          <div class="airport">
            <div class="airport-city">{{option?.city}}</div>
            <div class="airport-iata">{{option?.iata}}</div>
            <div class="airport-description">{{option?.country}} - {{option?.name}}</div>
          </div>
        </div>
      </template>
      <template #caret="{toggle}">
        <button
          type="button"
          class="toggle"
          @click.prevent="toggle"
        >
          <Icon name="down" />
        </button>
      </template>
      <template #beforeList>
        <div
          class="top"
          v-if="breakpoint==='sm'"
        >
          <div class="header">
            <div class="title">{{mobileTitle || label}}</div>
            <Close @click="closeDropdown" />
          </div>
          <div class="inner-search"></div>
        </div>

      </template>
    </VueMultiselect>
    <input
      :name="name"
      type="text"
      hidden
      :disabled="disabled"
      v-model="selected"
    >
    <div
      class="label"
      v-if="label"
    >{{label}}</div>
  </div>
</template>

<script>
import VueMultiselect from 'vue-multiselect';
import Close from '../../Close.vue';
import Icon from '../../Icon.vue';
import { useField } from 'vee-validate';
import apiGetAirports from '../../../tools/apiGetAirports';

export default {
  name: 'AirportSelect',
  components: {
    VueMultiselect,
    Icon,
    Close,
  },
  props: {
    name: {
      type: String,
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    initialSelected: {
      type: [String, Array, Object],
      default: '',
    },
    mobileTitle: {
      type: String,
      default: '',
    },
    searchPlaceholder: {
      type: String,
      default: '',
    },
    isReset: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: this.initialSelected,
      options: [],
      isLoading: false,
    };
  },
  computed: {
    breakpoint() {
      return this.$store.getters.getBreakpoint;
    },
  },
  methods: {
    closeDropdown() {
      this.$refs.select.toggle();
    },
    addMessage() {
      document.documentElement.classList.toggle('is-select-open');
    },
    selectedChange(value) {
      this.selected = value.icao;
    },
    async syncFind(query) {
      this.isLoading = true;
      const options = await apiGetAirports(query, this);
      console.log(options);
      this.options = options;
      return options;
    },
  },
  watch: {
    isReset(value) {
      if (value) {
        this.value = [];
      }
    },
  },
  beforeMount() {
    const options = this.syncFind(this.initialSelected);
    if (this.initialSelected) {
      options.then((result) => {
        [this.value] = result;
      });
    }
  },
  setup(props) {
    const {
      value: selected,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(props.name, props.rules, {
      initialValue: props.initialSelected,
    });

    return {
      handleChange,
      handleBlur,
      errorMessage,
      selected,
      meta,
    };
  },
};
</script>

<style lang="scss" scoped>
.airport-select {
  position: relative;
  display: block;

  &::after {
    @include pseudo;

    bottom: 0;
    left: 0;
    width: 100%;
    background-color: currentColor;

    @include wRule(
      height,
      (
        xl: 2,
        lg: 2,
        sm: 2,
      )
    );
  }

  &.is-inview-animation-input-transition {
    &::after {
      transition: transform 0.5s ease;
      transition-delay: inherit;
      transform-origin: 0 50%;
      transform: scaleX(0);
    }

    .label {
      opacity: 0;
      transition: opacity 0.5s ease, transform 0.25s ease;
      transition-delay: inherit;
    }
  }

  &.is-inview-animation-active {
    &::after {
      transform: scaleX(1);
    }

    .label {
      opacity: 1;
    }
  }

  .single-label {
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;

    @include wRule(
      font-size,
      (
        xl: 20,
        lg: 16,
        sm: 24,
      )
    );
  }

  .flag {
    display: block;
    font-size: 0;
    line-height: 0;
    flex: none;

    @include wRule(
      width,
      (
        xl: 30,
        lg: 30,
        sm: 38,
      )
    );

    @include wRule(
      margin-right,
      (
        xl: 10,
        lg: 10,
        sm: 15,
      )
    );
  }

  .option-item {
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    width: 100%;

    @include wRule(
      font-size,
      (
        xl: 20,
        lg: 16,
        sm: 24,
      )
    );
  }

  .airport {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    align-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    line-height: 1.2;
    letter-spacing: -0.02em;

    @include wRule(
      padding,
      (
        xl: 14 0 10,
        lg: 14 0 10,
        sm: 27 0 22,
      )
    );

    &-city {
      @include wRule(
        font-size,
        (
          xl: 24,
          lg: 16,
          sm: 28,
        )
      );
    }

    &-iata {
      text-transform: uppercase;

      @include wRule(
        font-size,
        (
          xl: 16,
          lg: 12,
          sm: 24,
        )
      );
    }

    &-description {
      width: 100%;
      color: color(gray);

      @include wRule(
        font-size,
        (
          xl: 16,
          lg: 14,
          sm: 24,
        )
      );

      @include wRule(
        margin-top,
        (
          xl: 6,
          lg: 6,
          sm: 6,
        )
      );
    }
  }

  .label {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    box-sizing: border-box;
    pointer-events: none;
    transform-origin: 0 100%;
    transition: transform $ease-main;
    text-rendering: geometricPrecision;

    @include wRule(
      padding,
      (
        xl: 16 0,
        lg: 16 0,
        sm: 23 0,
      )
    );

    @include wRule(
      font-size,
      (
        xl: 20,
        lg: 16,
        sm: 24,
      )
    );
  }

  ::v-deep(.multiselect__element) {
    @include wRule(
      padding,
      (
        xl: 0 10,
        lg: 0 16,
        sm: 0 40,
      )
    );
  }

  ::v-deep(.multiselect__option) {
    @include wRule(
      margin,
      (
        xl: 0 -10,
        lg: 0 -16,
        sm: 0 -40,
      )
    );
    @include wRule(
      padding,
      (
        xl: 0 10,
        lg: 0 16,
        sm: 0 40,
      )
    );
  }

  &.is-error {
    &::after {
      background-color: color(error);
    }
  }

  &.is-top .label,
  .multiselect--active ~ .label {
    transform: scale(0.6) translate3d(0, -121%, 0);
  }
}
</style>
