<template>
  <PageSection
    class="screen charter-section"
    :bg="content"
    :theme="true"
  >
    <template #default>
      <div class="container">
        <div class="header">
          <h3
            class="title"
            v-inview-animate:repeat="{name: 'fade-down', duration: 500, delay: 300}"
          >{{content?.title}}</h3>
          <!-- <p
            class="text-big"
            v-html="content?.subtitle"
            v-if="content.subtitle"
            v-inview-animate:repeat="{name: 'fade-down', duration: 500, delay: 400}"
          ></p> -->
        </div>
        <CharterSteps />
        <div class="links">
          <Link
            :tag="'router-link'"
            :to="'/charters'"
            v-inview-animate:repeat="{name: 'fade-down', duration: 500, delay: 900}"
          >
          <template #default>{{getName('more')?.default}}</template>
          <template #end>
            <Icon
              name="arrow"
              :style="'transition-delay: 900ms;'"
            />
          </template>
          </Link>
        </div>
      </div>
    </template>
  </PageSection>
</template>

<script>
import CharterSteps from '../../../components/CharterSteps.vue';
import Link from '../../../components/Link.vue';
import Icon from '../../../components/Icon.vue';
import PageSection from '../../../components/PageSection.vue';

export default {
  components: {
    PageSection,
    CharterSteps,
    Link,
    Icon,
  },
  name: 'CharterSection',
  props: {
    content: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.bg-media) {
  &::after {
    @include pseudo;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(26, 42, 68, 0.136) 0%,
      rgba(19, 29, 45, 0.76) 48.41%
    );
    z-index: 2;

    @include rule(
      opacity,
      (
        xl: 0.9,
        sm: 1,
      )
    );
  }
}

.charter-section {
  display: flex;
  transform-origin: 100% 100%;

  @include wRule(
    padding,
    (
      xl: 188 0 130,
      lg: 125 0 70,
      sm: 84 0 114,
    )
  );

  @media screen and (max-height: 55vw) {
    @include wRule(
      padding,
      (
        xl: 188 0 130,
        lg: 100 0 60,
        sm: 84 0 114,
      )
    );
  }

  @media screen and (max-height: 47vw) {
    @include wRule(
      padding,
      (
        xl: 148 0 90,
        lg: 80 0 30,
        sm: 84 0 114,
      )
    );
  }

  @media screen and (min-width: 1940px) {
    padding-top: 300px;
    padding-bottom: 240px;
  }

  ::v-deep(.container) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
  }

  .text-big {
    @include screen("sm", max) {
      display: none;
    }

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 58,
          lg: 24,
          sm: 46,
        )
      );
    }
  }

  .links {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 40,
          lg: 38,
          sm: 78,
        )
      );

      @media screen and (max-height: 47vw) {
        @include wRule(
          margin-top,
          (
            xl: 40,
            lg: 20,
            sm: 78,
          )
        );
      }
    }
  }

  .charter-steps {
    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 70,
          lg: 60,
          sm: 67,
        )
      );

      @media screen and (max-height: 55vw) {
        @include wRule(
          margin-top,
          (
            xl: 70,
            lg: 40,
            sm: 67,
          )
        );
      }

      @media screen and (max-height: 47vw) {
        @include wRule(
          margin-top,
          (
            xl: 70,
            lg: 20,
            sm: 67,
          )
        );
      }
    }
  }
}
</style>
