<template>
  <article
    :class="[
    'alliance-card',
    `alliance-card_${ data.title.toLowerCase().replace(/ /gi, '-') }`,
    {'is-disabled': data.disabled },
    {'is-active' : active, 'is-ready': isReady}
  ]"
    v-click-outside="closeCard"
    v-touch:tap="activationCard"
  >
    <div class="alliance-card__head">
      <transition name="fade-scalein">
        <div
          class="logo-text"
          v-show="inView"
        >{{ data?.title }}</div>
      </transition>
    </div>
    <div
      class="alliance-card__content"
      @transitionend="isReady = !isReady"
    >
      <BgMedia
        v-if="data.background"
        :media="data"
      >
      </BgMedia>
      <div class="alliance-card__describe">
        <img :src="getUrl(data.icon?.light?.url)" />
        <p>{{ data?.description }}</p>
        <a
          v-if="data?.link"
          :href="data?.link"
          target="_blank"
          @click.prevent="redirect(data?.link, isReady)"
        >{{ linkText }}</a>
      </div>
    </div>
  </article>
</template>

<script>
import BgMedia from '../../BgMedia.vue';

export default {
  name: 'AllianceCard',
  components: { BgMedia },
  props: {
    data: {
      type: Object,
      required: true,
    },
    linkText: {
      type: String,
      required: true,
    },
    inView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      active: false,
      isReady: false,
    };
  },
  computed: {
    breakpoint() {
      return this.$store.getters.getBreakpoint;
    },
  },
  methods: {
    activationCard(evt) {
      const touch = matchMedia('(hover: none)').matches;
      if (touch) {
        if (this.breakpoint === 'sm' && !this.active) {
          this.active = true;
          const content = this.$el.querySelector('.alliance-card__content');
          const sizes = content.getBoundingClientRect();
          const { height } = sizes;
          this.$el.style.height = `${height}px`;
        } else if (this.breakpoint === 'sm' && this.active) {
          if (!evt.target.closest('a')) {
            this.active = false;
            this.$el.style.height = '';
          }
        } else {
          this.active = true;
        }
      }
    },
    closeCard() {
      this.active = false;

      if (this.breakpoint === 'sm') {
        this.$el.style.height = '';
      }
    },
    redirect(url, ready) {
      const touch = matchMedia('(hover: none)').matches;
      if (touch) {
        if (ready) {
          window.open(url, '_blank');
        }
      } else {
        window.open(url, '_blank');
      }
    },
  },
  mounted() {
    document.body.addEventListener('touchstart', (evt) => {
      const { target } = evt;
      const isAllianceCard = this.$el.contains(target);
      if (!isAllianceCard) {
        this.closeCard();
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.alliance-card {
  $b: &;

  position: relative;
  display: block;
  box-sizing: border-box;
  overflow: hidden;
  transition: height $ease-main;

  &.is-disabled {
    cursor: default;
    pointer-events: none;
  }

  .logo-text {
    font-weight: 900;
    line-height: 1;
    letter-spacing: 0.28em;

    @include wRule(
      font-size,
      (
        xl: 19,
        lg: 14,
        sm: 19,
      )
    );
  }

  &__head {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: color(dark);
    z-index: 1;

    &::before {
      @include pseudo;
      @include wRule(
        width,
        (
          xl: 16,
          lg: 12,
          sm: 13,
        )
      );
      @include wRule(
        height,
        (
          xl: 16,
          lg: 12,
          sm: 13,
        )
      );
      @include wRule(
        right,
        (
          xl: 34,
          lg: 20,
          sm: 22,
        )
      );
      @include wRule(
        bottom,
        (
          xl: 34,
          lg: 22,
          sm: 22,
        )
      );

      box-sizing: border-box;
      border-bottom: 3px solid color(alliance-blue);
      border-right: 3px solid color(alliance-blue);

      @include rule(
        border-width,
        (
          xl: 3,
          lg: 3,
          sm: 3,
        )
      );
    }

    .is-disabled & {
      background-color: color(hover);

      &::before {
        display: none;
      }
    }
  }

  &__content {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    z-index: 2;
    opacity: 0;
    pointer-events: none;
    transition: opacity $ease-main;
    background-color: color(dark);

    @include screen("sm", max) {
      height: auto;
    }

    #{$b}.is-active & {
      opacity: 1;
      pointer-events: auto;
    }

    @media (hover: hover) {
      #{$b}:hover & {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  .bg-media {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    &::before {
      @include pseudo;

      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: linear-gradient(
        180deg,
        rgba(20, 24, 31, 0.6) 0%,
        #14181f 100%
      );
      z-index: 2;
    }
  }

  &__describe {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 3;
    box-sizing: border-box;
    line-height: 1.3;
    letter-spacing: -0.02em;

    @include wRule(
      padding,
      (
        xl: 10 24,
        lg: 15 15,
        sm: 66 0 84,
      )
    );

    @include wRule(
      font-size,
      (
        xl: 16,
        lg: 14,
        sm: 25,
      )
    );

    @include screen("sm", max) {
      height: auto;
    }

    img {
      width: 100%;

      @include wRule(
        height,
        (
          xl: 35,
          lg: 26,
          sm: 48,
        )
      );
    }

    a {
      display: inline-block;
      text-decoration: underline;
      font-weight: 700;
      letter-spacing: normal;
      pointer-events: none;

      @include wRule(
        font-size,
        (
          xl: 18,
          lg: 16,
          sm: 30,
        )
      );

      &::before {
        @include pseudo;

        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        pointer-events: none;
        display: none;
      }

      #{$b}.is-active & {
        pointer-events: auto;

        &::before {
          pointer-events: auto;

          @include screen("sm", min) {
            display: block;
          }
        }
      }

      @media (hover: hover) {
        #{$b}:hover & {
          pointer-events: auto;

          &::before {
            pointer-events: auto;

            @include screen("sm", min) {
              display: block;
            }
          }
        }
      }

      &:not(:first-child) {
        @include rule(
          margin-top,
          (
            xl: pxtovh(42, xl),
            lg: pxtovh(24, lg),
            sm: pxtovw(46, sm),
          )
        );
      }

      #{$b}_aviation & {
        &:not(:first-child) {
          @include wRule(
            margin-top,
            (
              xl: pxtovh(44, xl),
              lg: pxtovh(48, lg),
              sm: pxtovw(46, sm),
            )
          );
        }
      }
    }

    p {
      box-sizing: border-box;
      display: block;
      line-height: 1.3;

      @include wRule(
        font-size,
        (
          xl: 16,
          lg: 14,
          sm: 25,
        )
      );
      @include wRule(
        padding,
        (
          xl: 0 20,
          lg: 0,
          sm: 0 38,
        )
      );

      @media screen and (max-width: 1080px) {
        display: none;
      }

      @include screen("sm", max) {
        display: block;
      }

      &:not(:first-child) {
        @include rule(
          margin-top,
          (
            xl: pxtovh(38, xl),
            lg: pxtovh(20, lg),
            sm: pxtovw(52, sm),
          )
        );
      }

      #{$b}_aviation & {
        @include wRule(
          padding,
          (
            xl: 0 20,
            lg: 0 20,
            sm: 0 38,
          )
        );

        &:not(:first-child) {
          @include rule(
            margin-top,
            (
              xl: pxtovh(40, xl),
              lg: pxtovh(38, lg),
              sm: pxtovw(52, sm),
            )
          );
        }
      }
    }
  }

  @for $i from 1 to 10 {
    &:nth-child(#{$i}) {
      .logo-text.fade-scalein-enter-active {
        transition-delay: 0.5s + 0.1s * $i;
      }
    }
  }
}
</style>
