<template>
  <section class="success-message">
    <div class="image">
      <Icon :name="data?.Icon" />
    </div>
    <h3
      class="title-small"
      v-inview-animate="{name: 'fade-down', duration: 300, delay: 100}"
    >{{data?.Title}}</h3>
    <div
      class="text-small"
      v-inview-animate="{name: 'fade-down', duration: 300, delay: 200}"
    >{{data?.Text}}</div>
    <VButton
      :tag="'button'"
      :type="'button'"
      v-if="data.ButtonName"
      @click.prevent="close"
      v-inview-animate="{name: 'fade-down', duration: 300, delay: 300}"
    >{{data?.ButtonName}}</VButton>
  </section>
</template>

<script>
import Icon from './Icon.vue';
import VButton from './VButton.vue';

export default {
  components: { VButton, Icon },
  name: 'SuccessMessage',
  props: {
    data: {
      type: Object,
    },
  },
  emits: ['modalClosed'],
  methods: {
    close() {
      this.$emit('modal-closed');
    },
  },
};
</script>

<style lang="scss" scoped>
.success-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;

  @include wRule(
    padding,
    (
      xl: 0 42,
      lg: 0 36,
      sm: 40,
    )
  );

  .image {
    flex: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.31);

    @include wRule(
      border-width,
      (
        xl: 1,
        lg: 1,
        sm: 2,
      )
    );

    @include wRule(
      width,
      (
        xl: 124,
        lg: 80,
        sm: 120,
      )
    );

    @include wRule(
      height,
      (
        xl: 124,
        lg: 80,
        sm: 120,
      )
    );

    ::v-deep(.icon) {
      @include wRule(
        width,
        (
          xl: 42,
          lg: 29,
          sm: 29,
        )
      );

      @include wRule(
        height,
        (
          xl: 42,
          lg: 29,
          sm: 29,
        )
      );
    }
  }

  ::v-deep(.title-small) {
    text-align: center;

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 48,
          lg: 27,
          sm: 60,
        )
      );
    }
  }

  ::v-deep(.text-small) {
    width: 100%;
    text-align: center;
    line-height: 1.6;

    @include wRule(
      max-width,
      (
        xl: 360,
        lg: 380,
        sm: 420,
      )
    );

    @include wRule(
      font-size,
      (
        xl: 20,
        lg: 16,
        sm: 24,
      )
    );

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 15,
          lg: 11,
          sm: 20,
        )
      );
    }
  }

  ::v-deep(.v-button) {
    @include wRule(
      min-width,
      (
        xl: 180,
        lg: 150,
        sm: 0,
      )
    );

    @include screen("sm", max) {
      width: 100%;
    }

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 80,
          lg: 55,
          sm: 167,
        )
      );
    }
  }
}
</style>
