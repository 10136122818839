import axios from 'axios';
// import store from '../store/index';

export default function apiGetAirports(query, instance) {
  const inst = instance;
  return axios.get(`${process.env.VUE_APP_PROXY_HOST}/airports/`, {
    // withCredentials: true,
    // headers: {
    //   Authorization: 'Token gVCzzKp2DSWdbJ27RU7ptU5SVRaKYFsaRHf3',
    // },
    params: {
      query: query ? `${query}` : '',
      search_by: 'city,name,icao,country',
      fields: 'city,country,name,icao',
    },
  }).then((response) => {
    console.log(response.data);
    inst.isLoading = false;
    return response.data.items;
  }).catch((error) => {
    inst.isLoading = false;
    console.log(error);
  });
}
