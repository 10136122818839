<template>
  <div class="socials">
    <template
      v-for="(social, index) in socials"
      :key="index"
    >
      <a
        :href="social?.value"
        class="social"
        target="_blank"
        v-if="social"
      >
        <Icon :name="getIcon(social?.icon)?.name.toLowerCase()" />
      </a>
    </template>
  </div>
</template>

<script>
import Icon from './Icon.vue';

export default {
  name: 'Socials',
  components: {
    Icon,
  },
  computed: {
    socials() {
      return this.$store.getters.getNavigation?.socials;
    },
  },
};
</script>

<style lang="scss" scoped>
.socials {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  list-style: none;

  .social {
    display: block;
    flex: none;
    transition: opacity $ease-main;
    transform-origin: 50% 50%;

    &:not(:first-child) {
      @include wRule(
        margin-left,
        (
          xl: 32,
          lg: 26,
          sm: 65,
        )
      );
    }

    @media (hover: hover) {
      &:hover {
        opacity: 0.8;
      }
    }

    @include wRule(
      width,
      (
        xl: 35,
        lg: 28,
        sm: 43,
      )
    );
    @include wRule(
      height,
      (
        xl: 35,
        lg: 28,
        sm: 43,
      )
    );

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &.scalein-enter-active {
    transition: all 0.2s ease;

    .social {
      transition: all 0.2s ease;
    }

    @for $i from 1 to 3 {
      &:nth-child(#{$i}) {
        .social {
          transition-delay: 0.05s + (0.05s * $i);
        }
      }
    }
  }
  &.scalein-leave-active {
    transition: all 0.2s ease;

    .social {
      transition: all 0.2s ease;
    }
  }

  &.scalein-enter-from,
  &.scalein-leave-to {
    opacity: 0;

    .social {
      transform: scale(0);
    }
  }
}
</style>
