<template>
  <label
    class="base-toggle"
    :class="[{'checkbox': data?.type === 'checkbox'}, {'radio': data?.type === 'radio'}, {'box': !(data?.type === 'checkbox' || data?.type === 'radio')}]"
  >
    <input
      :type="'checkbox'"
      :name="name"
      :value="data?.label"
      :checked="checked"
      @change="handleChange(data?.label)"
    />
    <div class="label">
      <slot name="default">
        <span class="label-text">{{data?.label}}</span>
      </slot>
    </div>
  </label>
</template>

<script>
import { useField } from 'vee-validate';

export default {
  name: 'BaseToggle',
  props: {
    data: {
      type: Object,
    },
    name: {
      type: String,
    },
    modelValue: String,
  },
  emits: ['update:modelValue'],
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  setup(props) {
    const { checked, handleChange } = useField(props.name, undefined, {
      type: 'checkbox',
      checkedValue: props?.data?.label,
    });

    // // select/unselect the input
    // handleChange(props?.data?.value);

    return {
      checked, // readonly
      handleChange,
    };
  },
};
</script>

<style lang="scss" scoped>
.base-toggle {
  display: block;

  input[type="checkbox"],
  input[type="radio"] {
    display: none;
  }

  .label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 400;
    letter-spacing: -0.02em;
    line-height: 1.3;
    cursor: pointer;

    @include wRule(
      font-size,
      (
        xl: 16,
        lg: 14,
        sm: 24,
      )
    );
  }

  &.is-inview-animation-input-transition {
    .label {
      opacity: 0;
      transition: opacity 0.5s ease, transform 0.5s ease;
      transition-delay: inherit;
    }
  }

  &.is-inview-animation-active {
    .label {
      opacity: 1;
    }
  }

  .image {
    flex: none;
    display: flex;
    justify-content: center;
    align-items: center;

    @include wRule(
      width,
      (
        xl: 76,
        lg: 76,
        sm: 76,
      )
    );
    @include wRule(
      height,
      (
        xl: 18,
        lg: 18,
        sm: 18,
      )
    );

    &:not(:last-child) {
      @include wRule(
        margin-right,
        (
          xl: 15,
          lg: 15,
          sm: 15,
        )
      );
    }

    img {
      display: block;
      line-height: 0;
      font-size: 0;
      flex: none;
      object-fit: contain;
      max-width: 100%;
      max-height: 100%;
      height: 100%;
    }
  }

  &.checkbox {
    .label {
      line-height: 1;

      &::before {
        display: block;
        content: "";
        flex: none;
        box-sizing: border-box;
        background-clip: content-box;
        border: 1px solid currentColor;
        transition: background $ease-main;
        background-repeat: no-repeat;
        background-position: 60% 50%;

        @include wRule(
          background-size,
          (
            xl: 16,
            lg: 16,
            sm: 21,
          )
        );

        @include wRule(
          border-width,
          (
            xl: 1,
            lg: 1,
            sm: 2,
          )
        );

        @include wRule(
          width,
          (
            xl: 24,
            lg: 24,
            sm: 32,
          )
        );
        @include wRule(
          height,
          (
            xl: 24,
            lg: 24,
            sm: 32,
          )
        );

        @include wRule(
          margin-right,
          (
            xl: 16,
            lg: 16,
            sm: 30,
          )
        );
      }
    }

    input:checked ~ .label {
      &::before {
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 7.07779L7 10.9877L14.5 1.21289' stroke='white' stroke-width='3'/%3E%3C/svg%3E ");
      }
    }
  }

  &.radio {
    .label {
      line-height: 1;

      &::before {
        display: block;
        content: "";
        flex: none;
        box-sizing: border-box;
        border-radius: 50%;
        background-clip: content-box;
        border: 1px solid color(basic);
        transition: background $ease-main;

        @include wRule(
          border-width,
          (
            xl: 1,
            lg: 1,
            sm: 2,
          )
        );

        @include wRule(
          padding,
          (
            xl: 7,
            lg: 7,
            sm: 7,
          )
        );

        @include wRule(
          width,
          (
            xl: 24,
            lg: 24,
            sm: 32,
          )
        );
        @include wRule(
          height,
          (
            xl: 24,
            lg: 24,
            sm: 32,
          )
        );

        @include wRule(
          margin-right,
          (
            xl: 17,
            lg: 17,
            sm: 30,
          )
        );
      }
    }

    input:checked ~ .label {
      &::before {
        background-color: color(basic);
      }
    }
  }
}
</style>
