<template>
  <PageSection
    class="screen lead-section"
    :bg="undefined"
  >
    <template #default>
      <div class="container">
        <div class="header">
          <div class="column-left">
            <h1
              class="title-lead"
              v-show="content?.title"
              v-inview-animate:repeat="{name: 'fade-down', duration: 500, delay: 300}"
            >{{content?.title}}</h1>
            <p
              class="text"
              v-show="content?.description"
              v-inview-animate:repeat="{name: 'fade-down', duration: 500, delay: 400}"
              v-html="content?.description"
            ></p>
          </div>

          <div
            class="column-right"
            v-show="content?.socials"
          >
            <div class="contacts">
              <template
                v-for="(link, linkId) in contacts.phones"
                :key="`${link.type}-${linkId}`"
              >
                <Link
                  :class="`link link_${link.type}`"
                  :tag="'a'"
                  :href="`${link.type === 'phone' ? `tel:${link.value.replace(/ /gi, '')}` : ''}${link.type === 'whatsapp' ? `https://wa.me/${link.value.replace(/\s|\+/g, '')}` : ''}`"
                  v-inview-animate:repeat="{name: 'fade-right', duration: 500, delay: (400 + 100 * linkId)}"
                >
                <template #start>
                  <span class="circle">
                    <Icon :name="getIcon(link?.icon)?.name" />
                  </span>
                </template>
                <template #default>{{link?.value}}</template>
                </Link>
              </template>
            </div>

            <template
              v-for="(link, linkId) in contacts?.emails"
              :key="`${link.type}-${linkId}`"
            >
              <Link
                v-if="link.type === 'email'"
                :class="`link link_${link.type}`"
                :tag="'a'"
                :href="`${link.type === 'email' ? `mailto:${link.value}` : ''}`"
                v-inview-animate:repeat="{name: 'fade-right', duration: 500, delay: 700}"
              >
              <template #start>
                <span class="circle">
                  <Icon :name="getIcon(link?.icon)?.name" />
                </span>
              </template>
              <template #default>{{link?.value}}</template>
              </Link>
            </template>

            <VButton
              v-for="(presentation) in content?.presentation_button"
              :key="presentation.id"
              :tag="'a'"
              :href="presentation.file ? getUrl(presentation?.file?.url) : presentation?.link"
              class="thin presentation"
              v-inview-animate:repeat="{name: 'fade-right', duration: 500, delay: (500 + 100 * content?.socials.length)}"
              target="_blank"
            >
              <template #start>
                <Icon name="pdf" />
              </template>
              <template #default>{{presentation?.text}}</template>
            </VButton>
          </div>
        </div>
        <FlightCalculator
          v-show="content?.calculator_title"
          :bg="content"
          @order-charter="orderCharter"
          :title="content?.calculator_title"
        />
      </div>
    </template>
  </PageSection>
</template>

<script>
import PageSection from '../../../components/PageSection.vue';
import Link from '../../../components/Link.vue';
import Icon from '../../../components/Icon.vue';
import VButton from '../../../components/VButton.vue';
// import observeVisibilityOnPage from '../../../tools/observeVisibilityOnPage';
import FlightCalculator from './FlightCalculator.vue';

export default {
  components: {
    PageSection,
    Link,
    Icon,
    VButton,
    FlightCalculator,
  },
  name: 'LeadSection',
  emits: ['orderCharter'],
  props: {
    bg: {
      type: Object,
    },
    content: {
      type: Object,
    },
  },
  // data() {
  //   return {
  //     observer: null,
  //   };
  // },
  computed: {
    contacts() {
      return {
        emails: this.content?.socials?.filter((el) => el?.type === 'email'),
        phones: this.content?.socials?.filter((el) => el?.type !== 'email'),
      };
    },
    breakpoint() {
      return this.$store.getters.getBreakpoint;
    },
  },
  methods: {
    orderCharter(data) {
      console.log('lead hear');
      this.$emit('orderCharter', data);
    },
    setLeadHeight() {
      const el = this.$el;
      console.log(el);

      const resizer = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          const elem = entry.target;
          if (this.breakpoint === 'sm') {
            const wH = window.innerHeight;
            elem.style.minHeight = `${wH}px`;
          } else {
            elem.style.minHeight = '';
          }
        });
      });

      resizer.observe(el);

      if (this.breakpoint === 'sm') {
        const wH = window.innerHeight;
        el.style.minHeight = `${wH}px`;
      } else {
        el.style.minHeight = '';
      }
    },
  },
  mounted() {
    this.setLeadHeight();
    //   function opacityControl(entries) {
    //     entries.forEach((entry) => {
    //       const shift = entry.intersectionRatio > 0.7 ? 1 : entry.intersectionRatio;
    //       document.documentElement.style.setProperty('--fixedBgOpacity', 1 - shift);
    //     });
    //   }
    //   this.observer = observeVisibilityOnPage(opacityControl);
    //   this.observer.observe(this.$el);
    // },
    // beforeUnmounted() {
    //   if (this.observer) {
    //     this.observer.disconnect();
    //   }
  },
};
</script>

<style lang="scss" scoped>
.lead-section {
  color: color(basic-light);
  background-color: transparent;
  display: flex;
  z-index: 2;
  overflow: hidden;
  min-height: 100vh;

  @include wRule(
    padding-top,
    (
      xl: 245,
      lg: 170,
      sm: 243,
    )
  );

  @media screen and (max-height: 55vw) {
    @include wRule(
      padding-top,
      (
        xl: 245,
        lg: 130,
        sm: 243,
      )
    );
  }

  @media screen and (max-height: 47vw) {
    @include wRule(
      padding-top,
      (
        xl: 245,
        lg: 100,
        sm: 243,
      )
    );
  }

  @include screen("sm", max) {
    // padding-top: 21.31579vh; //243px
    // padding-top: 15.78947vh; // 180px
    overflow: auto;
  }

  ::v-deep(.container) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (min-width: 1940px) {
      justify-content: flex-end;
    }
  }

  ::v-deep(.title-lead) {
    letter-spacing: -0.02em;
  }

  .header {
    display: flex;
    justify-content: space-between;

    @include screen("sm", max) {
      flex-wrap: wrap;
    }
  }

  .column-left {
    display: block;
    flex: 1;
    box-sizing: border-box;

    @include screen("sm", max) {
      width: 100%;
    }

    @include wRule(
      padding-right,
      (
        xl: 100,
        lg: 100,
        sm: 0,
      )
    );

    .text {
      @include wRule(
        max-width,
        (
          xl: 700,
          lg: 480,
          sm: 100%,
        )
      );

      @include wRule(
        margin-top,
        (
          xl: 16,
          lg: 16,
          sm: 20,
        )
      );
    }
  }

  .column-right {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: flex-start;
    flex-wrap: wrap;
    box-sizing: border-box;

    @include wRule(
      margin-top,
      (
        xl: 12,
        lg: 10,
        sm: 92,
      )
    );

    @include wRule(
      width,
      (
        xl: 406,
        lg: 315,
        sm: 100%,
      )
    );

    @include wRule(
      padding-right,
      (
        xl: 40,
        lg: 30,
        sm: 0,
      )
    );

    @include screen("sm", max) {
      align-items: flex-start;
      align-content: flex-start;
    }
  }

  .contacts {
    display: flex;
    flex-direction: column;

    @include screen("sm", max) {
      position: relative;
      padding-right: pxtovw(50, sm);
      margin-right: pxtovw(50, sm);

      &::after {
        position: absolute;
        display: inline-block;
        content: "";
        width: 1px;
        background-color: color(basic-light);
        opacity: 0.36;
        right: 0;
        top: pxtovw(-4, sm);
        bottom: pxtovw(-4, sm);
      }
    }

    ::v-deep(.link) {
      &:not(:first-child) {
        @include wRule(
          margin-top,
          (
            sm: 20,
          )
        );
      }
    }
  }

  ::v-deep(.link) {
    .circle {
      @include screen("sm", max) {
        border: none;
      }
    }

    &__text {
      font-weight: 400;

      @include wRule(
        font-size,
        (
          xl: 20,
          lg: 16,
          sm: 20,
        )
      );
    }

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 12,
          lg: 12,
          sm: 0,
        )
      );
    }
  }

  ::v-deep(.v-button) {
    width: 100%;

    @include wRule(
      height,
      (
        xl: 49,
        lg: 44,
        sm: 88,
      )
    );

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 24,
          lg: 26,
          sm: 38,
        )
      );
    }

    .icon {
      @include wRule(
        margin-right,
        (
          xl: 18,
          lg: 15,
          sm: 41,
        )
      );
    }
  }
}
</style>
