import { createRouter, createWebHistory } from 'vue-router';
import Main from '../views/Main/Main.vue';
import store from '@/store';

const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main,
  },
  {
    path: '/charters',
    name: 'Charters',
    component: () => import('../views/Charter/Charter.vue'),
  },
  // {
  //   path: '/consulting',
  //   name: 'Consulting',
  //   component: () => import('../views/Consulting/Consulting.vue'),
  // },
  {
    path: '/services',
    name: 'Services',
    component: () => import('../views/Services/Services.vue'),
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: () => import('../views/Contacts/Contacts.vue'),
  },
  {
    path: '/sale',
    name: 'Sale',
    component: () => import('../views/Sale/Sale.vue'),
  },
  {
    path: '/sale/:aircraftName',
    name: 'SaleModel',
    component: () => import('../views/Model.vue'),
    props: true,
    meta: {
      order: true,
      theme: false,
    },
  },
  {
    path: '/fleet',
    name: 'Fleet',
    component: () => import('../views/Fleet/Fleet.vue'),
    meta: {
      theme: true,
      order: false,
    },
  },
  {
    path: '/privacy-policy',
    name: 'Policy',
    component: () => import('../views/PrivacyPolicy/PrivacyPolicy.vue'),
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    component: () => import('../views/TermsAndConditions/TermsAndConditions.vue'),
  },
  {
    path: '/fleet/:aircraftName',
    name: 'FleetModel',
    component: () => import('../views/Model.vue'),
    props: true,
    meta: {
      theme: true,
    },
  },
  {
    path: '/directory/:id?',
    name: 'Directory',
    component: () => import('../views/Directory/Directory.vue'),
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'is-active',
  linkExactActiveClass: 'is-active',
  scrollBehavior(to) {
    let result = null;
    if (to.hash) {
      result = document.documentElement.querySelector(to.hash).getBoundingClientRect() || { left: 0, top: 0 };
    } else {
      result = { left: 0, top: 0 };
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(result);
      }, 400);
    });
  },
});

router.beforeEach((to) => {
  if (to?.name !== 'FleetModel' || to?.name !== 'SaleModel') {
    document.title = `${store?.getters.getNavigationData?.[`${to.name}`]?.title || to.name} | SKYPRO Aviation`;
  }
});

export default router;
