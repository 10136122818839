<template>
  <div
    class="flight-calculator"
    v-inview-animate:repeat="{name: 'fade-down', duration: 500, delay: 600}"
  >
    <teleport
      to="#popups"
      :disabled="teleportActive"
    >
      <transition name="fade">
        <VeeForm
          class="form flight-form"
          :validation-schema="validationShema"
          @submit="onSubmit"
          v-show="popupActive"
          @order-chareter="orderCharter"
        >
          <BgMedia
            :media="bg"
            v-if="breakpoint === 'sm'"
          />
          <Close @click.prevent="closePopup" />
          <div class="form-wrapper">
            <div class="header">
              <h3
                class="title-small"
                v-inview-animate:repeat="{name: 'fade-down', duration: 300, delay: 500}"
              >{{title}}</h3>
            </div>
            <div class="fields">
              <div class="wrapper">
                <AirportSelect
                  :name="'departure_airport'"
                  :options="content?.airports"
                  :label="content?.Form?.Fields?.departure_airport?.label"
                  :mobileTitle="content?.Form?.Fields?.departure_airport?.mobileTitle"
                  v-inview-animate:repeat="{name: 'input-transition', duration: 300, delay: 600}"
                  :delay="600"
                />
                <ErrorMessage
                  name="departure_airport"
                  class="error"
                />
              </div>
              <div class="wrapper">
                <AirportSelect
                  :name="'arrival_airport'"
                  :options="content?.airports"
                  :label="content?.Form?.Fields?.arrival_airport?.label"
                  :mobileTitle="content?.Form?.Fields?.arrival_airport?.mobileTitle"
                  v-inview-animate:repeat="{name: 'input-transition', duration: 300, delay: 700}"
                  :delay="700"
                />
                <ErrorMessage
                  name="arrival_airport"
                  class="error"
                />
              </div>
              <div class="wrapper">
                <AircraftSelect
                  :name="'aircraft'"
                  :label="content?.Form?.Fields?.aircraft?.label"
                  v-inview-animate:repeat="{name: 'input-transition', duration: 300, delay: 800}"
                  :delay="800"
                />
                <ErrorMessage
                  name="aircraft"
                  class="error"
                />
              </div>
              <div class="wrapper">
                <BaseInput
                  :name="'pax'"
                  :label="content?.Form?.Fields?.pax?.label"
                  v-inview-animate:repeat="{name: 'input-transition', duration: 300, delay: 900}"
                  :delay="900"
                />
                <ErrorMessage
                  name="pax"
                  class="error"
                />
              </div>
              <div class="wrapper">
                <BaseDatepicker
                  :name="'departure_datetime'"
                  :label="content?.Form?.Fields?.departure_datetime?.label"
                  :mobileTitle="content?.Form?.Fields?.departure_datetime?.mobileTitle"
                  v-inview-animate:repeat="{name: 'input-transition', duration: 300, delay: 1000}"
                  :delay="1000"
                />
                <ErrorMessage
                  name="departure_datetime"
                  class="error"
                />
              </div>

              <div class="wrapper wrapper-button">
                <VButton
                  :tag="'button'"
                  type="submit"
                  class="submit"
                  v-inview-animate:repeat="{name: 'fade-down', duration: 300, delay: 1100}"
                >{{content?.Form?.FormSubmitName}}</VButton>
              </div>
            </div>
          </div>
        </VeeForm>
      </transition>
    </teleport>
    <button
      class="panel"
      @click.prevent="openPopup"
    >
      <div class="panel-wrapper">
        <div class="panel-name">
          <Icon :name="'calculator'" />
          <span>{{title}}</span>
        </div>
        <Icon :name="'next'" />
      </div>
    </button>
  </div>
</template>

<script>
import { Form as VeeForm, ErrorMessage } from 'vee-validate';
import Icon from '../../../components/Icon.vue';
import BaseInput from '../../../components/Forms/components/BaseInput.vue';
import BaseDatepicker from '../../../components/Forms/components/BaseDatepicker.vue';
import VButton from '../../../components/VButton.vue';
import apiCalculator from '../../../tools/apiCalculator';
import * as yup from 'yup';
import { setLocale } from 'yup';
import Close from '../../../components/Close.vue';
import { freeze, unfreeze } from '../../../tools/blockScroll';
import BgMedia from '../../../components/BgMedia.vue';
import AirportSelect from '../../../components/Forms/components/AirportSelect.vue';
import AircraftSelect from '../../../components/Forms/components/AircraftSelect.vue';

export default {
  name: 'FlightCalculator',
  components: {
    VeeForm,
    ErrorMessage,
    Icon,
    BaseInput,
    BaseDatepicker,
    VButton,
    // BaseSelect,
    Close,
    BgMedia,
    AirportSelect,
    AircraftSelect,
  },
  props: {
    bg: {
      type: Object,
    },
    title: {
      type: String,
    },
  },
  emits: ['orderCharter'],
  data() {
    return {
      popupActive: true,
      validationShema: null,
    };
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
    getErrorsList() {
      const errorList = this.$store.getters.getErrorList;
      return errorList;
    },
    breakpoint() {
      return this.$store.getters.getBreakpoint;
    },
    content() {
      return {
        Form: {
          FormSubmitName: this.getName('calculation_button')?.default,
          Fields: {
            departure_airport: {
              label: this.getName('field_from')?.secondary,
              mobileTitle: this.getName('field_from')?.default,
            },
            arrival_airport: {
              label: this.getName('field_to')?.secondary,
              mobileTitle: this.getName('field_to')?.default,
            },
            aircraft: {
              label: this.getName('field_aircraft_type')?.default,
            },
            pax: {
              label: this.getName('field_seats')?.default,
            },
            departure_datetime: {
              label: this.getName('field_date')?.default,
              mobileTitle: this.getName('field_date')?.secondary,
            },
          },
        },
      };
    },
  },
  methods: {
    async onSubmit(data) {
      console.log(data);
      await apiCalculator(data, this);
    },
    orderCharter(data) {
      this.$emit('orderCharter', data);
    },
    openPopup() {
      this.popupActive = true;
      freeze();
    },
    closePopup() {
      this.popupActive = false;
      unfreeze();
    },
    statePopup() {
      if (window.matchMedia('(min-width: 768px)').matches) {
        this.popupActive = true;
      } else {
        this.popupActive = false;
      }
      unfreeze();
    },
    setValidationShema(lang) {
      const errorList = this.getErrorsList[lang];
      setLocale(errorList);
      this.validationShema = yup.object({
        departure_airport: yup.string().required(),
        arrival_airport: yup.string().required(),
        aircraft: yup.string().required(),
        pax: yup.number().integer(),
        departure_datetime: yup.date(),
      });
    },
  },
  watch: {
    breakpoint(value) {
      this.statePopup();

      if (value === 'sm') {
        this.teleportActive = false;
      } else {
        this.teleportActive = true;
      }
    },
    lang(value) {
      this.setValidationShema(value);
    },
  },
  created() {
    const lang = this.$store.getters.getLang;
    this.setValidationShema(lang);
    this.statePopup();

    if (window.matchMedia('(min-width: 768px)').matches) {
      this.teleportActive = true;
    } else {
      this.teleportActive = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.flight-calculator {
  position: relative;
  display: block;
  box-sizing: border-box;
  // overflow: hidden;

  @include rule(
    width,
    (
      xl: 100%,
      sm: 100%,
    )
  );

  @include wRule(
    height,
    (
      xl: 390,
      lg: 320,
      sm: auto,
    )
  );

  @include wRule(
    padding,
    (
      sm: 45 0,
    )
  );

  @include wRule(
    margin-top,
    (
      xl: 50,
      lg: 50,
      sm: 50,
      // sm: 870.
    )
  );

  @media screen and (max-height: 55vw) {
    @include wRule(
      margin-top,
      (
        xl: 50,
        lg: 30,
        sm: 50,
        // sm: 870.
      )
    );

    @include wRule(
      height,
      (
        xl: 390,
        lg: 265,
        sm: auto,
      )
    );
  }

  @media screen and (max-height: 47vw) {
    @include wRule(
      margin-top,
      (
        xl: 50,
        lg: 30,
        sm: 50,
        // sm: 870.
      )
    );

    @include wRule(
      height,
      (
        xl: 390,
        lg: 235,
        sm: auto,
      )
    );
  }

  @media screen and (min-width: 1940px) {
    margin-top: 150px;
  }

  &::before {
    @include pseudo;

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        180deg,
        rgba(28, 27, 65, 0) 0%,
        rgba(14, 14, 34, 0.67) 100%
      ),
      rgba(8, 10, 35, 0.3);
    display: none;
    z-index: 0;

    @include screen("sm", max) {
      background: linear-gradient(
          180deg,
          rgba(28, 27, 65, 0) 0%,
          rgba(14, 14, 34, 0.67) 100%
        ),
        rgba(8, 10, 35, 0.3);
      width: auto;
      display: block;
      left: pxtovw(-40, sm);
      right: pxtovw(-40, sm);
      backdrop-filter: blur(31px);
    }
  }

  .panel {
    position: relative;
    z-index: 1;
    color: color(basic-light);
    cursor: pointer;

    @include rule(
      display,
      (
        xl: none,
        sm: block,
      )
    );

    @include screen("sm", max) {
      width: 100%;
      height: 100%;
    }
  }

  .panel-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .panel-name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    font-weight: 700;
    letter-spacing: -0.02em;
    text-align: left;
    line-height: 1.28;

    @include wRule(
      font-size,
      (
        sm: 28,
      )
    );

    @include wRule(
      margin-right,
      (
        sm: 80,
      )
    );
  }

  .icon_calculator {
    flex: none;
    color: color(icon-blue);

    @include wRule(
      width,
      (
        sm: 40,
      )
    );

    @include wRule(
      margin-right,
      (
        sm: 45,
      )
    );
  }

  .icon_next {
    flex: none;

    @include wRule(
      width,
      (
        sm: 17,
      )
    );
  }
}

.form {
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  color: color(basic-light);

  @include screen("sm", max) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 80;
    overflow: hidden;
  }

  ::v-deep(.bg-media) {
    @include screen(sm, max) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
    }

    &::after {
      @include screen("sm", max) {
        @include pseudo;

        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(
            180deg,
            rgba(28, 27, 65, 0) 0%,
            rgba(14, 14, 34, 0.67) 100%
          ),
          rgba(8, 10, 35, 0.3);
        z-index: 1;
        backdrop-filter: blur(31px);
      }
    }
  }

  .form-wrapper {
    display: block;
    height: 100%;
    box-sizing: border-box;

    @include wRule(
      padding,
      (
        xl: 36 62,
        lg: 40 58,
        sm: 40 40 200,
      )
    );

    @media screen and (max-height: 55vw) {
      @include wRule(
        padding,
        (
          xl: 36 62,
          lg: 30 58,
          sm: 40 40 200,
        )
      );
    }

    @media screen and (max-height: 47vw) {
      @include wRule(
        padding,
        (
          xl: 36 62,
          lg: 15 58,
          sm: 40 40 200,
        )
      );
    }

    @include screen("sm", max) {
      position: relative;
      z-index: 2;
      overflow: auto;
    }
  }

  .header {
    display: flex;

    @include screen("sm", max) {
      position: relative;
    }

    ::v-deep(.title-small) {
      @include wRule(
        font-size,
        (
          sm: 32,
        )
      );

      @include wRule(
        margin-right,
        (
          sm: 80,
        )
      );
    }
  }

  .close {
    position: absolute;
    z-index: 10;

    @include wRule(
      top,
      (
        xl: 45,
        lg: 30,
        sm: 30,
      )
    );
    @include wRule(
      right,
      (
        xl: 50,
        lg: 30,
        sm: 30,
      )
    );

    @include rule(
      display,
      (
        xl: none,
        sm: flex,
      )
    );

    .is-select-open & {
      z-index: 0;
    }
  }

  .fields {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @include wRule(
      column-gap,
      (
        xl: 60,
        lg: 50,
        sm: 0,
      )
    );

    @include wRule(
      row-gap,
      (
        xl: 50,
        lg: 45,
        sm: 0,
      )
    );

    @include screen("sm", max) {
      display: block;
    }

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 26,
          lg: 20,
          sm: 42,
        )
      );
    }

    @media screen and (max-height: 55vw) {
      @include wRule(
        row-gap,
        (
          xl: 50,
          lg: 35,
          sm: 0,
        )
      );

      &:not(:first-child) {
        @include wRule(
          margin-top,
          (
            xl: 26,
            lg: 10,
            sm: 42,
          )
        );
      }
    }

    .v-button {
      width: 100%;

      @include wRule(
        height,
        (
          xl: 62,
          lg: 52,
          sm: 88,
        )
      );
    }
  }

  .wrapper {
    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          sm: 42,
        )
      );
    }
  }

  .wrapper-button {
    @include screen("sm", max) {
      position: fixed;
      box-sizing: border-box;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 10;
      padding: pxtovw(48, sm) pxtovw(40, sm);
      background: color(dark);
    }
  }

  ::v-deep(.error) {
    display: block;
    color: color(error);
    font-weight: 400;
    letter-spacing: -0.02em;
    line-height: 1.28;
    box-sizing: border-box;

    @include wRule(
      padding,
      (
        xl: 12 0,
        lg: 12 0,
        sm: 16 0,
      )
    );

    @include wRule(
      font-size,
      (
        xl: 14,
        lg: 14,
        sm: 14,
      )
    );
  }
}
</style>
