<template>
  <PageHeader
    @order-charter="openPopup"
    :smTheme="theme"
  />
  <main
    class="page"
    :class="{'is-dark': theme}"
  >
    <router-view v-slot="{ Component, route }">
      <transition
        name="fade"
        mode="out-in"
      >
        <component
          :is="Component"
          @order-charter="openPopup"
          :key="route.name !== 'Directory' ? route.params : route.name"
        />
      </transition>
    </router-view>
  </main>
  <teleport to="#popups">
    <transition
      name="fade"
      mode="out-in"
    >
      <Popup
        v-if="popupActive"
        :isShown="popupActive"
        :bg="currentPopup !== 'Alliance' ? charterPopupBg : null"
        :blur="false"
        @modal-closed="popupActive = false"
      >
        <transition
          name="fade"
          mode="out-in"
        >
          <component
            :is="currentPopup"
            :key="currentPopup"
            :isResult="isResult"
            :presetData="presetData"
            @change-content="onChangePopup"
            @modal-closed="popupActive = false"
          />
        </transition>
      </Popup>
    </transition>
  </teleport>
  <PageFooter
    v-if="!noFooter"
    @show-popup="openPopup"
  />
</template>

<script>
import lazySizes from 'lazysizes';
import PageFooter from './components/PageFooter.vue';
import PageHeader from './components/PageHeader/PageHeader.vue';
import Popup from './components/Popup.vue';
import breakpointWatcher from './tools/breakpointsWatcher';
import apiGetCallingCodes from './tools/apiGetCallingCodes';
import apiGetCurrentUserCallingCode from './tools/apiGetCurrentUserCallingCode';
import PopupOrderForm from './components/PopupOrderForm.vue';
import PopupResult from './components/PopupResult.vue';
import Alliance from './components/Alliance/Alliance.vue';
import api from './tools/api';

lazySizes.cfg.loadHidden = false;

export default {
  name: 'App',
  components: {
    PageHeader,
    Popup,
    PageFooter,
    PopupOrderForm,
    PopupResult,
    Alliance,
  },
  data() {
    return {
      popupActive: false,
      theme: false,
      currentPopup: 'PopupOrderForm',
      isResult: false,
      presetData: null,
      noFooter: true,
      iconsLoading: true,
      labelsLoading: true,
      navLoading: true,
      pageLoading: true,
      allianceLoading: true,
    };
  },
  computed: {
    currentLang() {
      return this.$store.getters.getLang;
    },
    charterPopupBg() {
      return this.pageContent?.screen_main;
    },
  },
  watch: {
    currentLang(lang) {
      localStorage.setItem('lang', lang);
      this.getIcons();
      this.getLabels();
      this.getMainPage();
      this.getNavigation();
      this.getAlliance();
    },
    $route(route) {
      this.theme = route.meta.theme;
      this.noFooter = route.name === 'Main';
    },
  },
  methods: {
    setLanguage() {
      // const lang = localStorage.getItem('lang') ?? 'en';
      const lang = 'en';
      localStorage.setItem('lang', lang);
      this.$store.commit('setLang', lang);
    },
    openPopup(data) {
      this.onChangePopup(data);
      this.popupActive = true;
    },
    onChangePopup(data) {
      this.currentPopup = data.component;
      this.isResult = data.isResult;
      this.presetData = data.presetData || null;
    },
    async getCallingCodes() {
      const codes = await apiGetCallingCodes();
      this.$store.commit('setCallingCodes', codes);

      const userCode = await apiGetCurrentUserCallingCode();
      this.$store.commit('setUserCallingCode', userCode);
    },
    async getLabels() {
      const results = await api('labels', true);
      this.labelsLoading = !results.statusText === 'ok';
      const labels = results.data;
      this.$store.commit('setLabels', labels);
      document.title = `${this.$store?.getters.getNavigationData?.[
        `${this.$route.name}`
      ]?.title || this.$route.name} | SKYPRO Aviation`;
    },
    async getIcons() {
      const results = await api('icons', false);
      this.iconsLoading = !results.statusText === 'ok';
      const icons = results.data;
      this.$store.commit('setIcons', icons);
    },
    async getMainPage() {
      const results = await api('main-page', true);
      this.pageLoading = !results.statusText === 'ok';
      this.pageContent = results.data;
    },
    async getAlliance() {
      const results = await api('alliances', true);
      this.allianceLoading = !results.statusText === 'ok';
      const alliance = results.data;
      this.$store.commit('setAlliance', alliance);
    },
    async getNavigation() {
      const results = await api('navigation', true);
      this.navLoading = !results.statusText === 'ok';
      const navigation = results.data;
      this.$store.commit('setNavigation', navigation);
    },
  },
  created() {
    this.setLanguage();
    this.getCallingCodes();
    this.getIcons();
    this.getLabels();
    this.getMainPage();
    this.getNavigation();
    this.getAlliance();
  },
  mounted() {
    breakpointWatcher(this);
  },
};
</script>

<style lang="scss" src="./styles/index.scss"></style>
