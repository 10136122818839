<template>
  <div
    class="popup"
    :class="{'no-scroll': noScroll, 'is-active': isModalShown}"
    @keyup.tab.stop
  >
    <div
      class="overlay"
      :class="[{'is-blur': blur}]"
      @click.prevent="closeModal"
    >
      <BgMedia :media="bg" />
    </div>
    <close @click.prevent="closeModal"></close>
    <div class="wrapper">
      <div class="content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { freeze, unfreeze } from '@/tools/blockScroll';
import Close from './Close.vue';
import BgMedia from './BgMedia.vue';

export default {
  name: 'Popup',
  props: {
    isShown: {
      type: Boolean,
      default: false,
    },
    noScroll: {
      type: Boolean,
      default: false,
    },
    bg: {
      type: Object,
      default: null,
      required: false,
    },
    blur: {
      type: Boolean,
      defaul: false,
      requierd: false,
    },
    sidebar: {
      type: Object,
    },
  },
  components: { Close, BgMedia },
  emits: ['modalOpened', 'modalClosed'],
  computed: {
    isModalShown() {
      if (this.isShown) {
        this.showModal();
      }
      return this.isShown;
    },
  },
  methods: {
    closeModal() {
      this.$emit('modalClosed');
      if (this.sidebar) {
        console.log(this.sidebar);
      }
      unfreeze();
    },
    showModal() {
      this.$emit('modalOpened');
      if (this.sidebar) {
        const styleSidebar = this.sidebar.sidebar.getAttribute('style');
        const styleInnerSidebar = this.sidebar.sidebarInner.getAttribute(
          'style',
        );
        console.log(styleSidebar);
        console.log(styleInnerSidebar);
      }
      freeze();
    },
  },
};
</script>

<style lang="scss" scoped>
.popup {
  $b: &;
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  color: color(basic-light);
  overflow: hidden;
  box-sizing: border-box;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    background-color: rgba(color(dark), 0.9);

    &::before {
      @include pseudo;

      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 3;
    }

    &.is-blur {
      .bg-media {
        filter: blur(11px);
      }
    }
  }

  .bg-media {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
  }

  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 4;
    overflow: auto;
    pointer-events: none;

    @include native-scroll(color(basic-light));

    #{$b}.no-scroll & {
      overflow: hidden;
    }
  }

  .content {
    position: static;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    max-height: 100%;
    overflow: auto;
    pointer-events: auto;

    @include wRule(
      padding,
      (
        xl: 40 0,
        lg: 40 0,
        sm: 0,
      )
    );

    @include native-scroll(color(basic-light));
  }

  .close {
    position: absolute;
    z-index: 10;

    @include wRule(
      top,
      (
        xl: 45,
        lg: 30,
        sm: 30,
      )
    );
    @include wRule(
      right,
      (
        xl: 50,
        lg: 30,
        sm: 30,
      )
    );

    .is-select-open & {
      z-index: 0;
    }
  }
}
</style>
