import axios from 'axios';
import store from '../store/index';

export default function apiCalculator(query, instance) {
  // calculator on api v1
  const params = {
    departure_airport: query.departure_airport,
    arrival_airport: query.arrival_airport,
    aircraft_profile: query.aircraft,
    pax: query.pax,
    departure_date_utc: `${query.departure_datetime} 00:00`,
  };
  return axios.get(`${process.env.VUE_APP_PROXY_HOST}/flight_calculator/`, {
    params,
  }).then((response) => {
    console.log(response);
    const { result } = response.data;
    const arrival = result?.arrival_airport;
    const departure = result?.departure_airport;
    const aircraft = params?.aircraft_profile;
    const departureDate = query?.departure_datetime;
    const pax = params?.pax;
    const distance = result?.great_circle_distance / 1000;
    const time = result?.great_circle_time * 60;

    store.commit('setCalculationResults',
      {
        state: 'ok',
        data: {
          arrival_airport: arrival,
          departure_airport: departure,
          aircraft,
          departureDate,
          pax,
          distance,
          time,
        },
      });
    instance.$emit('orderCharter', {
      component: 'PopupResult',
      isResult: false,
    });
    return response.data;
  }).catch((error) => {
    console.log(error);
    store.commit('setCalculationResults', { state: 'fail', data: error.message });
    instance.$emit('orderCharter', {
      component: 'PopupResult',
      isResult: false,
    });
  });

  // calculator on api v2

  // return axios.get(`${process.env.VUE_APP_PROXY_HOST}/calculate`, {
  //   params: query,
  // }).then((response) => {
  //   console.log(response);
  //   const { result } = response.data;
  //   console.log(result);
  //   const arrival = result?.airport?.arrival;
  //   const departure = result?.airport?.departure;
  //   const distance = result?.distance?.great_circle / 1000;
  //   const time = result?.time?.great_circle * 60;

  //   store.commit('setCalculationResults',
  //     {
  //       state: 'ok',
  //       data: {
  //         arrival_airport: arrival,
  //         departure_airport: departure,
  //         distance,
  //         time,
  //       },
  //     });

  //   instance.$emit('orderCharter', {
  //     component: 'PopupResult',
  //     isResult: false,
  //   });

  //   return response.data;
  // }).catch((error) => {
  //   console.log(error);
  //   store.commit('setCalculationResults', { state: 'fail', data: error.message });
  //   instance.$emit('orderCharter', {
  //     component: 'PopupResult',
  //     isResult: false,
  //   });
  // });
}
