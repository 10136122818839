// import axios from 'axios';

// export default function apiGetCallingCodes() {
//   return axios.get('https://restcountries.eu/rest/v2/all?fields=callingCodes;alpha2Code').then((response) => {
//     const result = [];
//     response.data.forEach((el) => {
//       el.callingCodes.forEach((code) => {
//         if (code) {
//           const country = el.alpha2Code.toLowerCase().toLowerCase();
//           result.push({
//             value: `+${code}`,
//             country,
//           });
//         }
//       });
//     });
//     return result;
//   });
// }
import countryCodes from 'country-telephone-data';

export default function apiGetCallingCodes() {
  const data = countryCodes.allCountries;
  const result = [];

  data.forEach((el) => {
    result.push({
      value: `+${el.dialCode}`,
      country: el.iso2,
      countryName: el.name,
    });
  });

  return result;
}
