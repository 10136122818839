<template>
  <header
    class="page-header"
    :class="[{
      'is-dark': theme
    }, {
      'is-scrolled': pageMovedDown
    }, {
      'is-short': !pageMovedDown && !pageIsBegining
    }, {
      'is-sm-light': smTheme
    }]"
    v-observe-visibility="{callback: animation, once: true}"
  >
    <transition name="fade-down">
      <div
        class="container-wide"
        v-show="inView"
      >
        <component
          :is="isHome ? 'div' : 'router-link'"
          :to="isHome ? undefined : '/'"
          class="logo"
        >
          <Icon :name="logo" />
        </component>
        <div class="links">
          <VButton
            type="button"
            v-if="breakpoint !== 'sm'"
            @click="orderCharter"
          >{{content?.buttonName}}</VButton>
          <!-- <LangButton v-if="breakpoint !== 'sm'" /> -->
          <Socials v-if="breakpoint !== 'sm'" />
          <button
            type="button"
            class="burger"
            @click="showNavigation"
          >
            <Icon name="burger" />
          </button>
        </div>
      </div>
    </transition>
  </header>
  <transition
    name="slide"
    @after-enter="endNavAnimation"
    @before-leave="endNavAnimation"
  >
    <SiteNavigation
      v-show="navigationActive"
      :isActive="navigationActive"
      :isShown="navAnimation"
      @hide-navigation="hideNavigation"
      @charter-click="orderCharter"
    />
  </transition>
</template>

<script>
import Icon from '../Icon.vue';
import VButton from '../VButton.vue';
// import LangButton from './components/LangButton.vue';
import Socials from '../Socials.vue';
import SiteNavigation from './components/SiteNavigation.vue';
import scrollObserver from '../../tools/scrollObserver';
import { animationMixin } from '../mixins/animation';

export default {
  name: 'PageHeader',
  emits: ['orderCharter'],
  mixins: [animationMixin],
  components: {
    Icon,
    VButton,
    // LangButton,
    Socials,
    SiteNavigation,
  },
  props: {
    smTheme: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isHome: true,
      navigationActive: false,
      navAnimation: false,
      pageMovedDown: false,
      pageIsBegining: true,
      logo: 'logo-aviation',
    };
  },
  computed: {
    content() {
      return {
        buttonName: this.$store.getters.getLabelByName('CharterButtonName')
          ?.default,
      };
    },
    breakpoint() {
      return this.$store.getters.getBreakpoint;
    },
    navColor() {
      return this.$store.getters.getNavigationColors;
    },
    theme() {
      return this.navColor[this.$route.name];
    },
  },
  watch: {
    $route(route) {
      this.isHome = route.name === 'Main';
    },
  },
  methods: {
    orderCharter() {
      this.$emit('orderCharter', {
        component: 'PopupOrderForm',
        isResult: false,
      });
    },
    showNavigation() {
      this.navigationActive = true;
    },
    hideNavigation() {
      this.navigationActive = false;
    },
    endNavAnimation() {
      this.navAnimation = !this.navAnimation;
    },
  },
  mounted() {
    scrollObserver(this);
  },
};
</script>

<style lang="scss" scoped>
.page-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: block;
  color: color(basic-light);
  box-sizing: border-box;
  transition: transform 0.5s ease, background-color 0.2s ease, padding 0.5s ease;
  will-change: transform, background, padding;
  z-index: 20;
  max-width: 100vw;
  overflow: hidden;

  @include screen("sm", max) {
    color: color(basic);
    background-color: color(basic-light);
  }

  @include wRule(
    padding,
    (
      xl: 42 0 14,
      lg: 28 0 18,
      md: 42 0,
      sm: 23 0,
    )
  );

  ::v-deep(.container-wide) {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.fade-down-enter-active {
      transition-duration: 0.5s;
    }

    &.fade-down-leave-active {
      transition-duration: 0.5s;
    }
  }

  .links {
    display: flex;
    justify-content: flex;
    align-items: center;
  }

  ::v-deep(.v-button) {
    @include wRule(
      height,
      (
        xl: 52,
        lg: 44,
        sm: 88,
      )
    );

    @include wRule(
      padding,
      (
        xl: 0 36,
        lg: 0 22,
        sm: 0 15,
      )
    );
  }

  ::v-deep(.lang-button) {
    &:not(:first-child) {
      @include wRule(
        margin-left,
        (
          xl: 52,
          lg: 42,
          sm: 28,
        )
      );
    }
  }

  ::v-deep(.socials) {
    &:not(:first-child) {
      @include wRule(
        margin-left,
        (
          xl: 52,
          lg: 42,
          sm: 28,
        )
      );
    }
  }

  &.is-dark {
    color: color(basic);

    @include screen("sm", max) {
      color: color(basic);
    }
  }

  &.is-sm-light {
    background-color: color(dark);
    color: color(basic-light);
  }

  &.is-scrolled {
    transform: translate3d(0, -100%, 0);
  }

  &.is-short {
    @include screen("sm", min) {
      background-color: color(basic-light);
      color: color(basic);
    }

    @include wRule(
      padding,
      (
        xl: 14 0,
        lg: 18 0,
        sm: 16 0,
      )
    );
  }
}

.logo {
  display: block;
  flex: none;

  @include wRule(
    height,
    (
      xl: 52,
      lg: pxtovw(44, xlg),
      md: 44,
      sm: 47,
    )
  );

  @include wRule(
    width,
    (
      xl: 366,
      lg: pxtovw(314, xlg),
      md: 314,
      sm: 338,
    )
  );

  svg {
    height: 100%;
    width: 100%;
  }
}

.burger {
  display: block;
  color: inherit;
  transition: opacity $ease-main;

  @include wRule(
    width,
    (
      xl: 44,
      lg: 36,
      sm: 48,
    )
  );
  @include wRule(
    height,
    (
      xl: 32,
      lg: 26,
      sm: 34,
    )
  );

  &:not(:first-child) {
    @include wRule(
      margin-left,
      (
        xl: 56,
        lg: 45,
        sm: 32,
      )
    );
  }

  svg {
    height: 100%;
    width: 100%;
  }

  @media (hover: hover) {
    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
