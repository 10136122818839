<template>
  <PageSection
    class="screen about-section"
    :bg="undefined"
  >
    <template #default>
      <div class="container">
        <div class="header">
          <h3
            class="title"
            v-show="content?.title"
            v-inview-animate:repeat="{name: 'fade-down', duration: 500, delay: 300}"
          >{{content?.title}}</h3>
          <p
            class="text"
            v-show="content?.description"
            v-html="content?.description"
            v-inview-animate:repeat="{name: 'fade-down', duration: 500, delay: 400}"
          ></p>
        </div>

        <div class="features">
          <Feature
            v-for="(feature, featureId) in content?.advantage"
            :key="`about-${featureId}`"
            v-inview-animate:repeat="{name: 'fade-down', duration: 500, delay: (450 + 100 * featureId)}"
          >
            <template #symbol>
              <Icon :name="feature?.icon?.name" />
            </template>
            <template #default>
              <div class="title">{{feature?.title}}</div>
              <div class="text-small">{{feature?.text}}</div>
            </template>
          </Feature>
        </div>
      </div>
    </template>
  </PageSection>
</template>

<script>
import Feature from '../../../components/Feature.vue';
import Icon from '../../../components/Icon.vue';
import PageSection from '../../../components/PageSection.vue';

export default {
  components: { PageSection, Feature, Icon },
  name: 'AboutSection',
  props: {
    content: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.about-section {
  color: color(basic-light);
  background-color: transparent;

  @include wRule(
    padding-top,
    (
      xl: 230,
      lg: 170,
      sm: 84,
    )
  );

  @include wRule(
    padding-bottom,
    (
      xl: 95,
      lg: 95,
      sm: 95,
    )
  );

  @media screen and (max-height: 55vw) {
    @include wRule(
      padding-top,
      (
        xl: 230,
        lg: 130,
        sm: 84,
      )
    );

    @include wRule(
      padding-bottom,
      (
        xl: 95,
        lg: 55,
        sm: 95,
      )
    );
  }

  @media screen and (max-height: 47vw) {
    @include wRule(
      padding-top,
      (
        xl: 230,
        lg: 100,
        sm: 84,
      )
    );

    @include wRule(
      padding-bottom,
      (
        xl: 95,
        lg: 15,
        sm: 95,
      )
    );
  }

  @media screen and (min-width: 1940px) {
    display: flex;
    padding-top: 300px;
    padding-bottom: 240px;
  }

  ::v-deep(.container) {
    @include screen("xxl", min) {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  ::v-deep(.text) {
    @include wRule(
      max-width,
      (
        xl: 990,
        lg: 684,
        sm: 100%,
      )
    );

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 34,
          lg: 16,
          sm: 44,
        )
      );
    }

    @media screen and (min-width: 1940px) {
      max-width: 65%;
    }
  }

  .features {
    display: flex;
    justify-content: flex-start;

    @include screen("sm", max) {
      display: block;
    }

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 50,
          lg: 56,
          sm: 86,
        )
      );

      @media screen and (max-height: 47vw) {
        @include wRule(
          margin-top,
          (
            xl: 50,
            lg: 30,
            sm: 86,
          )
        );
      }

      @include screen("xxl", min) {
        margin-top: pxtovh(100, xl);
      }
    }
  }

  ::v-deep(.feature) {
    @include wRule(
      width,
      (
        xl: 360,
        lg: 276,
        sm: 100%,
      )
    );

    @media screen and (min-width: 1940px) {
      width: 450px;
    }

    &:not(:first-child) {
      @include wRule(
        margin-left,
        (
          xl: 90,
          lg: 32,
          sm: 0,
        )
      );

      @include wRule(
        margin-top,
        (
          sm: 58,
        )
      );

      @media screen and (min-width: 1940px) {
        margin-left: 130px;
      }
    }

    .symbol {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex: none;

      @include wRule(
        width,
        (
          xl: 100%,
          sm: 80,
        )
      );

      @include wRule(
        height,
        (
          xl: 66,
          lg: 38,
          sm: 66,
        )
      );

      @include screen("sm", max) {
        justify-content: center;
      }
    }

    .content {
      &:not(:first-child) {
        @include wRule(
          margin,
          (
            xl: 40 0 0,
            lg: 42 0 0,
            sm: 0 0 0 35,
          )
        );
      }
    }

    .text-small {
      color: rgba(255, 255, 255, 0.72);

      @include rule(
        line-height,
        (
          xl: 1.6,
          lg: 1.4,
        )
      );

      @include wRule(
        font-size,
        (
          sm: 24,
        )
      );

      &:not(:first-child) {
        @include wRule(
          margin-top,
          (
            xl: 26,
            lg: 16,
            sm: 16,
          )
        );
      }
    }

    .icon {
      height: 100%;
    }
  }
}
</style>
